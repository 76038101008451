import React, { Component, lazy, useContext, useEffect, useState } from 'react';
import { getProject, val } from '@theatre/core';
import { SheetProvider, PerspectiveCamera, useCurrentSheet } from '@theatre/r3f';
import SalesforceStateDesktop from '../animationScenes/SalesforceSceneDesktop.json'
import SalesforceStateMobile from '../animationScenes/SalesforceSceneMobile.json'
import { Canvas, useFrame } from '@react-three/fiber';
import { CloudTemplate } from '../components/salesforceScene/CloudTemplate';
import TextSection from '../components/TextSection';
import { Html } from '@react-three/drei';
import { NavContext } from '../NavContext';

const Flower = lazy(() => import("../components/salesforceScene/BlackFlower"));
const SalesforceScene = lazy(() => import("../components/salesforceScene/SalesforceScene"));
const Astro = lazy(() => import("../components/salesforceScene/Astro"));
const Appy = lazy(() => import("../components/salesforceScene/Appy"));
const Codey = lazy(() => import("../components/salesforceScene/Codey"));
const Einstein = lazy(() => import("../components/salesforceScene/Einstein"));
const Flo = lazy(() => import("../components/salesforceScene/Flo"));
const Genie = lazy(() => import("../components/salesforceScene/Genie"));
const Ruth = lazy(() => import("../components/salesforceScene/Ruth"));


class SalesforcePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileView: false
    };
  }

  componentDidMount() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkScreenSize);
  }

  checkScreenSize = () => {
    this.setState({ isMobileView: window.innerWidth <= 500 });
  }

  render() {
    const { previousScrollPosition } = this.props;
    const { isMobileView } = this.state;

    const sheetMobile = getProject('SalesforceSceneMobile', { state: SalesforceStateMobile }).sheet('Scene');
    const sheetDesktop = getProject('SalesforceSceneDesktop', { state: SalesforceStateDesktop }).sheet('Scene');

    return (
      <Canvas gl={{ preserveDrawingBuffer: true }}>
        <SheetProvider sheet={isMobileView ? sheetMobile : sheetDesktop}>
          <Scene previousScrollPosition={previousScrollPosition} />
        </SheetProvider>
      </Canvas>
    );
  }
}

function Scene({ previousScrollPosition }) {

  const { isNavOpen } = useContext(NavContext);

  const [isMobileView, setIsMobileView] = useState(false);
  const sheet = useCurrentSheet();
  const  outlines  = true;
  const scroll = isMobileView ? 63500 : 29000;

  const handleScrollToTop = () => {
  window.scrollTo(0, 0);
};

  useEffect(() => {
    function checkScreenSize() {
      setIsMobileView(window.innerWidth <= 500); 
    }

    checkScreenSize(); 

    window.addEventListener('resize', checkScreenSize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);
  useFrame(() => {
    const sequenceLength = val(sheet.sequence.pointer.length);
    sheet.sequence.position = previousScrollPosition/ scroll * sequenceLength;
    console.log("Scrolli ",  previousScrollPosition/ scroll * sequenceLength)
  });


  return (
    <>
    <ambientLight intensity={0.5}/>
    <directionalLight position={[0, 10, 0]} intensity={0.7} />
    <pointLight position={[5, 5, 5]} intensity={1} />
    <pointLight position={[-15, -15, 0]} intensity={1} />
    <pointLight position={[5, 10, 0]} intensity={0.5} />
    <PerspectiveCamera theatreKey="Camera" makeDefault fov={96.2} near={1} far={70} />

   <SalesforceScene/>

   <Flower black={false} position={isMobileView ? [-0.87,0.03,0.535] : [-0.87,-0.02,0.535]} scale={0.6}/>
   <Flower black={false} position={isMobileView ? [-0.94,0.03,0.45] : [-0.94,-0.02,0.45]} scale={0.6}/>

   <Flower black={false} position={isMobileView ? [-1.045,0.03,0.42] : [-1.045,-0.02,0.42]} scale={0.6}/>

   <Flower black={false} position={isMobileView ? [-1.15,0.03,0.47] : [-1.15,-0.02,0.47]} scale={0.6}/>
   <Flower black={false} position={isMobileView ? [-1.19,0.03,0.585] : [-1.19,-0.02,0.585]} scale={0.6}/>

    <Flower black={true} position={isMobileView ? [0,0.03,-0.015] : [0,0,-0.015]}/>     
      
      
      
    {!isNavOpen && isMobileView && sheet.sequence.position > 3.2 && (
    <Html position={[-4.8, -19, 0.2]} rotation={[-1.6, 0.3, -1.53]} transform>
    <a className="annotation" onClick={handleScrollToTop}> Tech Wisdom </a>
    </Html>
    )}

    {!isNavOpen && isMobileView && sheet.sequence.position > 3.2 && (
    <Html position={[-7.68,-18.3,2.3]} rotation={[-1.5,0.4,-1.53]} transform>
    <a className="annotation" href='/salesforceHub'> Salesforce Hub </a>
    </Html> 
     )}
      
    {!isNavOpen && isMobileView && sheet.sequence.position > 3.2 && (
    <Html position={[-7.8,-17.6,-2]} rotation={[-1.5,0.5,-1.53] } transform>
    <a className="annotation" href='/javaLab'> Java Lab </a>
    </Html>
    )}

    {!isNavOpen && isMobileView && sheet.sequence.position > 3.2 && (
    <Html position={[-10.5,-17,0.5]} rotation={[-1.6,0.5,-1.5]} transform>
    <a className="annotation" href='/salesforceTeam'> Team </a>
    </Html>
    )}



{!isNavOpen && !isMobileView && sheet.sequence.position > 7.9 && (
    <Html position={[-6, -20, 2.5]} rotation={[-1.6, 0.3, -1.42]} transform>
    <a className="annotation" onClick={handleScrollToTop}> Tech Wisdom </a>
    </Html>
    )}

    {!isNavOpen && !isMobileView && sheet.sequence.position > 7.9 && (
    <Html position={[-9,-17.6,8]} rotation={[-1.5,0.5,-1.47]} transform>
    <a className="annotation" href='/salesforceHub'> Salesforce Hub </a>
    </Html> 
     )}
      
    {!isNavOpen && !isMobileView && sheet.sequence.position > 7.9 && (
    <Html position={[-10.5,-17,-2.45]} rotation={[-1.5,0.5,-1.47]} transform>
    <a className="annotation" href='/javaLab'> Java Lab </a>
    </Html>
    )}

    {!isNavOpen && !isMobileView && sheet.sequence.position > 7.9 && (
    <Html position={[-15,-18.4,3.6]} rotation={[-1.6,0.3,-1.44]} transform>
    <a className="annotation" href='/salesforceTeam'> Team </a>
    </Html>
    )}



   <Astro/>
   <Appy/>
   <Codey/>
   <Einstein/>
   <Flo/>
   <Genie/>
   <Ruth/>

   <TextSection title={"Welcome to Salesforce"} fontSize={0.03}  maxWidth={0.3} fillOpacity={5} letterSpacing={-0.01} color={"#c29478"} position={[-0.05,0.65,2.92]} rotation={[-0.3,0,-0.01]} /> 
   <CloudTemplate x={0.029} y={0.489} z={2.875} rotationX={-Math.PI} rotationY={0.128} rotationZ={-Math.PI} scaleX={0.157} scaleY={0.123} scaleZ={0.116} cloudColor={"#5e4434"} cloudOverlineColor={"#4b362a" } cloudOverlineHoverColor={"#966d54"} outlines={outlines} 
   title1={"Embark on a transformative journey with us where we redefine comfort and efficiency for your company with Salesforce"} fontSize1={0.025}  maxWidth1={0.472} color1={"#c29478"} text1X={-0.18} text1Y={0.57} text1Z={2.925} text1RotationX={-0.10} text1RotationY={-0.01} text1RotationZ={0} 
   title2={"Let's get started!"} fontSize2={0.03}  maxWidth2={1} color2={"#c29478"} text2X={-0.1} text2Y={0.43} text2Z={2.92} text2RotationX={0} text2RotationY={-0.03} text2RotationZ={0.01} 
   />

   <CloudTemplate x={-0.722} y={2.053} z={1.110} rotationX={0} rotationY={-0.102} rotationZ={0} scaleX={isMobileView ? 0.1 : 0.112} scaleY={isMobileView ? 0.077 : 0.081} scaleZ={0.077} cloudColor={"#0f7999"} cloudOverlineColor={"#365980"} cloudOverlineHoverColor={"#253e59"} outlines={outlines} 
   title1={"Custom CRM Adaptation"} fontSize1={0.017} maxWidth1={0.372} color1={"#aebccc"} text1X={-0.815} text1Y={2.15} text1Z={1.15} text1RotationX={0} text1RotationY={-0.1} text1RotationZ={0} 
   title2={"Our expertise lies in customizing Salesforce solutions to fit   your unique business needs            We meticulously align the platform with your goals and industry specific requirements ensuring a seamless integration"} 
   fontSize2={0.0155}  maxWidth2={0.31} color2={"#aebccc"} text2X={-0.87} text2Y={2.11} text2Z={1.12} text2RotationX={0} text2RotationY={-0.1} text2RotationZ={0} 
   />

   <CloudTemplate x={0.743} y={2.384} z={0.706} rotationX={-Math.PI} rotationY={0.128} rotationZ={-Math.PI} scaleX={isMobileView ? 0.08 : 0.088} scaleY={isMobileView ? 0.065 : 0.069} scaleZ={0.065} cloudColor={"#6da1b2"} cloudOverlineColor={"#466772"} cloudOverlineHoverColor={"#106078"} outlines={outlines} 
   title1={"Effective Communication"} fontSize1={0.015}  maxWidth1={0.372} color1={"#08113c"} text1X={0.635} text1Y={2.46} text1Z={0.71} text1RotationX={-3.15} text1RotationY={3.37} text1RotationZ={-3.14} 
   title2={"The cornerstone of our  partnership is communication                  We maintain open and transparent channels keeping you well-informed about project developments, challenges and opportunities    Our commitment to clear communications is unwavering"} 
   fontSize2={0.0125}  maxWidth2={0.2545} color2={"#08113c"} text2X={0.628} text2Y={2.432} text2Z={0.71} text2RotationX={-3.14} text2RotationY={3.3} text2RotationZ={-3.12} 
   />

   <CloudTemplate x={0.397} y={1.467} z={-0.693} rotationX={0} rotationY={-0.864} rotationZ={0} scaleX={isMobileView ? 0.075 : 0.085} scaleY={isMobileView ? 0.063 : 0.067} scaleZ={0.063} cloudColor={"#734013"} cloudOverlineColor={"#5c330f"} cloudOverlineHoverColor={"#522d0d"} outlines={outlines} 
   title1={"Dedicated Support"} fontSize1={0.015}  maxWidth1={0.372} color1={"#c2af9f"} text1X={0.46} text1Y={1.55} text1Z={-0.64} text1RotationX={0} text1RotationY={2.18} text1RotationZ={0.005} 
   title2={"Our support extends beyond project completion We offer ongoing guidance and assistance ensuring your Salesforce environment not only keeps pace with but also enhances your business growth optimizing for continuous success"} 
   fontSize2={0.011}  maxWidth2={0.25} color2={"#c2af9f"} text2X={0.485} text2Y={1.515} text2Z={-0.617} text2RotationX={0} text2RotationY={2.15} text2RotationZ={0.01} 
   />

   <CloudTemplate x={-0.265} y={2.8} z={-0.7} rotationX={Math.PI} rotationY={-0.330} rotationZ={Math.PI} scaleX={isMobileView ? 0.075 : 0.08} scaleY={isMobileView ? 0.057 : 0.06} scaleZ={0.058} cloudColor={"#006666"} cloudOverlineColor={"#036b5f" } cloudOverlineHoverColor={"#007c6e"} outlines={outlines} 
   title1={"Scalable Solutions"} fontSize1={0.015}  maxWidth1={0.372} color1={"#cec2b8"} text1X={-0.19} text1Y={2.863} text1Z={-0.74} text1RotationX={3.14} text1RotationY={-0.33} text1RotationZ={3.13} 
   title2={"Regardless of your company's size from startups to large enterprises we offer scalable services       Our approach adapts alongside   your evolving business needs"} 
   fontSize2={0.0119}  maxWidth2={0.25} color2={"#cec2b8"} text2X={isMobileView ? -0.16 : -0.155} text2Y={2.829} text2Z={-0.755} text2RotationX={3.14} text2RotationY={-0.24} text2RotationZ={3.14} 
   />

   <CloudTemplate x={-0.942} y={3.74} z={0.383} rotationX={0} rotationY={0.037} rotationZ={0} scaleX={isMobileView ? 0.13 : 0.15} scaleY={isMobileView ? 0.1 :0.11} scaleZ={0.1} cloudColor={"#746d6a"} cloudOverlineColor={"#655d5a"} cloudOverlineHoverColor={"#867d79"} outlines={outlines} 
   title1={"Proven Success"} fontSize1={0.025}  maxWidth1={0.21} color1={"#cec2b8"} text1X={-1.05} text1Y={3.87} text1Z={0.41} text1RotationX={0} text1RotationY={0.04} text1RotationZ={0} 
   title2={"Our track record in Salesforce implementation is robust across various industries             We pride ourselves on delivering results that not only meet but exceed business expectations showcasing our commitment      to your success"} 
   fontSize2={0.021}  maxWidth2={0.4} color2={"#cec2b8"} text2X={-1.13} text2Y={3.83} text2Z={0.42} text2RotationX={0} text2RotationY={0.04} text2RotationZ={-0.02} 
   />

   <CloudTemplate x={-1.828} y={ 0.435} z={1.121} rotationX={2.452} rotationY={1.461} rotationZ={-2.444} scaleX={isMobileView ? 0.102 : 0.107} scaleY={isMobileView ? 0.079 : 0.084} scaleZ={0.079} cloudColor={"#695b85"} cloudOverlineColor={"#614769"} cloudOverlineHoverColor={"#433148"} outlines={outlines} 
   title1={"Win-Win Approach"} fontSize1={0.02}  maxWidth1={0.118} color1={"#cec2b8"} text1X={-1.848} text1Y={0.55} text1Z={1.085} text1RotationX={3.78} text1RotationY={-1.68} text1RotationZ={-2.53} 
   title2={"We focus on providing cost-effective yet high-quality solutions tailored to your business needs             Our aim is to create a satisfying  and mutally beneficial partnership"} 
   fontSize2={0.016}  maxWidth2={0.35} color2={"#cec2b8"} text2X={-1.858} text2Y={0.48} text2Z={0.966} text2RotationX={2.45} text2RotationY={-1.68} text2RotationZ={-3.835} 
   />

    </>
  );
}

export default SalesforcePage;