import React from "react";
import { Circle, Text } from "@react-three/drei";
import * as THREE from 'three';

const TextSection = React.memo( ({ title, color,fontSize,maxWidth,fillOpacity, letterSpacing,lineHeight,backgroundProps, ...props }) => {
  const material = new THREE.MeshBasicMaterial( { color: 0xAEA3A2 } ); 

  return (
    <group {...props}>
      {!!title && (
        <>
         {backgroundProps && ( <Circle args={[1, 32]} {...backgroundProps} material={material}/> )}
      
        <Text
          color={color}
          anchorX="left"
          anchorY="top"
          fontSize={fontSize}
          maxWidth={maxWidth}
          fillOpacity={fillOpacity}
          curveSegments={24}
          brevelSegments={1}
          bevelEnabled
          bevelSize={0.08}
          bevelThickness={0.03}
          letterSpacing={letterSpacing}
          font={"assets/fonts/Mechanical.otf"}
          lineHeight={lineHeight}
        >
          {title}
        </Text>
        </>
      )}

    </group>
  );
})
export default TextSection;