import React, { useEffect, useState,useRef, Suspense } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Home.css";
import ScrollMagic from 'scrollmagic';
import SalesforcePage from './salesforcePage/SalesforcePage';
import { PlayProvider } from './components/mainScene/Play';
import Header from './headerHome/Header';
import SalesforceTransition from './transitions/SalesforceTransition';




export default function AllPages() {
  


  const descriptionRef = useRef(null);
  const salesforcesRef = useRef(null);


  const [isMobileView, setIsMobileView] = useState(false);
  const [previousScrollPosition, setPreviousScrollPosition] = useState(0);


  const [renderDescriptionPage, setRenderDescriptionPage] = useState(true);
  const [renderSalesforcePage, setRenderSalesforcePage] = useState(false);

  const [salesforceProgress, setSalesforceProgress] = useState(0);


  const [descriptionSuspenseFinished, setDescriptionSuspenseFinished] = useState(100);
  const [salesforceSuspenseFinished, setSalesforceSuspenseFinished] = useState(0);

  
  const [imAtDescription,setImAtDescription]=useState(false);
  const [imAtSalesforce,setImAtSalesforce]=useState(false);


  useEffect(() => {
    function checkScreenSize() {
      setIsMobileView(window.innerWidth <= 500); 
    }

    checkScreenSize(); 

    window.addEventListener('resize', checkScreenSize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  const isLandscape = () => window.matchMedia('(orientation:landscape)').matches;
  const [orientation, setOrientation] = useState(isLandscape() ? 'landscape' : 'portrait');
  const [prevOrientation, setPrevOrientation] = useState(orientation);


  //Scoll magic
  useEffect(() => {

    const isMobileView = window.innerWidth <= 500;


    const controller = new ScrollMagic.Controller();
    
      
    
       new ScrollMagic.Scene({
        duration: isMobileView ? 8900 : 10000,
        triggerElement: descriptionRef.current,
        triggerHook: 0,
      })
        .setPin(descriptionRef.current)
        .addTo(controller)
        .on('enter', () => {
        setImAtDescription(true)
        setImAtSalesforce(false)     
        setRenderDescriptionPage(true)
        setDescriptionSuspenseFinished(100)
        window.scrollTo(0, 0);
        })
        .on('leave', () => {
        setRenderSalesforcePage(true)
       })
    
        new ScrollMagic.Scene({
          duration: 14910,
          triggerElement: salesforcesRef.current,
          triggerHook: 0,
        })
        
          .setPin(salesforcesRef.current)
          .addTo(controller)
          .on('enter', () => {      
            setImAtSalesforce(true)       
            setRenderSalesforcePage(true)
            setImAtDescription(false)
          })
          .on('start',() => {      
            setRenderDescriptionPage(false)
          })
        
      

  
  
  }, []);

  

  useEffect(() => {
    const handleScrollChange = (event) => {
      const currentScrollPosition = window.scrollY;

      if (currentScrollPosition !== previousScrollPosition) {
        setPreviousScrollPosition(currentScrollPosition);
      }
    };

    window.addEventListener('scroll', handleScrollChange, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScrollChange);
    };
  }, [previousScrollPosition]);

  useEffect(() => {

    if(salesforceProgress===100){
      setSalesforceSuspenseFinished(salesforceProgress)
    }


    
  }, [
    salesforceProgress,
    descriptionSuspenseFinished,
    salesforceSuspenseFinished,
  
  ]);


  useEffect(() => {
    const preventDefaultScroll = (event) => {
      event.preventDefault();
    };
  
    if ( (imAtDescription && descriptionSuspenseFinished === 100) || (imAtSalesforce && salesforceSuspenseFinished === 100)) {
      
    if (isMobileView) {
        setTimeout(() => {
          document.documentElement.style.overflowY = "visible";
        }, 300);
        document.body.style.overflowY = "";
      } 
      else {
        setTimeout(() => {
          document.body.style.overflowY = "visible";
        }, 100);
        document.documentElement.style.overflowY = "";
      }
  
      window.removeEventListener("DOMMouseScroll", preventDefaultScroll, false);
      window.removeEventListener("wheel", preventDefaultScroll);
      window.removeEventListener("touchmove", preventDefaultScroll);
    } 

 
    else {
      if (isMobileView) {
        document.documentElement.style.overflowY = "hidden";
        document.body.style.overflowY = "";
      } else {
        document.documentElement.style.overflowY = "";
        document.body.style.overflowY = "hidden";
      }
      window.addEventListener("DOMMouseScroll", preventDefaultScroll, false);
      window.addEventListener("wheel", preventDefaultScroll, { passive: false });
      window.addEventListener("touchmove", preventDefaultScroll, { passive: false });
    }
  
    return () => {
      window.removeEventListener("wheel", preventDefaultScroll);
      window.removeEventListener("touchmove", preventDefaultScroll);
    };
  }, [
    descriptionSuspenseFinished,
    imAtDescription,
    salesforceSuspenseFinished,
    imAtSalesforce,
    


    isMobileView,
  ]);
  


  useEffect(() => {
    const preventDefaultScroll = (event) => {
      event.preventDefault();
    };
    if((document.body.style.overflowY==="hidden" || document.documentElement.style.overflowY === "hidden") && imAtSalesforce && salesforceSuspenseFinished === 0){
      setTimeout(() => {
      setSalesforceSuspenseFinished(100);
      }, 10);
      if(isMobileView){
        document.documentElement.style.overflowY = "hidden";
        document.body.style.overflowY = ""
      }
      else{
        document.documentElement.style.overflowY=""
        document.body.style.overflowY = "hidden"
      }
      window.removeEventListener('wheel', preventDefaultScroll);
      window.removeEventListener('touchmove', preventDefaultScroll);
    }
  },[imAtSalesforce,salesforceSuspenseFinished,isMobileView])



const onWindowResize = () => {
  clearTimeout(window.resizeLag);

  window.resizeLag = setTimeout(() => {
    delete window.resizeLag;
    const currentOrientation = isLandscape() ? 'landscape' : 'portrait';

    if (currentOrientation !== orientation) {
      setPrevOrientation(orientation);
      setOrientation(currentOrientation);
    }
  }, 100);
};

useEffect(() => {
  if (orientation !== prevOrientation) {
    window.location.reload();
  }
}, [orientation, prevOrientation]);

useEffect(() => {
  onWindowResize();
  window.addEventListener('resize', onWindowResize);

  return () => {
    window.removeEventListener('resize', onWindowResize);
  };
}, []);
  

  return (
      <>
      <div id="description">
      <div ref={descriptionRef} className="panel-descriptionScene">
      {renderDescriptionPage && <Suspense fallback={null}> <PlayProvider> <Header/> </PlayProvider> </Suspense> }
      </div>
      </div>

      <div id="salesforce">
      <div ref={salesforcesRef} className="panel-salesforceScene">
      {renderSalesforcePage &&  <Suspense fallback={<SalesforceTransition onProgressChange={setSalesforceProgress}/>}><SalesforcePage previousScrollPosition={previousScrollPosition} />  </Suspense>}
      </div>
      </div>
      </>
  );
}

