import React from "react";
import './TeamTransition.css';
import { useProgress } from "@react-three/drei";

export default function TeamTransition() {

  const { progress } = useProgress();


  return (

  <div className="team"> 

  <h2 className="teamText">

    <div>
  <span className="letters">C</span>
  <span className="letters">A</span>
  <span className="letters">L</span>
  <span className="letters">M</span>
  </div>

  <div>
  <span className="letters">C</span>
  <span className="letters">O</span>
  <span className="letters">D</span>
  <span className="letters">E</span>
  <span className="letters">R</span>
  <span className="letters">S</span>
  </div>

  <div>
  <span className="letters">T</span>
  <span className="letters">E</span>
  <span className="letters">A</span>
  <span className="letters">M</span>
  </div>

  <div>
  <span className="letters mt-2">Loading {String(progress).slice(0, 3)} %</span>
  </div>
  
 </h2>


  </div>

  );

};

