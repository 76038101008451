import React, { useState, useEffect } from 'react';
import "./SideBar.css";
import SideBarMobileView from './SideBarMobileView';
import SideBarDesktopView from './SideBarDesktopView';


export default function SideBar() {
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    function checkScreenSize() {
      setIsMobileView(window.innerWidth <= 500); // Adjust the screen width as needed
    }

    checkScreenSize(); // Initial check

    window.addEventListener('resize', checkScreenSize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  return (
    <div className='sideBarMenu'>
      {isMobileView ? (
        <SideBarMobileView />
      ) : (
        <SideBarDesktopView />
      )}
    </div>
  );
}

