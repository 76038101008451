import React, { useEffect } from "react";
import './SalesforceTransition.css';
import { useProgress } from "@react-three/drei";

export default function SalesforceTransition({ onProgressChange }) {
  const { progress } = useProgress();

  useEffect(() => {
    if (onProgressChange) {
      onProgressChange(progress);
    }
  }, [progress, onProgressChange]);

  return (
    <div className="title">
    <h1 className="text1"> Salesforce </h1>
    <h1 className="text2"> World</h1>
    <h1 className="textSalesforce"> Loading {String(progress).slice(0, 3)} % </h1>
   </div>
  );

};

