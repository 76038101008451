import React from 'react';
import "./JavaInfoPage.css"

export default function JavaInfoPage() {


  return (

<body>

    <div class="hero_area">

    <div class="hero_bg_box">
    <div class="bg_img_box">
    <img src="assets/images/hero-bg.png" alt="" />
    </div>
    </div>

    <header className="header_section">
    <div className="container-fluid">
      <nav className="custom_nav-container navbar-expand">   

          <ul className="navbar-nav">
            <li className="nav-item active">
              <a className="nav-link" href="#home"> Home </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#distinction"> Distinction </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#story"> Story </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#projects"> Projects </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#methodology"> Methodology </a>
            </li>
          </ul>

      </nav>
    </div>
    </header>

    <div class="home" id="home">

    <div class="container">
          <div class="row">
            <div class="col-md-6 ">
              <div class="detail-box">
                <h1>
                ​Welcome to Java
                </h1>
                <p>
                    Java was created by James Gosling at Sun Microsystems in the mid-1990s as a platform-independent programming language. It became popular due to its "write once, run anywhere" capability.
                    
                </p>
                <p>
               Released in 1995, Java evolved with new features over the years and remains widely used in web development, enterprise applications, and Android development. Oracle acquired Java in 2010.
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="img-box">
                <img src="assets/images/java.png" alt="" />
              </div>
            </div>
            <div className='col-md-6'>    
                  
            <div class="technology_container">
            <div class="row">

            <div class="col-3">
                <div class="img-box1">
                  <img src="assets/images/spring.png" class="img1" alt=""/>
                </div>
            </div>

            <div class="col-3">
                <div class="img-box1">
                  <img src="assets/images/hibernate.png" class="img2" alt=""/>
                </div>
            </div>

            <div class="col-3">
                <div class="img-box1">
                  <img src="assets/images/maven.png" class="img3" alt="" />
                </div>
            </div>

            <div class="col-3">
                <div class="img-box1">
                  <img src="assets/images/tomcat.png" class="img4" alt=""/>
                </div>
            </div>
 
          </div>
        </div>
      </div>

    </div>

    </div>

    </div>

    </div>


    <div class="distinction layout_padding" id='distinction'>
        <div class="container">
          <div class="heading_container heading_center">
            <h2>
            What Java & Spring Boot offers <span>us</span>
            </h2>

            <p>
              Java Spring Boot is a powerful framework that streamlines the development of Java applications, particularly those with a focus on enterprise-level capabilities
            </p>

            <p>
              Here’s how Spring Boot helps in developing our projects: 
            </p>

          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="box">
                <div class="img-box">
                  <img src="assets/images/s1.png" alt="" />
                </div>
                <div class="detail-box">
                  <h5>
                 Rapid Development & Scalability
                  </h5>
                  <p>
                    Autoconfiguration and starter templates streamline setup and reduce boilerplate code 
                    <br></br>
                    Supports microservices, load balancing, and caching to handle growing user demands and data
                  </p> 
                  
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="box">
                <div class="img-box">
                  <img src="assets/images/s2.png" alt="" />
                </div>
                <div class="detail-box">
                  <h5>
                Database Integration & Security
                  </h5>
                  <p>
                    Easy integration with both relational (using Spring Data JPA) and NoSQL databases
                    <br></br>
                    Robust security features with Spring Security, including authentication, authorization, and encryption
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="box ">
                <div class="img-box">
                  <img src="assets/images/s3.png" alt="" />
                </div>
                <div class="detail-box">
                  <h5>
              RESTful APIs & Customization
                  </h5>
                  <p>
                    Simplifies the creation and integration of RESTful APIs for connecting with other systems
                    <br></br>
                    Flexible for building dynamic web interfaces and implementing custom business logic
                  </p>
                </div>
              </div>
            </div>
                  <div class="col-md-12">
              <div class="box ">
                <div class="img-box">
                  <img src="assets/images/w1.png" alt="" />
                </div>
                <div class="detail-box">
                  <h5>
                 Testing , Deployment & Monitoring
                  </h5>
                  <p>
                    Comprehensive support for unit, integration, and end-to-end testing
                    <br></br>
                    Easily deployable to cloud platforms, enabling scaling and high availability
                    <br></br>
                    Built-in monitoring and health checks with Spring Boot Actuator
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>


    <div class="story layout_padding" id='story'>
      <div class="container">
        <div class="heading_container heading_center">
          <h2>
            Our <span>Story</span>
          </h2>
        </div>
        <div class="row">
          <div class="col-md-6 ">
            <div class="img-box">
              <img src="assets/images/about-img.png" alt="" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="detail-box">
              <h3>
                We Are Calm Coders
              </h3>
              <p>
              Our journey began with a vision to revolutionize the digital landscape by harnessing the power of cutting-edge technologies
              </p>
              <p>
              ​Since our inception, we've remained dedicated to this mission, continuously evolving and adapting to meet the ever-changing needs of our clients
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="projects layout_padding" id='projects'>
      <div class="container">

        <div class="heading_container heading_center">
          <h2>
          Projects
          </h2>
        </div>

        <div class="projects_container">
          <div className='row'>

          <div className='col-lg-6 col-12'>
          <div class="box">
            <div class="img-box">
              <img src="assets/images/caricambi.png" alt="" class="img-box-project1"/>
            </div>
            <div class="detail-box">
              <h5>
                Caricambi
              </h5>
              <p> 
              The ultimate solution for managing your automotive sales and customer relationships. 
              Our intuitive interface empowers dealerships to streamline operations, track leads and enhance customer satisfaction.
              With real-time insights, automated follow-ups and seamless integration with your existing systems, our CRM helps you stay ahead in the competitive market. 
              Experience the future of automotive sales with our comprehensive and user-friendly platform
              </p>
            </div>
          </div>
          </div>

          <div className='col-lg-6 col-12'>
          <div class="box">
            <div class="img-box">
              <img src="assets/images/sniper.png" alt="" class="img-box-project2"/>
            </div>
            <div class="detail-box">
              <h5>
               Sniper
              </h5>
              <p>
              Sniper where every lead becomes an opportunity and every interaction is optimized for success.
              Our advanced system empowers sales teams to efficiently manage leads, track sales pipelines and nurture relationships with clients.
              With customizable dashboards, insightful analytics, and automated workflows, our page simplifies sales processes and drives revenue growth. 
              Say goodbye to scattered data and missed opportunities - embrace the power of our page and unlock your team's full potential
              </p>
            </div>
          </div>
          </div>

          <div className='col-lg-6 col-12'>
          <div class="box">
            <div class="img-box">
              <img src="assets/images/calm-coders.png" alt="" class="img-box-project3"/>
            </div>
            <div class="detail-box">
              <h5>
                Calm Coders
              </h5>
              <p>    
               3D scrolling experience! Step into a world where every scroll reveals a new dimension of creativity and innovation. 
               Navigate through stunning landscapes, intricate designs, and captivating animations as you journey deeper into our digital realm. 
               From breathtaking visuals to interactive elements, every detail is meticulously crafted to engage and inspire.
               Explore at your own pace, uncover hidden surprises, and immerse yourself in a scroll like never before. 
               Welcome to the future of web design - where every scroll tells a story in three dimensions
              </p>
            </div>
          </div>
          </div>

          <div className='col-lg-6 col-12'>
          <div class="box">
            <div class="img-box">
              <img src="assets/images/probranding.png" alt="" class="img-box-project4"/>
            </div>
            <div class="detail-box">
              <h5>
               ProBranding
              </h5>
              <p>    
                      ProBranding is a comprehensive project focused on CRM and user management.
                      It aims to streamline and enhance interactions with customers by integrating features that manage customer data, track communications, and analyze customer behavior.
                      Additionally, it includes robust user management functionalities to handle user roles, permissions, and authentication, ensuring secure and efficient access to various system components.
                      The project is designed to improve business operations, foster better customer relationships, and optimize user experiences.
              </p>
            </div>
          </div>
          </div>


          </div>
        </div>
      </div>
    </div>


    <div class="methodology layout_padding" id='methodology'>
      <div class="container-fluid">
        <div class="heading_container heading_center">
          <h2 class="">
            <span> How </span> We Do It
          </h2>
        </div>

        <div class="team_container">
          <div class="row">
            <div class="col-lg-4 col-12">
              <div class="box">
                <div class="img-box">
                  <img src="assets/images/strategic-planning.jpg" class="img1" alt="" />
                </div>
                <div class="detail-box">
                  <h5>
                  Strategic Planning
                  </h5>
                  <p>
                  ​We start by gaining a deep understanding of your business objectives and target audience, allowing us to devise a strategic plan tailored to your specific needs
                  </p>
                </div>
      
              </div>
            </div>
            <div class="col-lg-4 col-12">
              <div class="box">
                <div class="img-box">
                  <img src="assets/images/agile-software.png" class="img1" alt="" />
                </div>
                <div class="detail-box">
                  <h5>
                  Agile Development​
                  </h5>
                  <p>
                  ​Our agile development methodology ensures flexibility and transparency throughout the project lifecycle, enabling us to adapt to changing requirements and deliver results efficiently
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-12">
              <div class="box">
                <div class="img-box">
                  <img src="assets/images/quality-assurance.jpg" class="img1" alt="" />
                </div>
                <div class="detail-box">
                  <h5>
                  ​Quality Assurance
                  </h5>
                  <p>
                  ​We are here to stringent quality standards at every stage of development, conducting rigorous testing to guarantee the reliability, security, and performance of our solutions
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

      
      <div class="projects_web layout_padding" id='projects'>
      <div class="container">
        <div class="heading_container_web heading_center">  <h2> Structure </h2>  </div>

     <div class="stucture_container d-flex justify-content-center">
          <img src="assets/images/angularSpring.png" alt="" class="img-box-project1"/>
        </div>
      </div>
    </div>

    <div class="info_section layout_padding2">
      <div class="container">
        <div class="row">
          <div class="col-md-5 col-lg-4 info_col">
            <div class="info_contact">
              <h4>
                Address
              </h4>
              <div class="contact_link_box">
                <a href="#a">
                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                  <span>
                   Square 21
                  </span>
                </a>
 
              </div>

              <h4>
                Contacts
              </h4>

              <div class="contact_link_box">
               
                <a href="#a">
                  <i class="fa fa-phone" aria-hidden="true"></i>
                  <span>
                   +39 351 886 8016
                  </span>           
                </a>
                <a href="#a">
                  <i class="fa fa-phone" aria-hidden="true"></i>
                  <span>
                   +355 69 35 91 564
                  </span>           
                </a>
                <a href="/contact-us">
                  <i class="fa fa-envelope" aria-hidden="true"></i>
                  <span>
                    info@calm-coders.com
                  </span>
                </a>
          
              </div>
            </div>
       
          </div>
          <div class="col-md-5 col-lg-4 info_col">
            <div class="info_detail">
              <h4>
                Info
              </h4>
              <p style={{width:"250px"}}>
              Calm Coders team provides quality web design & development services.
                </p>
   
            </div>
          </div>
          <div class="col-md-2 col-lg-4 mx-auto info_col">
            <div class="info_link_box">
              <h4>
                Links
              </h4>
              <div class="info_links">
                <a className="nav-link" href="#home">
                  Home
                </a>
                <a className="nav-link" href="#distinction">
                  Distinction 
                </a>
                <a className="nav-link" href="#story">
                   Story
                </a>
                <a className="nav-link" href="#projects">
                   Projects 
                </a>
                <a className="nav-link" href="#methodology"> 
                   Methodology
                </a>
              </div>
            </div>
          </div>

        </div>
      </div>
      </div>
      


    <div class="footer_section">
      <div class="container">
        <p>
          &copy; All Rights Reserved By Calm Coders
        </p>
      </div>
    </div>



</body>

  );
}

