import React, { Suspense, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./Home.css";
import SideBar from './sideBarMenu/SideBar';
import {BrowserRouter as Router,Route,Switch} from 'react-router-dom';
import SalesforceInfoPage from './salesforceInfoPage/SalesforceInfoPage';
import WebDevelopmentInfoPage from './webDevelopmentInfoPage/WebDevelopmentInfoPage';
import AllPages from './AllPages';
import TeamTransition from './transitions/TeamTransition';
import ContactPage from './contactPage/Contact';
import JavaComponent from './javaComponent/JavaComponent';
import WebDevelopmentComponent from './webDevelopmentComponent/WebDevelopmentComponent';
import JavaInfoPage from './javaInfoPage/JavaInfoPage';
import JavaTeamPage from './teamPage/JavaTeamPage';
import SalesforceTeamPage from './teamPage/SalesforceTeamPage';
import PhpTeamPage from './teamPage/PhpTeamPage';
import { NavProvider } from './NavContext';

export default function Home() {



  useEffect(() => {

    const clearCacheData = async () => {
      const cacheNames = await caches.keys();
      await Promise.all(
          cacheNames.map(async (name) => {
              await caches.delete(name);
          })
      );
    };
    
    const isAtBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight;
    if (typeof window !== 'undefined' || isAtBottom) {
      window.scrollTo(0, 0);
      clearCacheData();
    }

    }, []);

  

  return (
    <>
     
          <Router>

          <Switch>
          <NavProvider>
          <Route exact path="/">
       
          <SideBar/>
          <AllPages/>
  
          </Route>

          <Route exact path="/salesforceHub">

          <SideBar/>
          <SalesforceInfoPage/>
      
          </Route>

          <Route exact path="/javaLab">
   
          <SideBar/>
          <JavaComponent />

          </Route>

          <Route exact path="/javaCentral">

          <SideBar/>
          <JavaInfoPage/>
   
          </Route>
          
          <Route exact path="/webEssentials">
    
          <SideBar/>
          <WebDevelopmentInfoPage/>

          </Route>

          <Route exact path="/webCastle">
  
          <SideBar/>
          <WebDevelopmentComponent/>
      
          </Route>

          <Route exact path="/javaTeam">     
          <SideBar/>
          <Suspense fallback={<TeamTransition/>}> <JavaTeamPage/> </Suspense>
          </Route>

          <Route exact path="/salesforceTeam">     
          <SideBar/>
          <Suspense fallback={<TeamTransition/>}> <SalesforceTeamPage/> </Suspense>
          </Route>

          <Route exact path="/phpTeam">     
          <SideBar/>
          <Suspense fallback={<TeamTransition/>}> <PhpTeamPage /> </Suspense>
          </Route>
          
          <Route exact path="/contact-us">
          <SideBar/>
          <ContactPage/>
          </Route>

         </NavProvider>
          </Switch>

          </Router>

    </>
  );
}