import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import "./Contact.css"
//import { Perf } from 'r3f-perf';


export default function ContactPage() {
    const form = useRef();
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      message: '',
    });
  
    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs
        .sendForm('service_yelzkj9', 'template_blejng9', form.current, 'ExSgVTpCbR4zDX1bG')
        .then(
          () => {
            console.log('SUCCESS!');
            setFormData({ name: '', email: '', message: '' });
          },
          (error) => {
            console.log('FAILED...', error.text);
          }
        );
    };
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    };
  
    return (
      <div className="wrapper">
        <div className="inner">
          <form ref={form} onSubmit={sendEmail}>
            <h3 className='text-white'>Contact Us</h3>
            <p className='text-white'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
            <label className="form-group">
              <input
                type="text"
                name="name"
                className="form-control"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <span>Your Name</span>
              <span className="border"></span>
            </label>
            <label className="form-group">
              <input
                type="email"
                name="email"
                className="form-control"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <span>Your Mail</span>
              <span className="border"></span>
            </label>
            <label className="form-group">
              <textarea
                name="message"
                className="form-control"
                value={formData.message}
                onChange={handleChange}
                required 
                cols="30"
                rows="10"
              ></textarea>
              <span>Your Message</span>
           
            </label>
            <button type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    );
  }