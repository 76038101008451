import React, { useState, useEffect, useCallback } from "react";
import { Text } from "@react-three/drei";

const TextSection = React.memo(
  ({
    title,
    subtitle,
    percentage,
    percentageColor,
    color,
    info,
    infoColor,
    command,
    commandColor,
    firstCommandPosition,
    percentageWidth,
    percentagePosition,
    ...props
  }) => {
    const [displayedCommand, setDisplayedCommand] = useState("");
    const displayCommandText = useCallback(() => {
      if (command) {
        let index = 0;
        const textLength = command.length;
        const intervalId = setInterval(() => {
          setDisplayedCommand((prevText) => {
            if (index < textLength) {
              return prevText + command.charAt(index++);
            } else {
              clearInterval(intervalId);
              return prevText;
            }
          });
        }, 10);

        return () => clearInterval(intervalId);
      }
    }, [command]);

    useEffect(() => {
      displayCommandText();
    }, [displayCommandText]);

    return (
      <group {...props}>
        {!!title && (
          <Text
            color={color}
            anchorX="left"
            anchorY="bottom"
            fontSize={0.43}
            maxWidth={39}
            lineHeight={1}
            font={"assets/fonts/Mechanical.otf"}
          >
            {title}
          </Text>
        )}

        <Text
          color={percentageColor}
          anchorX="left"
          anchorY="bottom"
          fontSize={0.43}
          maxWidth={percentageWidth}
          lineHeight={1.4}
          font={"assets/fonts/Mechanical.otf"}
          position={percentagePosition}
        >
          {percentage}
        </Text>

        <Text
          color={infoColor}
          anchorX="left"
          anchorY="bottom"
          fontSize={0.43}
          maxWidth={39}
          lineHeight={1}
          font={"assets/fonts/Mechanical.otf"}
        >
          {info}
        </Text>

        <Text
          color={commandColor}
          anchorX="left"
          anchorY="bottom"
          fontSize={0.43}
          maxWidth={39}
          lineHeight={1}
          font={"assets/fonts/Mechanical.otf"}
          position={firstCommandPosition}
        >
          {displayedCommand}
        </Text>
      </group>
    );
  }
);
export default TextSection;
