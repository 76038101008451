import React, { Component, lazy, useContext, useEffect, useState } from 'react';
import "./WebDevelopmentScene.css";
import { getProject, val } from '@theatre/core';
import { SheetProvider, PerspectiveCamera, useCurrentSheet } from '@theatre/r3f';
import { Float, Html} from '@react-three/drei';
import WebDevelopmentStateDesktop from "../animationScenes/WebDevelopmentSceneDesktop.json"
import WebDevelopmentStateMobile from "../animationScenes/WebDevelopmentSceneMobile.json"
import { Canvas, useFrame } from '@react-three/fiber';
import TextSection from '../components/webDevelopmentScene/TextSection';
import TextSection2 from '../components/webDevelopmentScene/TextSection2';
import { NavContext } from '../NavContext';
//import { Perf } from 'r3f-perf';

const Text1E = lazy(() => import("../components/webDevelopmentScene/Text1E"));
const Text2E = lazy(() => import("../components/webDevelopmentScene/Text2E"));
const Text3E = lazy(() => import("../components/webDevelopmentScene/Text3E"));
const Text4E = lazy(() => import("../components/webDevelopmentScene/Text4E"));
const Text5E = lazy(() => import("../components/webDevelopmentScene/Text5E"));
const Text6E = lazy(() => import("../components/webDevelopmentScene/Text6E"));
const Text7E = lazy(() => import("../components/webDevelopmentScene/Text7E"));
const Text8E = lazy(() => import("../components/webDevelopmentScene/Text8E"));
const Header = lazy(() => import("../components/webDevelopmentScene/CCLogoFinaleMHeader"));
const WebDevelopmentObject = lazy(() => import("../components/webDevelopmentScene/WebDevelopment"));
const Text31WebCastle = lazy(() => import("../components/webDevelopmentScene/Text31WebCastle"));
const Text32WebCastle = lazy(() => import("../components/webDevelopmentScene/Text32WebCastle"));
const Text33WebCastle = lazy(() => import("../components/webDevelopmentScene/Text33WebCastle"));
const Text34WebCastle = lazy(() => import("../components/webDevelopmentScene/Text34WebCastle"));
const Sky1 = lazy(() => import("../components/webDevelopmentScene/Sky1"));


class WebDevelopmentScene extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileView: false
    };
  }

  componentDidMount() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkScreenSize);
  }

  checkScreenSize = () => {
    this.setState({ isMobileView: window.innerWidth <= 500 });
  }

  render() {
    const { previousScrollPosition } = this.props;
    const { isMobileView } = this.state;

    const sheetDesktop = getProject('WebDevelopmentSceneDesktop', { state: WebDevelopmentStateDesktop }).sheet('Scene');
    const sheetMobile = getProject('WebDevelopmentSceneMobile', { state: WebDevelopmentStateMobile }).sheet('Scene');

    return (
      <Canvas gl={{ preserveDrawingBuffer: true }}>
        <SheetProvider sheet={isMobileView ? sheetMobile : sheetDesktop}>
          <Scene previousScrollPosition={previousScrollPosition} />
        </SheetProvider>
      </Canvas>
    );
  }
}

function Scene({previousScrollPosition}) {
  const { isNavOpen } = useContext(NavContext);

  const [isMobileView, setIsMobileView] = useState(false);
  useEffect(() => {
    function checkScreenSize() {
      setIsMobileView(window.innerWidth <= 500); 
    }
  
    checkScreenSize(); 
  
    window.addEventListener('resize', checkScreenSize);
  
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  const sheet = useCurrentSheet();
  const scroll = isMobileView ? 156000 : 93500;

  useFrame(() => {
    const sequenceLength = val(sheet.sequence.pointer.length);
    sheet.sequence.position = previousScrollPosition / scroll * sequenceLength;
  });

  const [dropDistance1, setDropDistance1] = useState(0);
  useEffect(() => {
    let animationFrameId;

    const dropAnimation = () => {
      if (isMobileView && sheet.sequence.position > 0.04) {
        setDropDistance1((prevDistance) => Math.min(prevDistance + 0.1, 5));
      } 
      else if (sheet.sequence.position > 0.05) {
        setDropDistance1((prevDistance) => Math.min(prevDistance + 0.1, 5));
      } 
      else {
        setDropDistance1((prevDistance) => Math.max(prevDistance - 0.1, 0));
      }

      animationFrameId = requestAnimationFrame(dropAnimation);
    };

    dropAnimation();

    return () => cancelAnimationFrame(animationFrameId);
  }, [isMobileView, sheet.sequence.position]);
  
  const [dropDistance2, setDropDistance2] = useState(0);
  useEffect(() => {
    let animationFrameId;

    const dropAnimation = () => {
      if (isMobileView && sheet.sequence.position > 0.08) {
        setDropDistance2((prevDistance) => Math.min(prevDistance + 0.1, 5));
      } 
      else if (sheet.sequence.position > 0.14) {
        setDropDistance2((prevDistance) => Math.min(prevDistance + 0.1, 5));
      } 
      else {
        setDropDistance2((prevDistance) => Math.max(prevDistance - 0.1, 0));
      }

      animationFrameId = requestAnimationFrame(dropAnimation);
    };

    dropAnimation();

    return () => cancelAnimationFrame(animationFrameId);
  }, [isMobileView, sheet.sequence.position]);

  const [dropDistance3, setDropDistance3] = useState(0);
  useEffect(() => {
    let animationFrameId;

    const dropAnimation = () => {
      if (isMobileView && sheet.sequence.position > 0.119) {
        setDropDistance3((prevDistance) => Math.min(prevDistance + 0.1, 5));
      } 
      else if (sheet.sequence.position > 0.2) {
        setDropDistance3((prevDistance) => Math.min(prevDistance + 0.1, 5));
      } 
      else {
        setDropDistance3((prevDistance) => Math.max(prevDistance - 0.1, 0));
      }

      animationFrameId = requestAnimationFrame(dropAnimation);
    };

    dropAnimation();

    return () => cancelAnimationFrame(animationFrameId);
  }, [isMobileView, sheet.sequence.position]);

  const [dropDistance4, setDropDistance4] = useState(0);
  useEffect(() => {
    let animationFrameId;

    const dropAnimation = () => {
      if (isMobileView && sheet.sequence.position > 0.153) {
        setDropDistance4((prevDistance) => Math.min(prevDistance + 0.1, 5));
      } 
      else if (sheet.sequence.position > 0.27) {
        setDropDistance4((prevDistance) => Math.min(prevDistance + 0.1, 5));
      } 
      else {
        setDropDistance4((prevDistance) => Math.max(prevDistance - 0.1, 0));
      }

      animationFrameId = requestAnimationFrame(dropAnimation);
    };

    dropAnimation();

    return () => cancelAnimationFrame(animationFrameId);
  }, [isMobileView, sheet.sequence.position]);

  const [dropDistance5, setDropDistance5] = useState(0);
  useEffect(() => {
    let animationFrameId;

    const dropAnimation = () => {
      if (isMobileView && sheet.sequence.position > 0.185) {
        setDropDistance5((prevDistance) => Math.min(prevDistance + 0.1, 5));
      } 
      else if (sheet.sequence.position > 0.34) {
        setDropDistance5((prevDistance) => Math.min(prevDistance + 0.1, 5));
      } 
      else {
        setDropDistance5((prevDistance) => Math.max(prevDistance - 0.1, 0));
      }

      animationFrameId = requestAnimationFrame(dropAnimation);
    };

    dropAnimation();

    return () => cancelAnimationFrame(animationFrameId);
  }, [isMobileView, sheet.sequence.position]);

  const [dropDistance6, setDropDistance6] = useState(0);
  useEffect(() => {
    let animationFrameId;

    const dropAnimation = () => {
      if (isMobileView && sheet.sequence.position > 0.217) {
        setDropDistance6((prevDistance) => Math.min(prevDistance + 0.1, 5));
      } 
      else if (sheet.sequence.position > 0.4) {
        setDropDistance6((prevDistance) => Math.min(prevDistance + 0.1, 5));
      } 
      else {
        setDropDistance6((prevDistance) => Math.max(prevDistance - 0.1, 0));
      }

      animationFrameId = requestAnimationFrame(dropAnimation);
    };

    dropAnimation();

    return () => cancelAnimationFrame(animationFrameId);
  }, [isMobileView, sheet.sequence.position]);

  const [dropDistance7, setDropDistance7] = useState(0);
  useEffect(() => {
    let animationFrameId;

    const dropAnimation = () => {
      if (isMobileView && sheet.sequence.position > 0.256) {
        setDropDistance7((prevDistance) => Math.min(prevDistance + 0.1, 5));
      } 
      else if (sheet.sequence.position > 0.47) {
        setDropDistance7((prevDistance) => Math.min(prevDistance + 0.1, 5));
      } 
      else {
        setDropDistance7((prevDistance) => Math.max(prevDistance - 0.1, 0));
      }

      animationFrameId = requestAnimationFrame(dropAnimation);
    };

    dropAnimation();

    return () => cancelAnimationFrame(animationFrameId);
  }, [isMobileView, sheet.sequence.position]);

  const [dropDistance8, setDropDistance8] = useState(0);
  useEffect(() => {
    let animationFrameId;

    const dropAnimation = () => {
      if (isMobileView && sheet.sequence.position > 0.284) {
        setDropDistance8((prevDistance) => Math.min(prevDistance + 0.1, 5));
      } 
      else if (sheet.sequence.position > 0.54) {
        setDropDistance8((prevDistance) => Math.min(prevDistance + 0.1, 5));
      } 
      else {
        setDropDistance8((prevDistance) => Math.max(prevDistance - 0.1, 0));
      }

      animationFrameId = requestAnimationFrame(dropAnimation);
    };

    dropAnimation();

    return () => cancelAnimationFrame(animationFrameId);
  }, [isMobileView, sheet.sequence.position]);

  const [showElements, setShowElements] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowElements(false);
    }, 100);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <>
      <ambientLight />
      <PerspectiveCamera
        theatreKey="Camera"
        makeDefault
        fov={90}
        near={4}
        far={-5}
      />
  {/* Directional Light */}
  <directionalLight position={[0, 10, 0]} intensity={1.0} />

{/* Point Light */}
<pointLight position={[5, 5, 5]} intensity={1.0} distance={10} decay={2} />

{/* Spot Light */}
<spotLight position={[0, 10, 0]} angle={Math.PI / 4} intensity={1.0} distance={10} decay={2} />

{/* Hemisphere Light */}
<hemisphereLight skyColor="#00BFFF" groundColor="#D1692F" intensity={1.0} />
{/* directional Light Above Object */}
<directionalLight position={[100, 100, 60]} intensity={2}  decay={1} />
<directionalLight position={[-100, 100, 90]} intensity={2}  decay={1} />

    <WebDevelopmentObject/>
    <Sky1/>

    {showElements && (
    <>
    <Text1E />
    <Text2E />
    <Text3E />
    <Text4E />
    <Text5E />
    <Text6E />
    <Text7E />
    <Text8E />
    </>
    )}

    {isMobileView && sheet.sequence.position > 0.04 ? ( 
    <> 
    <Text1E scale={[4,4,4]} position={[0.3,75 - dropDistance1,-380]} rotation={[0.1,Math.PI,0]} />
    <TextSection title={"The Art and Science of  Web Development"} position={[1,81.35 - dropDistance1,-379.5]} rotation={[0.1,Math.PI,0]} fontSize={0.31} maxWidth={2.9} fillOpacity={3} letterSpacing={-0.05} color={"#3c291c"}/>
    <TextSection title={"Creating Interactive & Responsive Websites and Web Applications for a Digital World"} position={[1.55,80.4 - dropDistance1,-379.5]} rotation={[0.1,Math.PI,0]} fontSize={0.3}  maxWidth={4} fillOpacity={4} letterSpacing={-0.05} color={"#3c291c"} />
    </>
    ) : sheet.sequence.position > 0.05 ? (
    <> 
    <Text1E scale={[4,4,4]}  position={[0.3,75 - dropDistance1,-380]} rotation={[0.1,Math.PI,0]}/>
    <TextSection title={"The Art and Science of  Web Development"} position={[1,81.35 - dropDistance1,-379.5]} rotation={[0.1,Math.PI,0]} fontSize={0.31} maxWidth={2.9} fillOpacity={3} letterSpacing={-0.05} color={"#3c291c"}/>
    <TextSection title={"Creating Interactive & Responsive Websites and Web Applications            for a Digital World"} position={[1.7,80.35 - dropDistance1,-379.5]} rotation={[0.1,Math.PI,0]} fontSize={0.3}  maxWidth={5} fillOpacity={4} letterSpacing={-0.05} color={"#3c291c"} />
    </>
    ) : null} 

    {isMobileView && sheet.sequence.position > 0.08 ? ( 
    <> 
    <Text2E scale={[4.8,4.8,4.8]} position={[3,75 - dropDistance2,-340]} rotation={[0.1,Math.PI,0]}/>
    <TextSection title={"It refers to the tasks associated"} position={[5.2,83.15 - dropDistance2,-339.4]} rotation={[0.1,Math.PI,0]} fontSize={0.27} maxWidth={5.1} fillOpacity={4} letterSpacing={-0.1} color={"#204228"}/>
    <TextSection title={"with creating,building and maintaining websites and web applications that run      online on a browser"} position={[4.95,82.35 - dropDistance2,-339.5]} rotation={[0.1,Math.PI,0]} fontSize={0.29}  maxWidth={5.1} fillOpacity={4} letterSpacing={-0.1} lineHeight={2.1} color={"#204228"}/>
    </>
    ) : sheet.sequence.position > 0.14 ? (
    <> 
    <Text2E scale={[4.8,4.8,4.8]} position={[3,75 - dropDistance2,-340]} rotation={[0.1,Math.PI,0]}/>
    <TextSection title={"It refers to the tasks associated"} position={[5,83.15 - dropDistance2,-339.5]} rotation={[0.1,Math.PI,0]} fontSize={0.29} maxWidth={5.1} fillOpacity={4} letterSpacing={-0.1} color={"#204228"}/>
    <TextSection title={"with creating , building and maintaining websites and web applications                              that run online on a browser"} position={[5.15,82.35 - dropDistance2,-339.5]} rotation={[0.1,Math.PI,0]} fontSize={0.29}  maxWidth={5.1} fillOpacity={4} letterSpacing={-0.1} lineHeight={2} color={"#204228"}/>
    </>
    ) : null} 

    {isMobileView && sheet.sequence.position > 0.119 ? ( 
    <> 
    <Text3E scale={[4.8,4.8,4.8]} position={[-3,75 - dropDistance3,-300]} rotation={[0.1,Math.PI,0]}/>
    <TextSection title={"However it may also include web design , web programming and database managemenet"} position={[-4.5,82.4 - dropDistance3,-299.1]} rotation={[0.1,Math.PI,0]} fontSize={0.32} maxWidth={3.8} fillOpacity={6} letterSpacing={-0.1} lineHeight={1.75} color={"#3c291c"} />
    </>
    ) : sheet.sequence.position > 0.2 ? (
    <> 
    <Text3E scale={[4.8,4.8,4.8]} position={[-3,75 - dropDistance3,-300]} rotation={[0.1,Math.PI,0]}/>
    <TextSection title={"However it may also include web design , web programming         and database managemenet"} position={[-4.4,82.3 - dropDistance3,-299.1]} rotation={[0.1,Math.PI,0]} fontSize={0.32} maxWidth={4} fillOpacity={6} letterSpacing={-0.1} lineHeight={1.75} color={"#3c291c"} />
    </>
    ) : null} 

    {isMobileView && sheet.sequence.position > 0.153? ( 
    <> 
    <Text4E scale={[4.5,4.5,4.5]} position={[10,75 - dropDistance4,-260]} rotation={[0.1,Math.PI,0]}/>
    <TextSection title={"There are three main types          of  web development"} position={[3.9,83.9 - dropDistance4,-259.5]} rotation={[0.1,Math.PI,0]} fontSize={0.3} maxWidth={3.7} fillOpacity={5} letterSpacing={-0.1} lineHeight={1.5} color={"#3c291c"} />
    </>
    ) : sheet.sequence.position > 0.27 ? (
    <> 
    <Text4E scale={[4.5,4.5,4.5]} position={[10,75 - dropDistance4,-260]} rotation={[0.1,Math.PI,0]}/>
    <TextSection title={"There are three main types          of web development"} position={[3.98,83.9 - dropDistance4,-259.5]} rotation={[0.1,Math.PI,0]} fontSize={0.3} maxWidth={3.7} fillOpacity={5} letterSpacing={-0.1} lineHeight={1.5} color={"#3c291c"} />
    </>
    ) : null} 

    {isMobileView && sheet.sequence.position > 0.185? ( 
     <> 
     <Text5E scale={[4.8,4.8,4.8]} position={[-3,75 - dropDistance5,-220]} rotation={[0.1,Math.PI,0]} />
     <TextSection title={"Front-end development  Back-end development     Full-stack development"} position={[-1.2,83.5 - dropDistance5,-219.4]} rotation={[0.1,Math.PI,0.01]} fontSize={0.33} maxWidth={4} fillOpacity={10} letterSpacing={-0.06} lineHeight={3.5} color={"#204228"} />
     </>
    ) : sheet.sequence.position > 0.34 ? (
    <> 
    <Text5E scale={[4.8,4.8,4.8]} position={[-3,75 - dropDistance5,-220]} rotation={[0.1,Math.PI,0]} />
    <TextSection title={"Front-end development  Back-end development     Full-stack development"} position={[-1.3,83.5 - dropDistance5,-219.4]} rotation={[0.1,Math.PI,0.01]} fontSize={0.33} maxWidth={4} fillOpacity={10} letterSpacing={-0.06} lineHeight={3.5} color={"#204228"} />
    </>
    ) : null} 

    {isMobileView && sheet.sequence.position > 0.217 ? ( 
    <>
    <Text6E scale={[4.8,4.8,4.8]} position={[2.5,75 - dropDistance6,-180]} rotation={[0.1,Math.PI,0]} />
    <TextSection title={"Types"} position={[3.65,83.38 - dropDistance6,-179.3]} rotation={[0.1,Math.PI,0]} fontSize={0.46}  maxWidth={3.7} fillOpacity={10} color={"#204228"} />
    <TextSection title={"Front-End Development is responsible for how a digital product looks . Most common programming languages :                        -Html -Css -JavaScript"} position={[3.87,81.5 - dropDistance6,-179.5]} rotation={[0.1,Math.PI,0]} fontSize={0.26}  maxWidth={3.3} fillOpacity={6} letterSpacing={-0.06} lineHeight={1} color={"#573724"} />
    <TextSection title={"Back-End Development"} position={[3.89,78.8 - dropDistance6,-179.8]} rotation={[0.1,Math.PI,0]} fontSize={0.3}  maxWidth={3.7} fillOpacity={10} letterSpacing={-0.06} color={"#3c291c"} />
    </>
    ) : sheet.sequence.position > 0.4 ? (
    <>
    <Text6E scale={[4.8,4.8,4.8]} position={[2.5,75 - dropDistance6,-180]} rotation={[0.1,Math.PI,0]} />
    <TextSection title={"Types"} position={[3.65,83.35 - dropDistance6,-179.3]} rotation={[0.1,Math.PI,0]} fontSize={0.46}  maxWidth={3.7} fillOpacity={10} color={"#204228"} />
    <TextSection title={"Front-End Development is responsible for how a digital product looks . Most common programming languages :                        -Html -Css -JavaScript"} position={[3.87,81.5 - dropDistance6,-179.5]} rotation={[0.1,Math.PI,0]} fontSize={0.26}  maxWidth={3.3} fillOpacity={6} letterSpacing={-0.06} lineHeight={1} color={"#573724"} />
    <TextSection title={"Back-End Development"} position={[3.89,78.8 - dropDistance6,-179.8]} rotation={[0.1,Math.PI,0]} fontSize={0.3}  maxWidth={3.7} fillOpacity={10} letterSpacing={-0.06} color={"#3c291c"} />
    </>
    ) : null} 

    {isMobileView && sheet.sequence.position > 0.256 ? ( 
    <>
    <Text7E scale={[4.8,4.8,4.8]} position={[-2.5,75 - dropDistance7,-140]} rotation={[0.1,Math.PI,0]} />
    <TextSection title={"Full-Stack Development"} position={[-0.7,83.25 - dropDistance7,-139.4]} rotation={[0.1,Math.PI,0]} fontSize={0.27} maxWidth={4} fillOpacity={10} letterSpacing={-0.08} color={"#204228"} />
    <TextSection title={"Back-End Development is focused          on how it works                                              Most common programming languages :              - Server-Side : PHP , Java , Node.js                  - Databases : MySQL , MongoDB                   - Server Frameworks : Django , Laravel"} position={[-1.1,81.66 - dropDistance7,-139.8]} rotation={[0.1,Math.PI,0]} fontSize={0.2}  maxWidth={3.3} fillOpacity={6} letterSpacing={-0.06} lineHeight={1.2} color={"#573724"} />
    <TextSection title={"Back-End Development"} position={[-1.2,78.95 - dropDistance7,-140]} rotation={[0.1,Math.PI,0]} fontSize={0.31} maxWidth={3.7} fillOpacity={10} letterSpacing={-0.06} color={"#3c291c"} />
    </>
    ) : sheet.sequence.position > 0.47 ? (
    <>
    <Text7E scale={[4.8,4.8,4.8]} position={[-2.5,75 - dropDistance7,-140]} rotation={[0.1,Math.PI,0]} />
    <TextSection title={"Full-Stack Development"} position={[-0.5,83.2 - dropDistance7,-139.4]} rotation={[0.1,Math.PI,0]} fontSize={0.27} maxWidth={4} fillOpacity={10} letterSpacing={-0.08} color={"#204228"} />
    <TextSection title={"Back-End Development is focused               on how it works                                             Most common programming languages :              - Server-Side : PHP , Java , Node.js                                      - Databases : MySQL , MongoDB                                         - Server Frameworks : Django , Laravel"} position={[-1,81.6 - dropDistance7,-139.8]} rotation={[0.1,Math.PI,0]} fontSize={0.2}  maxWidth={3.5} fillOpacity={6} letterSpacing={-0.06} lineHeight={1.2} color={"#573724"} />
    <TextSection title={"Back-End Development"} position={[-1.1,78.9 - dropDistance7,-140]} rotation={[0.1,Math.PI,0]} fontSize={0.31} maxWidth={3.7} fillOpacity={10} letterSpacing={-0.06} color={"#3c291c"} />
    </>
    ) : null} 

    {isMobileView && sheet.sequence.position > 0.284 ? ( 
    <>
    <Text8E scale={[4.8,4.8,4.8]} position={[2.5,75 - dropDistance8,-100]} rotation={[0.1,Math.PI,0]} />
    <TextSection title={"Back-End Development"} position={[4.3,83.26 - dropDistance8,-99.3]} rotation={[0.1,Math.PI,0]} fontSize={0.29} maxWidth={4} fillOpacity={10} letterSpacing={-0.09} color={"#204228"} />
    <TextSection title={"Full-Stack Development refers to the end-to-end application software development including front & back-end. Is responsible for project architecture deciding on the technology stack and making choices related to the database , web servers      and hosting platforms"} position={[4.07,81.53 - dropDistance8,-99.5]} rotation={[0.1,Math.PI,0]} fontSize={0.18}  maxWidth={3.7} fillOpacity={6} letterSpacing={-0.06} lineHeight={1.2} color={"#573724"} />
    <TextSection title={"Let's start"} position={[3.3,78.85 - dropDistance8,-99.8]} rotation={[0.1,Math.PI,0]} fontSize={0.33} maxWidth={3.7} fillOpacity={10} letterSpacing={-0.06} color={"#3c291c"} />
    </>
    ) : sheet.sequence.position > 0.54 ? ( 
    <>
    <Text8E scale={[4.8,4.8,4.8]} position={[2.5,75 - dropDistance8,-100]} rotation={[0.1,Math.PI,0]} />
    <TextSection title={"Back-End Development"} position={[4.55,83.25 - dropDistance8,-99.3]} rotation={[0.1,Math.PI,0]} fontSize={0.29} maxWidth={4} fillOpacity={10} letterSpacing={-0.09} color={"#204228"} />
    <TextSection title={"Full-Stack Development refers to          the end-to-end application software development including front & back-end                       Is responsible for project architecture deciding on the technology stack and making choices related to the database , web servers and hosting platforms"} position={[3.9,81.6 - dropDistance8,-99.5]} rotation={[0.1,Math.PI,0]} fontSize={0.18}  maxWidth={3} fillOpacity={6} letterSpacing={-0.06} lineHeight={1.2} color={"#573724"} />
    <TextSection title={"Let's start"} position={[3.45,78.8 - dropDistance8,-99.8]} rotation={[0.1,Math.PI,0]} fontSize={0.33} maxWidth={3.7} fillOpacity={10} letterSpacing={-0.06} color={"#3c291c"} />
    </>
    ) : null} 

    {isMobileView && sheet.sequence.position > 0.318 ? ( 
    <Float floatIntensity={2} speed={4} rotationIntensity={0.05}> 
    <TextSection title={"The Tale of Web Development Crafting the Digital Kingdom"} fontSize={0.73} maxWidth={11} fillOpacity={10} letterSpacing={-0.06} color={"#3c291c"} position={[5,81,-50]} rotation={[0.1,Math.PI,0]}/>
    </Float>
    ) : sheet.sequence.position > 0.6 ? ( 
    <Float floatIntensity={2} speed={4} rotationIntensity={0.05}> 
    <TextSection title={"The Tale of Web Development    Crafting the Digital Kingdom"} fontSize={0.73} maxWidth={12} fillOpacity={10} letterSpacing={-0.06} color={"#3c291c"} position={[4.1,81,-50]} rotation={[0.1,Math.PI,0]}/>
    </Float>
    ) : null} 

    <Header position-x={-0.5} position-y={80.7}  position-z={21} rotation-y={Math.PI/2.5} rotation-x={0.1}/>
    
    <TextSection title={"In the"} fontSize={0.85} maxWidth={12} fillOpacity={20} letterSpacing={-0.06} color={"#23160e"} position={[1,77.7,21.5]} rotation={[0.1,Math.PI,0]}/>
    <TextSection title={"vast kingdom of Technology"} fontSize={0.75} maxWidth={12} fillOpacity={20} letterSpacing={-0.06} color={"#23160e"} position={[4,76.1,19.94]} rotation={[0.1,Math.PI,0]}/>
    <TextSection title={"a talented guild of web developers"} fontSize={0.72} maxWidth={15} fillOpacity={20} letterSpacing={-0.095} color={"#23160e"} position={[4.7,74.6,17.5]} rotation={[0.1,Math.PI,0]}/>
    <TextSection title={"were the architects and craftsmen responsible"} fontSize={0.68} maxWidth={18} fillOpacity={20} letterSpacing={-0.095} color={"#23160e"} position={isMobileView ? [6.5,73.2,14.63] : [6.5,73.2,15.56]} rotation={[0.1,Math.PI,0.018]}/>
    <TextSection title={"for shaping the digital landscapes of the internet"} fontSize={0.59} maxWidth={18} fillOpacity={20} letterSpacing={-0.095} color={"#23160e"}   position={isMobileView ? [6, 71.5, 13.56] : [6, 71.65, 13.51]} rotation={ isMobileView ? [0.1,Math.PI,0.05] : [0.1,Math.PI,0.09]}/>
   
    <Text31WebCastle  position={isMobileView ? [-15, 99, -10.7] : [-14.552, 99.212, -13.776]} rotation={[0.324, 0.738, 2.748]} scale={isMobileView ? 0.83 : 1.059}/>

    <Text34WebCastle position={isMobileView ? [-13.034, 99.405, -10.535] : [-13.464, 99.405, -10.835]} rotation={isMobileView ? [0.6, 0.72, 2.53] : [0.582, 0.75, 2.53]} scale={isMobileView ? 0.95 : 1.059}/>

    <Text33WebCastle position={isMobileView ? [-10.575, 98.8, -7.5] : [-10.575, 98.8, -8.9]} rotation={isMobileView ? [0.497, 0.687, 2.603] : [0.497, 0.687, 2.603]} scale={isMobileView ? 0.9 : 1.059}/>

    <Text32WebCastle position={isMobileView ? [-8, 98.5, -6.858] : [-8, 98.5, -6.858]} rotation={isMobileView ? [0.463, 0.861, 2.65] : [0.463, 0.821, 2.623]} scale={isMobileView ? 1 : 1.059}/>
    
    <TextSection2 
    title={"Within their arsenal developers discovered libraries and frameworks like jQuery React and Angular   These tools akin to expert craftsmen specialized in different facets of   web development accelerating the construction process"} 
    fontSize={0.16} maxWidth={3} fillOpacity={20} letterSpacing={0.04} color={"#4c4c4c"} lineHeight={1}
    position={[3.28, 113.916, 27.274]} 
    rotation={[3.15, -0.011, -3.134]}/>
    <TextSection2 
    title={"Databases including SQL and NoSQL functioned as vast repositories storing data and supporting dynamic content like user profiles and product listings"} 
    fontSize={0.15} 
    maxWidth={2.9} fillOpacity={20} letterSpacing={0.04} color={"#4c4c4c"} lineHeight={1}
    position={[-27.2, 113.6, 2.1]} 
    rotation={[-Math.PI / 2.3, 1.55, 1.37]}/>
    <TextSection2
    title={"The security of the digital realm was paramount just as knights safeguarded the gates of a castle Developers employed technologies such as SSL and HTTPS to ensure the protection of sensitive data and to facilitate secure communication between users and their websites"} 
    fontSize={0.15} maxWidth={2.8} fillOpacity={20} letterSpacing={0.04} color={"#4c4c4c"} lineHeight={1}
    position={[-3.3, 114.4, -26.461]} 
    rotation={[-0.01, 0, -0.001]}/>
    <TextSection title={"The importance of responsive design was not lost on these artisans"} fontSize={isMobileView ? 0.3 : 0.46} maxWidth={isMobileView ? 3.3 : 5} fillOpacity={20} letterSpacing={-0.04} color={"#23160e"} position={isMobileView ? [-15.8,148.9,18] : [-17.5,148.7,17.9]} rotation={[-0.10,1.01,0.05]}/>
    <TextSection title={"They ensured that their creations"} fontSize={0.5} maxWidth={4.5} fillOpacity={20} letterSpacing={-0.04} color={"#23160e"} position={[-24.84,147.6,1.9]} rotation={[0.05,0,0.02]}/>
    <TextSection title={"looked and functioned flawlessly on all devices"} fontSize={0.265} maxWidth={3} fillOpacity={20} letterSpacing={-0.04} color={"#23160e"} position={ isMobileView ? [-19.18,147.60,-17.2] : [-19.18,147.7,-17.2]} rotation={[0,-1,0]}/>
    <TextSection title={"be in the mighty desktop computers or compact smartphones"} fontSize={0.29} maxWidth={4.5} fillOpacity={20} letterSpacing={-0.04} color={"#23160e"} position={isMobileView ? [1.7,147.75,-25.79] : [1.2,147.75,-25.79]} rotation={[0,-1.8,0.01]}/>
    <TextSection title={"Media queries and flexible layouts allowed them to adapt seamlessly to various screen sizes"} fontSize={0.3} maxWidth={3.8} fillOpacity={20} letterSpacing={-0.09} color={"#23160e"} position={[24.8,149.17,-3.5]} rotation={[3.1,0.04,3.16]}/>
    <TextSection title={"Collaboration among fellow developers was the hallmark of success                                 Version control systems , epitomized by Git documented  every change made to their projects enabling seamless teamwork"} 
    fontSize={0.25} maxWidth={4.2} fillOpacity={20} letterSpacing={-0.07} color={"#083f6f"} position={[25,196.5,-12.5]} rotation={[0.5,-1.5,0.5]}/>
    <TextSection title={"APIs acted as bridges to external services allowing developers to fetch data from distant realms and enhance their creations"} 
    fontSize={0.3} maxWidth={5.2} fillOpacity={20} letterSpacing={-0.07} color={"#083f6f"} position={[-22.7,196.5,-4.2]} rotation={isMobileView ? [2.7, 1.85, -2.75] : [2.7, 1.6, -2.71]}/>
    <TextSection title={"Web development was a never-ending expedition, a dontinuous journey of knowledge and creativity                 Each line of code, design choice and user interaction contributed to shaping the digital landscape                             In this vast digital universe , web developers left                      an indelible leaving their legacy in sands of time"} fontSize={0.31} maxWidth={7.3} fillOpacity={20} letterSpacing={-0.07} lineHeight={1.1} color={"#76b6ec"} position={[-45.30,212,9.3]} rotation={[1.56,1.5,-1.56]}/>
  
    
    {isMobileView && sheet.sequence.position > 1.44 && !isNavOpen ? ( 
    <>
     <TextSection title={"Explore other scenes"} fontSize={0.12} maxWidth={7} fillOpacity={20} letterSpacing={-0.07} lineHeight={1.1} color={"#76b6ec"} position={[-60,211.9,6.8]} rotation={[1.56,1.5,-1.56]}/>

      <Html position={[-62.8, 213, 6.35]} rotation={[1.56, 1.5, -1.565]} transform>
      <a className="annotation" href='/javaLab'> Java Lab </a>
      </Html>
          
      <Html position={[-62.5, 211.4, 8]} rotation={[1.56, 1.5, -1.565]} transform>
      <a className="annotation" href='/javaTeam'> Team </a>
      </Html>
          
      <Html position={[-62.5, 211.4, 4.95]} rotation={[1.56, 1.5, -1.565]} transform>
      <a className="annotation" href='/webEssentials'> Web Essentials </a>
      </Html>

    

    </>
    ) : sheet.sequence.position > 2.48  && !isNavOpen ? ( 
    <>
      <TextSection title={"Explore other scenes"} fontSize={0.28} maxWidth={7} fillOpacity={20} letterSpacing={-0.07} lineHeight={1.1} color={"#76b6ec"} position={[-60,211.7,7.65]} rotation={[1.56,1.5,-1.56]}/>
      
      <Html position={[-65, 214.2, 6.5]} rotation={[1.56, 1.5, -1.565]} transform>
      <a className="annotation" href='/javaLab'> Java Lab </a>
      </Html>

      <Html position={[-65, 211.5, 11]} rotation={[1.56, 1.5, -1.565]} transform>
      <a className="annotation" href='/javaTeam'> Team </a>
      </Html>
  
      <Html position={[-65, 211.5, 2]} rotation={[1.56, 1.5, -1.565]} transform>
      <a className="annotation" href='/webEssentials'> Web Essentials </a>
      </Html>
    </>  
    ) : null} 

    </>
  );
}

export default WebDevelopmentScene;