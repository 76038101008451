
import React, { useContext, useState } from 'react';
import "./SideBarMobileView.css";
import { NavContext } from '../NavContext';


export default function SideBarMobileView() {
  const { isNavOpen, setIsNavOpen } = useContext(NavContext);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  
  
    return (
      <div>
      {isNavOpen ? (
 

        <div className={`sidenav-mobile ${isNavOpen ? 'open' : 'close'}`}>

            <ul className="navbar-list">
            <li><a href="/"> Home</a> </li>
            <li><h5> Salesforce</h5> <br></br> <a href="/salesforceHub"> Portal </a> <br></br> <a href="/salesforceHub"> Hub </a></li>
            <li><h5> Java </h5> <br></br> <a href="/javaLab"> Lab </a> <br></br> <a href="/javaCentral"> Central </a></li>
            <li><h5> Web </h5> <br></br> <a href="/webCastle"> Castle </a> <br></br> <a href="/webEssentials"> Essentials </a></li>
            <li> <h5> Teams </h5> <br></br> <a href="/javaTeam"> Java </a> <br></br><a href="/salesforceTeam"> Salesforce </a> <br></br><a href="/phpTeam"> Php </a></li>
            <li><a href="/contact-us"> Contact Us </a></li>
            </ul>

        </div>
      ) : null}

      <div className={`icon-menu-mob ${isNavOpen ? 'open' : 'close'}`}>
        <div className="svg-container">
          <svg
            id="svg"
            className="svg-mobile"
            width="70px"
            height="591px"
            viewBox="0 0 60 591"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M38.2983 218.272C38.2983 195.16 65 218.272 65 218.272V408.396C65 408.396 38.2983 432.255 38.2983 408.396C38.2983 384.537 2.90014 361.849 0.3819 322.654C-0.121184 314.824 -0.133379 310.352 0.3819 302.523C2.92879 263.829 38.2983 241.385 38.2983 218.272Z"
              fill="#80ade9"
            />
          </svg>
          <input
            className="label-check"
            id="label-check"
            type="checkbox"
            onClick={toggleNav}
          />
          <label htmlFor="label-check" className={`hamburger-label ${isNavOpen ? 'open' : 'close'}`}>
            <div className="line1"></div>
            <div className="line2"></div>
            <div className="line3"></div>
          </label>
        </div>
      </div>
    </div>
    
    );
}
