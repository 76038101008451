
import React, { useContext } from 'react';
import "./SideBarDesktopView.css";
import { NavContext } from '../NavContext';

export default function SideBarDesktopView() {
  const { isNavOpen, setIsNavOpen } = useContext(NavContext);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };


  return (
    <div>
      <nav className={`side-nav ${isNavOpen ? 'open' : 'closed'}`} >

      <ul className="navbar-list-desktop">
      <li><a href="/"> Home</a> </li>
      <li><a href="/salesforceHub"> Salesforce Hub </a></li>
      <li className='d-flex gap-5'><a href="/javaLab"> Java Lab </a> <a href="/javaCentral"> Java Central </a></li>
      <li className='d-flex gap-5'><a href="/webCastle"> Web Castle </a> <a href="/webEssentials"> Web Essentials </a></li>
      <li className='d-flex gap-5'><a href="/javaTeam"> Java Team </a><a href="/salesforceTeam"> Salesforce Team </a><a href="/phpTeam"> Php Team </a></li>
      <li><a href="/contact-us"> Contact Us </a></li>
      </ul> 
      
<div className="spinneri">
              <div className="spinneri__image" />
            </div>
      </nav>
      <div className="burger-menu-class">
        <svg
          onClick={toggleNav}
          height="37px"
          id="burger-menu"
          style={{ enableBackground: 'new 0 0 32 32' }}
          version="1.1"
          viewBox="0 -5 32 32"
          width="37px"
          xmlSpace="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <path
            d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,22,28,22z"
            fill="#69d4ff"
            />
        </svg>
      </div>
    </div>
  );

}
