import React, { Component, lazy, useContext, useMemo } from 'react';
import "./JavaScene.css";
import { getProject, val } from '@theatre/core';
import { SheetProvider, PerspectiveCamera, useCurrentSheet } from '@theatre/r3f';
import { useEffect, useState } from "react";
import { Sparkles, Shadow, Float, Html } from '@react-three/drei';
import JavaStateDesktop from "../animationScenes/JavaSceneDesktop.json"
import JavaStateMobile from "../animationScenes/JavaSceneMobile.json"
import { Canvas, useFrame } from '@react-three/fiber';
import TextSection from '../components/javaScene/TextSection';
import { NavContext } from '../NavContext';


const JavaSceneObject = lazy(() => import("../components/javaScene/JavaScene"));
const SpringLogo3D = lazy(() => import("../components/javaScene/SpringLogo3D"));
const Glow = lazy(() => import("../components/javaScene/Glow"));
const GrailsLogo3D = lazy(() => import("../components/javaScene/GrailsLogo3D"));
const SpringImage = lazy(() => import("../components/javaScene/SpringImage"));
const GrailsImage = lazy(() => import("../components/javaScene/GrailsImage"));
const LogoBigMR = lazy(() => import("../components/javaScene/LogoBigMR"));
const LogoBigML = lazy(() => import("../components/javaScene/LogoBigML"));
const LogoSmallMR = lazy(() => import("../components/javaScene/LogoSmallMR"));
const LogoSmallML = lazy(() => import("../components/javaScene/LogoSmallML"));

class JavaScene extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileView: false
    };
  }

  componentDidMount() {
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkScreenSize);
  }

  checkScreenSize = () => {
    this.setState({ isMobileView: window.innerWidth <= 500 });
  }

  render() {
    const { previousScrollPosition } = this.props;
    const { isMobileView } = this.state;

    const sheetDesktop = getProject('JavaSceneDesktop', { state: JavaStateDesktop }).sheet('Scene');
    const sheetMobile = getProject('JavaSceneMobile', { state: JavaStateMobile }).sheet('Scene');

    return (
      <Canvas gl={{ preserveDrawingBuffer: true }}>
        <SheetProvider sheet={isMobileView ? sheetMobile : sheetDesktop}>
          <Scene previousScrollPosition={previousScrollPosition} />
        </SheetProvider>
      </Canvas>
    );
  }
}

function Scene({previousScrollPosition}) {

  const { isNavOpen } = useContext(NavContext);

  const [isMobileView, setIsMobileView] = useState(false);
  const [currentIndex1, setCurrentIndex1] = useState(null);
  const [currentIndex2, setCurrentIndex2] = useState(null);
  const [showFirst, setShowFirst] = useState(false);
  const [showSecond, setShowSecond] = useState(false);
  const [skipAnimation, setSkipAnimation] = useState(false);


  useEffect(() => {
    function checkScreenSize() {
      setIsMobileView(window.innerWidth <= 500); 
    }
  
    checkScreenSize(); 
  
    window.addEventListener('resize', checkScreenSize);
  
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);


  const sheet = useCurrentSheet();

  const textSections1 = useMemo(() => [

    { 
    title: "calm-coders@ root: ", 
    command: isMobileView ? "                   mvn -f /calmcoders/services/pom.xml clean install -DskipTests=true -Pproduction " :  "                   mvn -f /calmcoders/services/pom.xml clean install -DskipTests=true -Pproduction ",
    commandColor:"#fff", 
    position: isMobileView ? [-5.3, 9.67, -2.8] : [-5, 8, 0], 
    rotation: [0.0001, 0.001,  0.001], 
    scale: isMobileView ? 0.42 : 0.25 ,
    color:"#4AF626",
    },

    { 
    info:"[INFO]",
    infoColor:"#00d5ff",
    title: isMobileView ? "       ----------------------< org.apache.maven: standalone-pom >-------------------" : "        ----------------------------------------------< org.apache.maven: standalone-pom >----------------------------------------------", 
    position: isMobileView ?  [-5.4, 9.48, -2.94] : [-5, 7.85, 0],
    rotation: [0.0001, 0.001,  0.001], 
    scale: isMobileView ? 0.48 : 0.25 ,
    color:"#fff"
    },

    { 
    info:"[INFO]",
    infoColor:"#00d5ff",
    title: "        Building Maven Stub Project (No POM)", 
    position: isMobileView ? [-5.4, 9.23, -2.95] : [-5, 7.735, 0],
    rotation: [0.0001, 0.001,  0.001], 
    scale: isMobileView ? 0.48 : 0.25 ,
    color:"#fff"
    },

    { 
    info:"[INFO]",
    infoColor:"#00d5ff",
    title: isMobileView ? "        ----------------------------------< pom >-----------------------------------" : "        ---------------------------------------------------------------< pom >----------------------------------------------------------", 
    position: isMobileView ? [-5.38, 8.98, -2.92] : [-5, 7.62, 0],
    rotation: [0.0001, 0.001,  0.001], 
    scale: isMobileView ? 0.48 : 0.25 ,
    color:"#fff"
    },

    { 
    info:"[INFO] (release)",
    infoColor:"#00d5ff",
    title: "                 Services description", 
    percentage:isMobileView ? "                                                                  ---- 0% [LOADING]" : "                                                                                                                                  ---- 0% [LOADING]",
    percentageColor:"#00fa9a",
    position: isMobileView ? [-5.38, 8.73, -2.92] : [-5, 7.42, 0],
    rotation: [0.0001, 0.001,  0.001], 
    scale: isMobileView ? 0.5 : 0.25 ,
    color:"#fff"
    },
   
    {
    info:"[INFO] (release)",
    infoColor:"#00d5ff",
    title: "                 Advanced and Modern Backend Solutions", 
    percentage: isMobileView ?  "                                                                  ---- 16%" : "                                                                                                                                  ---- 16%",
    percentageColor:"#00fa9a",
    position: isMobileView ? [-5.38, 8.47, -2.92] : [-5, 7.25, 0], 
    rotation: [0.01, 0.001, 0.001],
    scale: isMobileView ? 0.5 : 0.25 ,
    color:"#fff"
    },
  
    { 
    title: "[NOTICE]", 
    percentage:"         We specialize in crafting robust and scalable backends, leveraging Java and a broad spectrum of advanced technologies",
    percentageWidth: isMobileView ? 20 : 50,
    percentagePosition: isMobileView ? [0.2,-0.6,0] : [0.2,-0.1,0],
    percentageColor:"#fff",
    position: isMobileView ? [-5.38, 8.18, -2.92] : [-5, 7.05, 0],
    rotation: [0.01, 0.001,  0.001], 
    scale: isMobileView ? 0.5 : 0.25 ,
    color:"#00fa9a"
    },

    { 
    title: "[NOTICE]", 
    percentage: "         This enables us to handle intricate tasks and ensure high performance",
    percentagePosition: [0,-0.1,0],
    percentageColor:"#fff",
    position: isMobileView ? [-5.38, 7.66, -2.92] : [-5, 6.9, 0], 
    rotation: [0.01, 0.001,  0.001],
    scale: isMobileView ? 0.48 : 0.25 ,
    color:"#00fa9a"
    },

    { 
    info:"[INFO] (release)",
    infoColor:"#00d5ff",
    title: "                 Relevant and Intuitive Frontend Design", 
    percentage: isMobileView ? "                                                                   ---- 32%" : "                                                                                                                                  ---- 32%",
    percentageColor:"#00fa9a",
    position: isMobileView ? [-5.38, 7.38, -2.92] : [-5, 6.68, 0], 
    rotation: [0.0001, 0.001,  0.001], 
    scale: isMobileView ? 0.48 : 0.25 ,
    color:"#fff" 
    },

    { 
    title: "[NOTICE]", 
    percentage:"         Our focus is on designing engaging and functional user interfaces, utilizing Angular and other contemporary frameworks",
    percentageColor:"#fff",
    percentageWidth: isMobileView ? 20 : 50,
    percentagePosition: isMobileView ? [0.1,-0.6,0] : [0.1,-0.1,0],
    position: isMobileView ? [-5.38, 7.08, -2.92] : [-5, 6.5, 0], 
    rotation: [0.01, 0.001,  0.001], 
    scale: isMobileView ? 0.48 : 0.25 ,
    color:"#00fa9a"
    },

    { 
    title: "[NOTICE]", 
    percentage:"         Our aim is to create smooth and immersive user experiences",
    percentageColor:"#fff",
    position: isMobileView ? [-5.38, 6.53, -2.92] : [-5, 6.35, 0], 
    rotation: [0.0001, 0.001,  0.001], 
    scale: isMobileView ? 0.48 : 0.25 ,
    color:"#00fa9a"
    },

    {
    info:"[INFO] (release)",
    infoColor:"#00d5ff", 
    title: "                 Simple Web Application Solutions",
    percentage: isMobileView ? "                                                                   ---- 48%" : "                                                                                                                                  ---- 48%",
    percentageColor:"#00fa9a",
    position: isMobileView ? [-5.38, 6.28, -2.92] : [-5, 6.1, 0],
    rotation: [0.0001, 0.001,  0.001], 
    scale: isMobileView ? 0.48 : 0.25 ,
    color:"#fff" 
    },

    {
    title: "[NOTICE] ", 
    percentage:"         We offer easy-to-use web application solutions, ideal for small to medium-sized businesses seeking an effective and manageable online presence",
    percentageColor:"#fff",
    percentageWidth: isMobileView ? 20 : 50,
    percentagePosition: isMobileView ? [0,-0.65,0] : [0,-0.1,0],
    position: isMobileView ? [-5.38, 6, -2.92] : [-5, 5.92, 0], 
    rotation: [0.01, 0.001,  0.001], 
    scale: isMobileView ? 0.48 : 0.25 ,
    color:"#00fa9a" 
    },
   
    {
    info:"[INFO] (release)",
    infoColor:"#00d5ff",  
    title: "                 Complex Architectures for Large Systems", 
    percentage: isMobileView ?  "                                                                   ---- 64%" : "                                                                                                                                  ---- 64%",
    percentageColor:"#00fa9a",
    position: isMobileView ? [-5.38, 5.43, -2.92] : [-5, 5.65, 0], 
    rotation: [0.0001, 0.001,  0.001], 
    scale: isMobileView ? 0.48 : 0.25 ,
    color:"#fff" 
    },

    { 
    title: "[NOTICE]", 
    percentage:"         For clients requiring more sophisticated systems, we design intricate architectures that support high data volumes",
    percentageColor:"#fff",
    percentageWidth: isMobileView ? 21 : 50,
    percentagePosition: isMobileView ? [0,-0.7,0] : [0,-0.1,0],
    position: isMobileView ? [-5.38, 5.12, -2.92] : [-5, 5.45, 0], 
    rotation: [0.0001, 0.001,  0.001], 
    scale: isMobileView ? 0.48 : 0.25 ,
    color:"#00fa9a" 
    },

    { 
    title: "[NOTICE] ", 
    percentage:"         and traffic while maintaining efficiency and security",
    percentageColor:"#fff",
    percentagePosition: isMobileView ? [0,0,0] : [0,-0.1,0],
    position: isMobileView ? [-5.38, 4.51, -2.92] : [-5, 5.2, 0], 
    rotation: [0.0001, 0.001,  0.001], 
    scale: isMobileView ? 0.48 : 0.25 ,
    color:"#00fa9a"
    },

    {
    info:"[INFO] (release)",
    infoColor:"#00d5ff",  
    title: "                 Employing Cutting-Edge Technologies", 
    percentage: isMobileView ? "                                                                   ---- 80%" : "                                                                                                                                  ---- 80%",
    percentageColor:"#00fa9a",
    position:  isMobileView ? [-5.38, 4.26, -2.92] : [-5, 5, 0], 
    rotation: [0.0001, 0.001,  0.001], 
    scale: isMobileView ? 0.48 : 0.25 ,
    color:"#fff" 
    },

    { 
    title: "[NOTICE]", 
    percentage:"         We stay at the forefront of technological trends. Our use of Java combined with the latest technologies ensures solutions",
    percentageColor:"#fff",
    percentageWidth: isMobileView ? 20 : 50,
    percentagePosition: isMobileView ? [0,-0.6,0]: [0,-0.1,0],
    position:  isMobileView ? [-5.38, 3.93, -2.92] : [-5, 4.8, 0], 
    rotation: [0.0001, 0.001,  0.001],
    scale: isMobileView ? 0.48 : 0.25 ,
    color:"#00fa9a"
    },

    { 
    title: "[NOTICE]", 
    percentage:"         that meet the current market demands",
    percentageColor:"#fff",
    position: isMobileView ? [-5.38, 3.38, -2.92] : [-5, 4.65, 0], 
    rotation: [0.0001, 0.001,  0.001], 
    scale: isMobileView ? 0.48 : 0.25 ,
    color:"#00fa9a"
    },

    { 
    info:"[INFO] (release)",
    infoColor:"#00d5ff", 
    title: "                 Executing Complex Tasks", 
    percentage: isMobileView ? "                                                                   ---- 100% [SUCCESS]" : "                                                                                                                                  ---- 100% [SUCCESS]",
    percentageColor:"#00fa9a",
    position: isMobileView ? [-5.38, 3.13, -2.92] : [-5, 4.4, 0], 
    rotation: [0.0001, 0.001,  0.001], 
    scale: isMobileView ? 0.48 : 0.25 ,
    color:"#fff" 
    },

    { 
    title: "[NOTICE]", 
    percentage:"         Our expertise allows us to tackle and resolve complex tasks, providing reliable and high-performance solutions for every business need",
    percentageColor:"#fff",
    percentageWidth: isMobileView ? 19 : 50,
    percentagePosition: isMobileView ? [-0.1,-0.65,0] : [-0.1,-0.1,0],
    position: isMobileView ? [-5.38, 2.87, -2.92] : [-5, 4.2, 0], 
    rotation: [0.0001, 0.001,  0.001], 
    scale: isMobileView ? 0.48 : 0.25 ,
    color:"#00fa9a"
    },

    { 
      info:"[INFO]",
      infoColor:"#00d5ff",
      title: isMobileView ? "       ----------------------------------------------------------------------------" : "       ---------------------------------------------------------------------------------------------------------------------------------", 
      position:  isMobileView ? [-5.38, 2.39, -2.92] : [-5, 4.01, 0],
      rotation: [0.0001, 0.001,  0.001], 
      scale: isMobileView ? 0.48 : 0.25 ,
      color:"#fff"
    },
  
    { 
      info:"[INFO]",
      infoColor:"#00d5ff",
      title: "        BUILD SUCCES", 
      position: isMobileView ? [-5.38, 2.2, -2.92] : [-5, 3.87, 0],
      rotation: [0.0001, 0.001,  0.001], 
      scale: isMobileView ? 0.48 : 0.25 ,
      color:"#00fa9a"
    },

    { 
      info:"[INFO]",
      infoColor:"#00d5ff",
      title: isMobileView ? "       ----------------------------------------------------------------------------" : "       ---------------------------------------------------------------------------------------------------------------------------------", 
      position: isMobileView ? [-5.38, 1.98, -2.92] : [-5, 3.73, 0],
      rotation: [0.0001, 0.001,  0.001], 
      scale: isMobileView ? 0.48 : 0.25 ,
      color:"#fff"
    },

    { 
      info:"[INFO]",
      infoColor:"#00d5ff",
      title: isMobileView ? "       ----------------------------------------------------------------------------" : "       ---------------------------------------------------------------------------------------------------------------------------------", 
      position: isMobileView ? [-5.38, 1.98, -2.92] : [-5, 3.73, 0],
      rotation: [0.0001, 0.001,  0.001], 
      scale: isMobileView ? 0.48 : 0.25 ,
      color:"#fff"
    },

    ], [isMobileView]);

  const textSections2 = useMemo(() => [

    { 
      title: "calm-coders@ root: ", 
      command:"                   mvn -f /calmcoders/services/pom.xml clean install -DskipTests=true -Pproduction ",
      commandColor:"#fff", 
      position:  isMobileView ? [-5.2, 9.45, -2.5] : [-5, 8, 0], 
      rotation: [0.0001, 0.001,  0.001], 
      scale: isMobileView ? 0.41 : 0.25 ,
      color:"#4AF626"
      },

    { 
     title: "We are focusing on web development in the context of Java and Angular technologies:",
     percentage: isMobileView ? "                                                                                    ---- 0% [LOADING]" : "                                                                                                                         ---- 0% [LOADING]", 
     percentageColor:"#00fa9a",
     position: isMobileView ? [-5.05, 9.25, -2.5] : [-5, 7.8, 0], 
     rotation: [0.0001, 0.02,  0.001], 
     scale: isMobileView ? 0.4 : 0.25 ,
     color:"#00d5ff"
    },

    { 
    info:"[INFO] (release)",
    infoColor:"#00d5ff",
    title: "                 Custom Enterprise Web Applications", 
    percentage: isMobileView ? "                                                                       ---- 10%" : "                                                                                                                         ---- 10%", 
    percentageColor:"#00fa9a",
    position: isMobileView ? [-5.1, 9.05, -2.5] : [-5, 7.55, 0], 
    rotation: [0.0001, 0.02,  0.001], 
    scale: isMobileView ? 0.38 : 0.25 , 
    color:"#fff" 
    },

    { 
    title: "[NOTICE]", 
    percentage:"         Tailored solutions for business processes, including ERP systems, CRM platforms, and supply chain management tools", 
    percentageColor:"#fff",
    percentageWidth: isMobileView ? 23 : 50,
    percentagePosition: isMobileView ? [0,-0.7,0] : [0,-0.1,0],
    position: isMobileView ? [-5.1, 8.85, -2.5] : [-5, 7.4, 0],
    rotation: [0.01, 0.02,  0.001], 
    scale: isMobileView ? 0.38 : 0.25  ,
    color:"#00fa9a"
    },


    { 
    info:"[INFO] (release)",
    infoColor:"#00d5ff", 
    title: "                 Interactive User Interfaces",
    percentage: isMobileView ? "                                                                       ---- 20%" : "                                                                                                                         ---- 20%", 
    percentageColor:"#00fa9a",
    position: isMobileView ? [-5.1, 8.4, -2.5] : [-5, 7.15, 0], 
    rotation: [0.01, 0.02,  0.001],
    scale: isMobileView ? 0.38 : 0.25  , 
    color:"#fff"
    },

    { 
    title: "[NOTICE]", 
    percentage:"         Engaging and responsive front-end designs using Angular, offering intuitive navigation and dynamic content presentation", 
    percentageColor:"#fff",
    percentageWidth: isMobileView ? 20 : 50,
    percentagePosition: isMobileView ? [0,-0.7,0] : [0,-0.1,0],
    position: isMobileView ? [-5.1, 8.15, -2.5] : [-5, 7, 0], 
    rotation: [0.0001, 0.02,  0.001], 
    scale: isMobileView ? 0.38 : 0.25  ,
    color:"#00fa9a"
    },
  
    {
    info:"[INFO] (release)",
    infoColor:"#00d5ff", 
    title: "                 Data Management Systems",
    percentage: isMobileView ? "                                                                       ---- 30%" : "                                                                                                                         ---- 30%", 
    percentageColor:"#00fa9a",
    position: isMobileView ? [-5.1, 7.65, -2.5] : [-5, 6.75, 0], 
    rotation: [0.01, 0.02,  0.001], 
    scale: isMobileView ? 0.38 : 0.25  , 
    color:"#fff"
    },

    { 
    title: "[NOTICE]", 
    percentage:"         Solutions for handling large datasets, including data warehousing, analytics platforms, and real-time data processing", 
    percentageColor:"#fff",
    percentageWidth: isMobileView ? 20 : 50,
    percentagePosition: isMobileView ? [0,-0.7,0] : [0,-0.1,0],
    position: isMobileView ? [-5.1, 7.37, -2.5] : [-5, 6.6, 0], 
    rotation: [0.0001, 0.02,  0.001], 
    scale: isMobileView ? 0.38 : 0.25  ,
    color:"#00fa9a"
    },

    {
    info:"[INFO] (release)",
    infoColor:"#00d5ff", 
    title: "                 API Development and Integration", 
    percentage:isMobileView ? "                                                                       ---- 40%" : "                                                                                                                         ---- 40%", 
    percentageColor:"#00fa9a",
    position: isMobileView ? [-5.1, 6.9, -2.5] : [-5, 6.35, 0], 
    rotation: [0.0001, 0.02,  0.001], 
    scale: isMobileView ? 0.38 : 0.25  , 
    color:"#fff"
    },

    { 
    title: "[NOTICE]", 
    percentage:"         Designing and implementing RESTful APIs for seamless integration with other services and third-party applications", 
    percentageColor:"#fff",
    percentageWidth: isMobileView ? 20 : 50,
    percentagePosition: isMobileView ? [0,-0.7,0] : [0,-0.1,0],
    position: isMobileView ? [-5.1, 6.65, -2.5] : [-5, 6.2, 0], 
    rotation: [0.01, 0.02,  0.001], 
    scale: isMobileView ? 0.38 : 0.25  ,
    color:"#00fa9a"
    },

    { 
    info:"[INFO] (release)",
    infoColor:"#00d5ff",  
    title: "                 Cloud-Based Solutions", 
    percentage:isMobileView ? "                                                                       ---- 50%" : "                                                                                                                         ---- 50%", 
    percentageColor:"#00fa9a",
    position: isMobileView ? [-5.1, 6.2, -2.5] : [-5, 5.95, 0], 
    rotation: [0.0001, 0.02,  0.001], 
    scale: isMobileView ? 0.38 : 0.25  , 
    color:"#fff"},

    { 
    title: "[NOTICE]",
    percentage:"         Leveraging cloud technologies for scalable and flexible web applications, including AWS, Azure, DigitalOcean", 
    percentageColor:"#fff",
    percentageWidth: isMobileView ? 20 : 50,
    percentagePosition: isMobileView ? [0,-0.7,0] : [0,-0.1,0],
    position: isMobileView ? [-5.1, 5.95, -2.5] : [-5, 5.8, 0], 
    rotation: [0.0001, 0.02,  0.001], 
    scale: isMobileView ? 0.38 : 0.25  ,
    color:"#00fa9a"
   },

    {
    info:"[INFO] (release)",
    infoColor:"#00d5ff", 
    title: "                 Mobile-Responsive Web Applications", 
    percentage:isMobileView ? "                                                                       ---- 60%" : "                                                                                                                         ---- 60%", 
    percentageColor:"#00fa9a",
    position: isMobileView ? [-5.1, 5.5, -2.5] : [-5, 5.55, 0], 
    rotation: [0.0001, 0.02,  0.001], 
    scale: isMobileView ? 0.38 : 0.25  , 
    color:"#fff"
    },

    { 
    title: "[NOTICE]", 
    percentage:"         Creating web applications optimized for mobile devices, ensuring a seamless user experience across all platforms", 
    percentageColor:"#fff",
    percentageWidth: isMobileView ? 20 : 50,
    percentagePosition: isMobileView ? [0,-0.7,0] : [0,-0.1,0],
    position: isMobileView ? [-5.1, 5.25, -2.5] : [-5, 5.4, 0], 
    rotation: [0.0001, 0.02,  0.001], 
    scale: isMobileView ? 0.38 : 0.25  ,
    color:"#00fa9a"
    },
   
    { 
    info:"[INFO] (release)",
    infoColor:"#00d5ff",
    title: "                 Content Management Systems (CMS)", 
    percentage:isMobileView ? "                                                                       ---- 70%" : "                                                                                                                         ---- 70%", 
    percentageColor:"#00fa9a",
    position: isMobileView ? [-5.1, 4.8, -2.5] : [-5, 5.1, 0], 
    rotation: [0.0001, 0.02,  0.001], 
    scale: isMobileView ? 0.38 : 0.25  , 
    color:"#fff"},
    
    { 
    title: "[NOTICE]", 
    percentage:"         User-friendly platforms for content creation, management and publishing, tailored to the client's specific needs", 
    percentageColor:"#fff",
    percentageWidth: isMobileView ? 20 : 50,
    percentagePosition: isMobileView ? [0,-0.7,0] : [0,-0.1,0],
    position: isMobileView ? [-5.1, 4.55, -2.5] : [-5, 4.95, 0], 
    rotation: [0.0001, 0.02,  0.001], 
    scale: isMobileView ? 0.38 : 0.25  ,
    color:"#00fa9a"
    },

    {
    info:"[INFO] (release)",
    infoColor:"#00d5ff", 
    title: "                 Security and Compliance Solutions", 
    percentage: isMobileView ? "                                                                       ---- 80%" : "                                                                                                                         ---- 80%", 
    percentageColor:"#00fa9a",
    position: isMobileView ? [-5.1, 4.1, -2.5] : [-5, 4.7, 0], 
    rotation: [0.0001, 0.02,  0.001], 
    scale: isMobileView ? 0.38 : 0.25  , 
    color:"#fff"
    },

    { 
    title: "[NOTICE]", 
    percentage:"         Implementing robust security measures and ensuring compliance with industry standards and regulations like GDPR, HIPAA, etc", 
    percentageColor:"#fff",
    percentageWidth: isMobileView ? 20 : 50,
    percentagePosition: isMobileView ? [0,-0.7,0] : [0,-0.1,0],
    position: isMobileView ? [-5.1, 3.86, -2.5] : [-5, 4.55, 0], 
    rotation: [0.0001, 0.02,  0.001], 
    scale: isMobileView ? 0.38 : 0.25  ,
    color:"#00fa9a"
    },

    { 
    info:"[INFO] (release)",
    infoColor:"#00d5ff",
    title: "                 Performance Optimization", 
    percentage: isMobileView ? "                                                                       ---- 90%" : "                                                                                                                         ---- 90%", 
    percentageColor:"#00fa9a",
    position: isMobileView ? [-5.1, 3.4, -2.5] : [-5, 4.35, 0], 
    rotation: [0.0001, 0.02,  0.001], 
    scale: isMobileView ? 0.38 : 0.25  , 
    color:"#fff"},

    { 
    title: "[NOTICE]", 
    percentage:"         Enhancing the speed and efficiency of web applications through advanced caching techniques, load balancing, and resource optimization", 
    percentageColor:"#fff",
    percentageWidth: isMobileView ? 25 : 50,
    percentagePosition: isMobileView ? [0,-0.7,0] : [0,-0.1,0],
    position: isMobileView ? [-5.1, 3.2, -2.5] : [-5, 4.2, 0], 
    rotation: [0.0001, 0.02,  0.001], 
    scale: isMobileView ? 0.38 : 0.25  ,
    color:"#00fa9a"
    },

    {
    info:"[INFO] (release)",
    infoColor:"#00d5ff",
    title: "                 Internet of Things (IoT) Integrations", 
    percentage: isMobileView ? "                                                                       ---- 100% [SUCCESS]" : "                                                                                                                         ---- 100% [SUCCESS]", 
    percentageColor:"#00fa9a",
    position: isMobileView ? [-5.1, 2.75, -2.5] : [-5, 3.98, 0], 
    rotation: [0.0001, 0.02,  0.001], 
    scale: isMobileView ? 0.38 : 0.25  , 
    color:"#fff"},
    
    { 
    title: "[NOTICE]", 
    percentage:"         Developing web solutions that integrate with IoT devices and sensors for data collection and management", 
    percentageColor:"#fff",
    percentageWidth: isMobileView ? 20 : 50,
    percentagePosition: isMobileView ? [0,-0.7,0] : [0,-0.1,0],
    position: isMobileView ? [-5.1, 2.5, -2.5] : [-5, 3.8, 0], 
    rotation: [0.0001, 0.02,  0.001], 
    scale: isMobileView ? 0.38 : 0.25  ,
    color:"#00fa9a"
    },

  ], [isMobileView]);

  const scroll = isMobileView ? 399000 : 50000;

  useFrame(() => {
    const sequenceLength = val(sheet.sequence.pointer.length);
    sheet.sequence.position = previousScrollPosition / scroll * sequenceLength;
  });
  


  useEffect(() => {
    const calculateIndex = (sections, setIndex, intervalId) => {
      setIndex((prevIndex) => {
        if (prevIndex < sections.length - 1) {
          return prevIndex + 1;
        } else {
          clearInterval(intervalId);
          return sections.length - 1;
        }
      });
    };
  
    const handleInterval = (sections, setIndex, intervalDelay) => {
      const intervalId = setInterval(() => {
        calculateIndex(sections, setIndex, intervalId);
      }, intervalDelay);
  
      return intervalId;
    };
  
    const handleTimeout = (sections, setIndex, initialDelay, intervalDelay) => {
      const initialTimeoutId = setTimeout(() => {
        const intervalId = handleInterval(sections, setIndex, intervalDelay);
        return intervalId;
      }, initialDelay);
  
      return initialTimeoutId;
    };
  
    const handleVisibility = (showFirst, showSecond, overflow) => {
      setShowFirst(showFirst);
      setShowSecond(showSecond);
      document.documentElement.style.overflowY = overflow;
      document.body.style.overflowY = overflow;
    };
  
    const isBetween = (value, min, max) => value >= min && value < max;
  
    let intervalId;
  
    if (!isMobileView) {

      const scrollRatio = previousScrollPosition / scroll;

 
      
      if (isBetween(scrollRatio, 0.058, 0.076)) {
        handleVisibility(true, false, "hidden");
        intervalId = handleTimeout(textSections1, setCurrentIndex1, skipAnimation ? 80 : 1600, skipAnimation? 30 : 500);
      }
  
      if (currentIndex1 === textSections1.length - 1) {
        handleVisibility(false, true, "hidden");
        intervalId = handleTimeout(textSections2, setCurrentIndex2, skipAnimation ? 80 : 1600, skipAnimation? 30 : 500);
      }
  
      if (currentIndex2 === textSections2.length - 1) {
        handleVisibility(false, false, "visible");
      }

      if (currentIndex2 === textSections2.length - 1 && scrollRatio >= 0.06 && scrollRatio < 0.179) {
        handleVisibility(false, true, "visible");
      }
  
      if (currentIndex2 === textSections2.length - 1 && scrollRatio >= 0.18) {
        handleVisibility(false, false, "visible");
      }
    } 
    else {
      const scrollRatio = previousScrollPosition / scroll;

      if (isBetween(scrollRatio, 0.0112, 0.021)) {
        handleVisibility(true, false, "hidden");
        intervalId = handleTimeout(textSections1, setCurrentIndex1, skipAnimation ? 80 : 1600, skipAnimation? 30 : 500);
      }
  
      if (currentIndex1 === textSections1.length - 1) {
        handleVisibility(false, true, "hidden");
        intervalId = handleTimeout(textSections2, setCurrentIndex2, skipAnimation ? 80 : 1600, skipAnimation? 30 : 500);
      }
  
      if (currentIndex2 === textSections2.length - 1) {
        handleVisibility(false, false, "visible");
      }
  
      if (currentIndex2 === textSections2.length - 1 && scrollRatio >= 0.0112 && scrollRatio < 0.021) {
        handleVisibility(false, true, "visible");
      }

      if (currentIndex2 === textSections2.length - 1 && scrollRatio >= 0.021) {
        handleVisibility(false, false, "visible");
      }
    }
  
    return () => clearInterval(intervalId);
  
  }, [previousScrollPosition, isMobileView, scroll, currentIndex1, currentIndex2, textSections1, textSections2,skipAnimation, setCurrentIndex1, setCurrentIndex2]);
  
  const goToFirstInfo = () => {
        const handleVisibility = (showFirst, showSecond, overflow) => {
      setShowFirst(showFirst);
      setShowSecond(showSecond);
      document.documentElement.style.overflowY = overflow;
      document.body.style.overflowY = overflow;
    };

        if (currentIndex2 === textSections2.length - 1) {
        handleVisibility(true, false, "visible");
      }

  }

  const goToSecondInfo = () => {
      const handleVisibility = (showFirst, showSecond, overflow) => {
      setShowFirst(showFirst);
      setShowSecond(showSecond);
      document.documentElement.style.overflowY = overflow;
      document.body.style.overflowY = overflow;
    };

        if (currentIndex2 === textSections2.length - 1) {
        handleVisibility(false, true, "visible");
      }

  }
  
  const Spring = ({ size = 1, amount = 50, color = 'white', emissiveShadow, glow, ...props }) => (
    <mesh {...props}>
      <Float floatIntensity={2} speed={4} rotationIntensity={0.05}> 
      <SpringLogo3D color={color}/>
      </Float>
      <Glow position={[15.5, 2.33, 4.7]} scale={size * 0.8} near={-10} color={glow || emissiveShadow || color} />
      <Sparkles count={amount} scale={size * 3} size={8} speed={0.4} position={[16, 6.3, 4.5]} color={"green"}/>
      <Shadow rotation={[-Math.PI / 2, 0, 0]} scale={size/1.3} position={[15.45, 2.25, 4.75]} color={emissiveShadow} opacity={0.7} />
     <SpringImage/>
    </mesh>
  );

  const Grails = ({ size = 1, amount = 50, color = 'white', emissiveShadow, glow, ...props }) => (
    <mesh {...props}>
     <Float floatIntensity={2} speed={4} rotationIntensity={0.05}> 
     <GrailsLogo3D color={color}/>
     </Float>
     <Glow position={[-15.5, 2.3, 4.78]} scale={size * 0.8} near={-10} color={glow || emissiveShadow } />
     <Sparkles count={amount} scale={size * 3} size={8} speed={0.4} position={[-15.5, 6.2, 4.7]} color={"green"}/>
     <Shadow rotation={[-Math.PI / 2, 0, 0]} scale={size/1.3} position={[-15.45, 2.25, 4.75]} color={emissiveShadow} opacity={0.7} /> 
    <GrailsImage/>
      
    </mesh>
  );




  return (
    <>
   <ambientLight intensity={1.2}/>
      <PerspectiveCamera
        theatreKey="Camera"
        makeDefault
        fov={90}
        near={0.5}
        far={80}
      />
       <TextSection />

      {!isMobileView && previousScrollPosition / scroll >= 0.058 && showFirst && (
      textSections1.slice(0, currentIndex1 + 1).map((textSection, index) => (
      <TextSection {...textSection} key={index} />
      ))
      )}


      {!isMobileView && previousScrollPosition / scroll >= 0.058  && showSecond && (
      textSections2.slice(0, currentIndex2 + 1).map((textSection, index) => (
      <TextSection {...textSection} key={index} />
      ))
      )}


      {isMobileView && previousScrollPosition / scroll >= 0.0112 && showFirst && (
      textSections1.slice(0, currentIndex1 + 1).map((textSection, index) => (
      <TextSection {...textSection} key={index} />
      ))
      )}


      {isMobileView && previousScrollPosition / scroll >= 0.0112 &&  showSecond && (
      textSections2.slice(0, currentIndex2 + 1).map((textSection, index) => (
      <TextSection {...textSection} key={index} />
      ))
      )}
 
      {currentIndex2 === textSections2.length - 1 && showSecond && !isMobileView && previousScrollPosition / scroll >= 0.06 && previousScrollPosition / scroll < 0.179 && (<Html position={[6.25, 2.66, -2.5]} transform>
        <button className="buttons" onClick={() => goToFirstInfo()}>Back</button>
      </Html>)}

      {currentIndex2 === textSections2.length - 1 && showFirst && !isMobileView && previousScrollPosition / scroll >= 0.06 && previousScrollPosition / scroll < 0.179 && (<Html position={[6.25, 2.66, -2.5]} transform>
        <button className="buttons" onClick={() => goToSecondInfo()}>Next</button>
      </Html>)}
      

      {currentIndex2 === textSections2.length - 1 && showSecond && isMobileView && previousScrollPosition / scroll >= 0.0112 && previousScrollPosition / scroll < 0.021 && (<Html position={[3.5, 2.33, -2.92]} transform>
      <button className="buttons" onClick={() => goToFirstInfo()}>Back</button>
      </Html>)}

      {currentIndex2 === textSections2.length - 1 && showFirst && isMobileView && previousScrollPosition / scroll >= 0.0112 && previousScrollPosition / scroll < 0.021 && (<Html position={[3.5, 2.33, -2.92]} transform>
        <button className="buttons" onClick={() => goToSecondInfo()}>Next</button>
      </Html>)}


      {currentIndex2 !== textSections2.length - 1 && !isMobileView && previousScrollPosition / scroll >= 0.06 && previousScrollPosition / scroll < 0.179 && (<Html position={[6.25, 2.66, -2.5]} transform>
        <button className="buttons" onClick={() => setSkipAnimation(true)}>Skip</button>
      </Html>)}

      {currentIndex2 !== textSections2.length - 1 && isMobileView && previousScrollPosition / scroll >= 0.0112 && previousScrollPosition / scroll < 0.021 && (<Html position={[3.5, 2.33, -2.92]} transform>
      <button className="buttons" onClick={() => setSkipAnimation(true)}>Skip</button>
      </Html>)}



    <TextSection position={isMobileView ? [-8, 19, -0.1] : [-5.8, 18.5, -0.1]} rotation={isMobileView ? [-1.5,0,0] : [-1.5,0,0.001]} title="Scroll to dive into java world" scale={isMobileView ? 2 : 1.3} color="#253edf"/>
    
    <JavaSceneObject scaleScreen={(isMobileView && previousScrollPosition / scroll >= 0.0112 && previousScrollPosition / scroll < 0.021 ) ? [600, 3.161, 430] : [976.004, 3.161, 443.101]} />

    <Spring color="green" amount={50} emissiveShadow="green" glow="green" />
    <Grails color="green" amount={50} emissiveShadow="green" glow="green" />
    <LogoBigMR/>
    <LogoBigML/>
    <LogoSmallMR/>
    <LogoSmallML/>


    {!isMobileView && previousScrollPosition / scroll >= 0.184  && (
      <Html position={[-0.1,7.5,-50]} rotation={[0,0,0]} transform>
    <img src='assets/images/CCLogo.png' alt=''/>
    </Html>  
    )}

    {isMobileView && previousScrollPosition / scroll >= 0.022 &&  (
    <Html position={[-0.1,6,-40]} rotation={[0,0,0]} transform>
    <img src='assets/images/CCLogo.png' alt=''/>
    </Html>  
    )}


    {isMobileView && !isNavOpen && previousScrollPosition / scroll >= 0.022 &&
    (
      <>
      <Html position={[-0.4,-5,19]} rotation={[-2,0,-0.01]} transform>
      <a className="annotation" href='/'> Salesforce Portal </a>
      </Html>
          

      <Html position={[-0.5,-4.5,21.3]} rotation={[-2,0,0.01]} transform>
      <p className="fs-10 text-white"> Choose your next trip </p>
      </Html>

      <Html position={[-1.8,-3,22]} rotation={[-2,0,0.02]} transform>
      <a className="annotation" href='/webCastle'> Web Castle </a>
      </Html>

      <Html position={[1.1,-3.1,22]} rotation={[-2,0,0.02]} transform>
      <a className="annotation" href='/javaCentral'> Java Central </a>
      </Html>

      <Html position={[-0.3,-2.4,23]} rotation={[-2,0,0.03]} transform>
      <a className="annotation" href='/javaTeam'> Team </a>
      </Html>
      </>
    )
    }

   {!isMobileView && !isNavOpen && previousScrollPosition / scroll >= 0.184 &&
    (
      <>
      <Html position={[-0.5,-5,18]} rotation={[-2,0,-0.01]} transform>
      <a className="annotation" href='/'> Salesforce Portal </a>
      </Html>

      <Html position={[-0.5,-4.5,21.3]} rotation={[-2,0,0.03]} transform>
      <p className="fs-10 text-white"> Choose your next trip </p>
      </Html>

      <Html position={[-7,-3,20.5]} rotation={[-2,0,0.02]} transform>
      <a className="annotation" href='/webCastle'> Web Castle </a>
      </Html>

      <Html position={[7,-4,20.5]} rotation={[-2,0,0.02]} transform>
      <a className="annotation" href='/javaCentral'> Java Central </a>
      </Html>

      <Html position={[-0.5,-2.5,23]} rotation={[-2,0,0.05]} transform>
      <a className="annotation" href='/javaTeam'> Team </a>
      </Html>
      </>
    )
    }




    </>
  );
}

export default JavaScene;