import React from 'react';
import "./SalesforceInfoPage.css"
import AstroPlane from "../animationScenes/AstroPlane.json"
import AstroWaterfall from "../animationScenes/AstroWaterfall.json"
import Ballon from "../animationScenes/Ballon.json"
import Birds from "../animationScenes/Birds.json"
import Butterfly from "../animationScenes/Butterfly.json"
import SalesforceBear from "../animationScenes/SalesforceBear.json"
import SmallButterfly from "../animationScenes/SmallButterfly.json"
import Rainbow from "../animationScenes/Rainbow.json"
import Sun from "../animationScenes/Sun.json"
import Flower from "../animationScenes/Flower.json"
import Robot from "../animationScenes/Robot.json"
import Community from "../animationScenes/Community.json"
import Commerce from "../animationScenes/Commerce.json"
import Analitic from "../animationScenes/Analitic.json"
import Marketing from "../animationScenes/Marketing.json"
import CCRobot from "../animationScenes/CCRobot.json"
import Expertise from "../animationScenes/Expertise.json"
import Certified from "../animationScenes/Certified.json"
import Track from "../animationScenes/Track.json"
import Solutions from "../animationScenes/Solutions.json"
import Strategies from "../animationScenes/Strategies.json"
import Results from "../animationScenes/Results.json"
import Customers from "../animationScenes/Customers.json"
import Improvement from "../animationScenes/Improvement.json"
import Partnership from "../animationScenes/Partnership.json"

import Lottie from "lottie-react"

export default function SalesforceInfoPage() {

  return (

    <div className="main">


<section className="description">

  <h3 className="firstTitle text-primary text-center mt-4 fw-bold">Unlock the Full Potential of Salesforce with Our Expertise</h3>

  <div className="row">

    <div className="col-sm-12 col-md-12 col-lg-6">
      <div className="d-flex justify-content-center">
        <div className="round-card">
          <div className="row">
            
            <div className="col-3">

              <div style={{ maxWidth: "200px", maxHeight: "200px" }}> <Lottie animationData={SmallButterfly} /> </div>

              <div style={{ maxWidth: "200px", maxHeight: "200px", marginLeft: "40px" }}> <Lottie animationData={Flower} /> </div>

            </div>

            <div className="col-9">

              <div className="content">
                <h5 className="fw-bold">Simplifying Salesforce for You</h5>
                <p className="fw-semibold mt-3">Our expert team will guide you through every step, ensuring you get the most out of Salesforce without the hassle</p>
              </div>

            </div>

          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div className="rainbow">
          <Lottie animationData={Rainbow} />
        </div>
      </div>
    </div>

    <div className="col-sm-12 col-md-12 col-lg-6">

     
        <h4 className="information d-flex justify-content-center gap-2 fw-semibold">We provide ongoing support to keep things running smoothly
          <div className="d-flex justify-content-end" style={{ maxWidth: "50px", maxHeight: "50px", marginLeft: "20px" }}>  <Lottie animationData={Butterfly} />  </div>
        </h4>

        <h4 className="d-flex justify-content-center text-primary fw-semibold">
        
        <div className="d-flex justify-content-end" style={{ maxWidth: "150px", maxHeight: "150px" }}> <Lottie animationData={SmallButterfly} /></div>

        <h4 className="information fw-semibold">  Here are the clouds we specialize in </h4>

        </h4>

  
      <div className="d-flex justify-content-center gap-5">

        <div style={{ maxWidth: "150px", maxHeight: "150px" }}> <Lottie animationData={Ballon} /> </div>
        <div style={{ maxWidth: "500px", maxHeight: "500px" }}> <Lottie animationData={AstroPlane} /> </div>

      </div>

    </div>

  </div>

  <div style={{ position: "relative", width: "100%" }}>
    <div style={{ position: "absolute", bottom: -1, width: "100%" }}>
      <img loading="lazy" src="assets/images/bg-green-swoop-top.png" alt="" width="100%" />
    </div>
  </div>
</section>

<section className="salesService">
  <div className="row">

    <div className="col-sm-12 col-md-12 col-lg-6">

        <h3 className="information text-center text-white">Sales Cloud</h3>
        <h4 className="information text-center text-success mt-2">Boost your sales efficiency with streamlined</h4>
        <h4 className="information text-center text-warning mt-1">processes and improved lead management</h4>

      
      <div className="d-flex justify-content-start">

        <div style={{ maxWidth: "150px", maxHeight: "150px", marginLeft: "23%" }}>  <Lottie animationData={Sun} /> </div>
        <div style={{ maxWidth: "250px", maxHeight: "250px", marginLeft: "20%" }}>  <Lottie animationData={Birds} /> </div>

      </div>

      <div className="d-flex justify-content-center">
      <div className="robot"> <Lottie animationData={Robot} /> </div>
      </div>

    </div>

    <div className="col-sm-12 col-md-12 col-lg-6">

  
        <h3 className="information text-center text-white">Service Cloud</h3>
        <h4 className="information text-center text-warning mt-2">Deliver outstanding customer service with</h4>
        <h4 className="information text-center text-primary mt-1">automated case management and multi-channel support</h4>


      <div className="salesforceBear"> <Lottie animationData={SalesforceBear} /> </div>

    </div>

  </div>
</section>

<section className="communityAnalytics">

    <div>
      <img loading="lazy" src="assets/images/bg-green-swoop-bottom.png" alt="" width="100%"/>
    </div>
  
  <div className="row">

    <div className="col-sm-12 col-md-12 col-lg-6">

        <h3 className="information text-center">Community Cloud</h3>
        <h4 className="information text-center text-primary mt-2">Build vibrant customer and partner communities</h4>
        <h4 className="information text-center text-primary mt-1">for enhanced collaboration and support</h4>
 
      <div className="d-flex justify-content-center mt-3">
        <div className="community">  <Lottie animationData={Community} />  </div>
      </div>
    </div>

    <div className="col-sm-12 col-md-12 col-lg-6">


        <h3 className="information text-center">Analytics Cloud</h3>
        <h4 className="information text-center mt-2" style={{ color: "#12d4cc" }}>Unlock actionable insights</h4>
        <h4 className="information text-center mt-1" style={{ color: "#12d4cc" }}>with powerful analytics and interactive dashboards</h4>

      <div className="d-flex justify-content-center">
        <div className="analitic"> <Lottie animationData={Analitic} /> </div>
      </div>
    </div>

  </div>

</section>

<section className="marketingCommerce">

  <div className="row">

    <div className="col-sm-12 col-md-12 col-lg-6">

        <h3 className="information text-center">Marketing Cloud</h3>
        <h4 className="information text-center text-primary mt-2">Transform your marketing efforts</h4>
        <h4 className="information text-center text-primary mt-1">with personalized campaigns and data-driven insights</h4>
  
      <div className="d-flex justify-content-center mt-3">
        <div className="marketing"> <Lottie animationData={Marketing} /> </div>
      </div>

    </div>

    <div className="col-sm-12 col-md-12 col-lg-6">

        <h3 className="information text-center">Commerce Cloud</h3>
        <h4 className="information text-center mt-2" style={{ color: "#f18080" }}>Create seamless e-commerce experiences</h4>
        <h4 className="information text-center mt-1" style={{ color: "#f18080" }}>that drive customer engagement and loyalty</h4>

      <div className="d-flex justify-content-center">
        <div className="commerce"> <Lottie animationData={Commerce} /> </div>
      </div>

    </div>

  </div>

</section>

<section className="salesPlatform">
  <div className="row">

    <div className="col-sm-12 col-md-12 col-lg-6 ">

    <div className="information-salesPlatform">

    <div className="d-flex justify-content-end align-items-center" style={{ maxWidth: "800px", maxHeight: "800px" }}> <Lottie animationData={AstroWaterfall} />  </div>

    <div className="d-flex justify-content-end align-items-center fw-semibold">
          <div className="text-center">
              <h3 className="information" style={{ color: "#8c735d" }}>Salesforce Platform</h3>
              <h4 className="information text-success mt-2">Develop custom solutions tailored to your</h4>
              <h4 className="information text-warning mt-1">business needs and automate your workflows</h4>
          </div>
    </div>

    </div>

    </div>

    <div className="col-sm-12 col-md-12 col-lg-6">

      <div className="d-flex flex-column justify-content-center align-items-center text-center mt-5">
        <h3 className="information">Let us handle the complexity so you can focus on what you do best</h3>
        <h4 className="information" style={{ color: "#d67df4" }}>Reach out today to find out how we can help you</h4>
        <h4 className="information" style={{ color: "#d67df4" }}>simplify Salesforce and achieve your business goals</h4>
      </div>

      <div className="d-flex justify-content-center mt-5">
        <div className="CCRobot">  <Lottie animationData={CCRobot} /> </div>
      </div>
    </div>


  </div>


    <div style={{ position: "absolute", bottom: -3, width: "100%" }}>
      <img loading="lazy" src="assets/images/bg-light-green-swoop-top.png" alt="" className="topLineWhyUs" />
    </div>


</section>

<section className="why-us">
  
  <h2 className="text-center fw-semibold mb-2"> Why us </h2>

  <div className="row">

   <div className="col-lg-12 col-sm-12 d-flex justify-content-center">

   <div className="d-flex flex-column justify-content-end align-items-center">
        <div className="expertise">  <Lottie animationData={Expertise} /> </div>
        <h4 className="information fw-semibold"> Expertise and Experience </h4>
  </div>

  
   </div>

   <div className="col-lg-6 col-sm-12 d-flex justify-content-center">

   <div className="d-flex flex-column justify-content-end align-items-center">
        <div className="certified">  <Lottie animationData={Certified} /> </div>
        <h4 className="information fw-semibold"> Certified Salesforce </h4>
        <h4 className="information fw-semibold"> consultants and developers </h4>
      </div>
   </div>

   <div className="col-lg-6 col-sm-12 d-flex justify-content-center">

   <div className="d-flex flex-column justify-content-end align-items-center">
        <div className="track">  <Lottie animationData={Track} /> </div>
        <h4 className="information fw-semibold"> Proven track record of </h4>
        <h4 className="information fw-semibold"> successful implementations </h4>
      </div>

   </div>



   <div className="col-lg-12 col-sm-12 d-flex justify-content-center">

   <div className="d-flex flex-column justify-content-end align-items-center">
        <div className="solutions">  <Lottie animationData={Solutions} /> </div>
        <h4 className="information fw-semibold"> Customized Solutions </h4>
      </div>
   </div>

   <div className="col-lg-6 col-sm-12 d-flex justify-content-center">

   <div className="d-flex flex-column justify-content-end align-items-center">
        <div className="strategies">  <Lottie animationData={Strategies} /> </div>
        <h4 className="information fw-semibold"> Tailored strategies to meet </h4>
        <h4 className="information fw-semibold mb-3"> your specific business needs </h4>
      </div>

   </div>

   <div className="col-lg-6 col-sm-12 d-flex justify-content-center">

   <div className="d-flex flex-column justify-content-end align-items-center">
        <div className="results">  <Lottie animationData={Results} /> </div>
        <h4 className="information fw-semibold"> Focus on delivering </h4>
        <h4 className="information fw-semibold"> tangible results and ROI </h4>
      </div>

   </div>



   <div className="col-lg-12 col-sm-12 d-flex justify-content-center">

   <div className="d-flex flex-column justify-content-end align-items-center">
        <div className="customers">  <Lottie animationData={Customers} /> </div>
        <h4 className="information fw-semibold"> Customer-Centric Approach </h4>
      </div>

   </div>

   <div className="col-lg-6 col-sm-12 d-flex justify-content-center">

   <div className="d-flex flex-column justify-content-end align-items-center">
       <div className="improvement">  <Lottie animationData={Improvement} /> </div>
      <h4 className="information fw-semibold"> Dedicated support</h4>
      <h4 className="information fw-semibold"> and continuous improvement </h4>
      </div>

   </div>
   
   <div className="col-lg-6 col-sm-12 d-flex justify-content-center">
   <div className="d-flex flex-column justify-content-end align-items-center">
       <div className="partnership">  <Lottie animationData={Partnership} /> </div>
        <h4 className="information fw-semibold"> Long-term partnership</h4>
        <h4 className="information fw-semibold"> to ensure your success </h4>
      </div>
   </div>
   
  </div>

</section>

    <div class="info_section_sales layout_padding2">
      <div class="container">
        <div class="row">
          <div class="col-md-5 col-lg-4 info_col">
          <div class="info_contact">
              <h4>
                Address
              </h4>
              <div class="contact_link_box">
                <a href="#a">
                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                  <span>
                   Square 21
                  </span>
                </a>
 
              </div>

              <h4>
                Contacts
              </h4>

              <div class="contact_link_box">
               
                <a href="#a">
                  <i class="fa fa-phone" aria-hidden="true"></i>
                  <span>
                   +39 351 886 8016
                  </span>           
                </a>
                <a href="#a">
                  <i class="fa fa-phone" aria-hidden="true"></i>
                  <span>
                   +355 69 35 91 564
                  </span>           
                </a>
                <a href="/contact-us">
                  <i class="fa fa-envelope" aria-hidden="true"></i>
                  <span>
                    info@calm-coders.com
                  </span>
                </a>
          
              </div>
            </div>
       
          </div>
          <div class="col-md-5 col-lg-4 info_col">
            <div class="info_detail">
              <h4>
                Info
              </h4>
              <p style={{width:"250px"}}>
              Calm Coders team provides quality web design & development services.
                </p>
            
            </div>
          </div>
          <div class="col-md-2 col-lg-4 mx-auto info_col">
            <div class="info_link_box">
              <h4>
                Links
              </h4>
              <div class="info_links">
                <a className="nav-link" href="#home">
                  Home
                </a>
                <a className="nav-link" href="#distinction">
                  Distinction 
                </a>
                <a className="nav-link" href="#story">
                   Story
                </a>
                <a className="nav-link" href="#projects">
                   Projects 
                </a>
                <a className="nav-link" href="#methodology"> 
                   Methodology
                </a>
              </div>
            </div>
          </div>

        </div>
      </div>
      </div>
      


    <div class="footer_section_sales">
      <div class="container">
        <p>
          &copy; All Rights Reserved By Calm Coders
        </p>
      </div>
    </div>

</div>

  

  );
}

