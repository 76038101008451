import React, { Suspense, lazy, useEffect, useRef, useState } from 'react';
import "./TeamPage.css";
import { Canvas } from '@react-three/fiber';
import { Environment, useCursor } from '@react-three/drei';
import ScrollMagic from 'scrollmagic';
import TeamTransition from './TeamTransition';

const Mario3D = lazy(() => import("../components/teamScene/Mario"));
const Anxhelo3D = lazy(() => import("../components/teamScene/Anxhelo"));

const TextSection = lazy(() => import("../components/TextSection"));

export default function PhpTeamPage() {

  const [hovered, setHovered] = useState(null);
  useCursor(hovered);
  const [isMobileView, setIsMobileView] = useState(false);
  const [isDesktopView, setIsDesktopView] = useState(false);

  useEffect(() => {
    function checkScreenSize() {
      setIsMobileView(window.innerWidth <= 500); 
      setIsDesktopView(window.innerWidth > 500 && window.innerWidth <= 1680 ); 
    }

    checkScreenSize(); 

    window.addEventListener('resize', checkScreenSize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);


  const marioRef = useRef(null);
  const anxheloRef = useRef(null);

  const [renderMarioModel, setRenderMarioModel] = useState(false);
  const [renderAnxheloModel, setRenderAnxheloModel] = useState(false);

  const [marioSuspenseFinished, setMarioSuspenseFinished] = useState(0);
  const [anxheloSuspenseFinished, setAnxheloSuspenseFinished] = useState(0);


  useEffect(() => {

          const controller = new ScrollMagic.Controller();



          new ScrollMagic.Scene({
            duration: 1000,
            triggerElement: marioRef.current,
            triggerHook: 0,
            })
            .setPin(marioRef.current)
            .addTo(controller)
            .on('enter', () => {    
              setRenderMarioModel(true)
              setRenderAnxheloModel(false)
            })
    
    
            new ScrollMagic.Scene({
            duration: 1000,
            triggerElement: anxheloRef.current,
            triggerHook: 0,
            })
            .setPin(anxheloRef.current)
            .addTo(controller)
            .on('enter', () => {    
            setRenderMarioModel(false)
            setRenderAnxheloModel(true)
            })
    
  }, []);
 



  useEffect(()=> {


    if (renderMarioModel && marioSuspenseFinished === 100){
      
      if(isMobileView){
        setTimeout(() => {
          document.documentElement.style.overflowY = "visible";
          }, 500 );
  
        document.body.style.overflowY = ""
      }

      else{
        setTimeout(() => {
          document.body.style.overflowY = "visible"
          }, 300 );
        document.documentElement.style.overflowY=""
      }
    }
    
    else{
  
      if(isMobileView){
        document.documentElement.style.overflowY = "hidden";
        document.body.style.overflowY = ""
      }
      else{
        document.documentElement.style.overflowY=""
        document.body.style.overflowY = "hidden"
      }
    }
  
  
  },[renderMarioModel,marioSuspenseFinished,isMobileView])

  useEffect(() => {

    if((document.body.style.overflowY==="hidden" || document.documentElement.style.overflowY === "hidden") && renderMarioModel && marioSuspenseFinished === 0){
      setTimeout(() => {
        setMarioSuspenseFinished(100)
      }, isMobileView ? 300 : 200);
    }
  }, [renderMarioModel, marioSuspenseFinished, isMobileView])
  

  useEffect(()=> {


    if (renderAnxheloModel && anxheloSuspenseFinished === 100){
      
      if(isMobileView){
        setTimeout(() => {
          document.documentElement.style.overflowY = "visible";
          }, 500 );
  
        document.body.style.overflowY = ""
      }

      else{
        setTimeout(() => {
          document.body.style.overflowY = "visible"
          }, 300 );
        document.documentElement.style.overflowY=""
      }
    }
    
    else{
  
      if(isMobileView){
        document.documentElement.style.overflowY = "hidden";
        document.body.style.overflowY = ""
      }
      else{
        document.documentElement.style.overflowY=""
        document.body.style.overflowY = "hidden"
      }
    }
  
  
  },[renderAnxheloModel,anxheloSuspenseFinished,isMobileView])

  useEffect(() => {

    if((document.body.style.overflowY==="hidden" || document.documentElement.style.overflowY === "hidden") && renderAnxheloModel && anxheloSuspenseFinished === 0){
      setTimeout(() => {
        setAnxheloSuspenseFinished(100)
      }, isMobileView ? 300 : 200);
    }
  },[renderAnxheloModel,anxheloSuspenseFinished,isMobileView])



  return (
    
    <div>

  <div id='php' ref={marioRef}  style={{ width: '100%', height: '100vh', backgroundColor: "#1e2a44"}}>
  {renderMarioModel &&
  <Suspense fallback={<TeamTransition  name={"Mario"} surname={"Prifti"}/>}> 
  <Canvas>
   <ambientLight intensity={0.5}/>
   <Environment preset="sunset"/>
   <Mario3D scale={3} position={isMobileView ? [0,-4.3,-2] : isDesktopView ? [-3.7,-2.5,-3] : [-5.5,-2.5,-3]} rotation={isMobileView ? [0.16,0.6,0.012] : isDesktopView ? [0.35,0.55,-0.13] : [0.35,1.05,-0.13]} hoverd={hovered ==="Mario"}/>      
   <TextSection title={"Php and Python Developer"} 
    position={isMobileView ? [-0.9,3.2,0.1] : [0,1.7,0]} 
    fontSize={isMobileView ? 0.148 : 0.2} 
    maxWidth={isMobileView ? 3.5 : 4} 
    color={"#d6d3d5"} 
    fillOpacity={3} />
   <TextSection 
                title={isMobileView
                  ? "Versatile software developer with extensive experience in both     Python and PHP. Specialized in     web application development using Django and Laravel frameworks. Proven ability to deliver high-quality, scalable, and analysis with a focus on delivering high-quality, scalable, and maintainable maintainable code. Strong background in full-stack development and data code. Adept at working in fast-paced environments and collaborating with cross-functional teams to achieve project goals."
                  : "Versatile software developer with extensive experience in both Python    and PHP. Specialized in web application development using Django and Laravel frameworks. Proven ability to deliver high-quality, scalable, and analysis with a focus on delivering high-quality, scalable, and maintainable maintainable code. Strong background in full-stack development and data code. Adept at working in fast-paced environments and collaborating with cross-functional teams to achieve project goals."} 
   position={isMobileView ? [-1.05,3,0.1] : [0,1.2,0]} 
   fontSize={isMobileView ? 0.125 : 0.2} 
   maxWidth={isMobileView ? 2.7 : 4.8}
   color={"#d6d3d5"} 
   fillOpacity={3} 
   onPointerEnter={() => setHovered("Mario")}  
   onPointerLeave={() => setHovered(null)}/>
  <TextSection title={"Scroll to see more"} 
    position={isMobileView ? [-1.7, 0.9, 0] : isDesktopView ? [-4.8, 0.5, 0] :  [-7.5, 0.9, 0]}
    fontSize={isMobileView ? 0.13 : 0.2} 
    maxWidth={isMobileView ? 0.1 : 1} 
    color={"#d6d3d5"} 
    fillOpacity={3} /> 
  </Canvas>
  </Suspense>
   }


      </div>
      
  <div ref={anxheloRef}  style={{ width: '100%', height: '100vh', backgroundColor: "#1e2a44"}}>
   {renderAnxheloModel &&
    <Suspense fallback={<TeamTransition  name={"Anxhelo"} surname={"Shperdheja"}/>}> 
   <Canvas style={{ width: '100%', height: isMobileView ? '106vh' : '102vh', backgroundColor: "#1e202c"}}>
    <ambientLight intensity={0.5}/>
    <Environment preset="sunset"/>
    <Anxhelo3D scale={3} position={isMobileView ? [-0.04,-4,-2.6] : isDesktopView ? [-3.3,-2.5,-3] : [-4.5,-2.5,-3]} rotation={isMobileView ? [0.2,-0.2,-0.03] : [0.3,0.2,-0.095]} hoverd={hovered ==="Anxhelo"}/>   
    <TextSection title={"Php Developer"} 
    position={isMobileView ? [-0.6,3,0.1] : [0,1.8,0]} 
    fontSize={isMobileView ? 0.15 : 0.2} 
    maxWidth={isMobileView ? 2.4 : 4} 
    color={"#cccccc"} 
    fillOpacity={3} />
    <TextSection title={isMobileView 
    ? 
    "Hello I'm Anxhelo              I'm a tech enthusiast who loves Laravel and Vue.js but can juggle any tech you throw at him.    From building apps from scratch to working with global clients, he's made it his mission to create solutions that users love. Always curious and never bored, he's constantly exploring       new technologies!" 
    :
    "Hello I'm Anxhelo              I'm a tech enthusiast who loves Laravel and Vue.js but can juggle any tech you throw at him.     From building apps from scratch to working with global clients, he's made it his mission to create solutions that users love. Always curious and never bored, he's constantly exploring new technologies!"} 
    position={isMobileView ? [-1.05,2.7,0.1] : [0,1.3,0]} 
    rotation={[0,0,0]} 
    fontSize={isMobileView ? 0.12 : 0.2} 
    maxWidth={isMobileView ? 2.4 : 4} 
    color={"#cccccc"} 
    fillOpacity={3} 
    onPointerEnter={() => setHovered("Anxhelo")}  
    onPointerLeave={() => setHovered(null)}/>
   </Canvas>
   </Suspense>
   }
   </div>

  </div>

  );
}
