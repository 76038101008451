import React, { useEffect } from "react";
import './WebDevelopmentTransition.css';
import { useProgress } from "@react-three/drei";

export default function WebDevelopmentTransition({ onProgressChange }) {
  const { progress } = useProgress();

  useEffect(() => {
    if (onProgressChange) {
      onProgressChange(progress);
    }
  }, [progress, onProgressChange]);
  return (

    <div className="titleWeb">
    <h1 className="text1Web"> Web </h1>
    <h1 className="text1Web"> Development </h1>
    <h1 className="textWeb"> Loading {String(progress).slice(0, 3)} % </h1>
   </div>

  );

};

