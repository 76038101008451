import React from 'react';
import { Canvas } from "@react-three/fiber";
import { Overlay } from '../components/mainScene/Overlay';
import { HeaderLogo } from '../components/mainScene/HeaderLogo';
import "./Header.css"
//import { Perf } from 'r3f-perf';

export default function Header() {
  
    return (

    <>
    <Canvas>
    <HeaderLogo/>
    </Canvas>
    <Overlay/>
    </>
      
       );
}