import React from "react";
import './JavaTransitionDesktop.css';
import { useProgress } from "@react-three/drei";

export default function JavaTransitionDesktop() {
  const { progress } = useProgress();

  return (

    <div className="titleJava">

    <h1 className="text1Java"> 
    <div>J</div>
    <div>A</div>
    <div>V</div>
    <div>A</div>
    </h1>

    <h1 className="text2Java">     
    <div>T</div>
    <div>H</div>
    <div>R</div>
    <div>E</div>
    <div>A</div>
    <div>D</div>
    </h1>

    <h1 className="textJava"> Loading {String(progress).slice(0, 3)} % </h1>

   </div>

  );

};
