import React, { useEffect, useState,useRef, Suspense } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../Home.css";
import ScrollMagic from 'scrollmagic';
import WebDevelopmentTransition from '../transitions/WebDevelopmentTransition';
import WebDevelopmentScene from '../webDevelopmentPage/WebDevelopmentScene';


export default function WebDevelopmentComponent( ) {

const webDevelopmentRef = useRef(null);

  const [isMobileView, setIsMobileView] = useState(false);
  const [previousScrollPosition, setPreviousScrollPosition] = useState(0);

  
  const [renderWebDevelopmentPage, setRenderWebDevelopmentPage] = useState(false);
  const [webDevelopmentProgress, setWebDevelopmentProgress] = useState(0);
  const [webDevelopmentSuspenseFinished, setWebDevelopmentSuspenseFinished] = useState(0);
  const [imAtWebDevelopment,setImAtWebDevelopment]=useState(false);




  useEffect(() => {
    function checkScreenSize() {
      setIsMobileView(window.innerWidth <= 500); 
    }

    checkScreenSize(); 

    window.addEventListener('resize', checkScreenSize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  const isLandscape = () => window.matchMedia('(orientation:landscape)').matches;
  const [orientation, setOrientation] = useState(isLandscape() ? 'landscape' : 'portrait');
  const [prevOrientation, setPrevOrientation] = useState(orientation);



  useEffect(() => {


    const controller = new ScrollMagic.Controller();
    if (!isMobileView) {
      new ScrollMagic.Scene({
        duration: 22550,
        triggerElement: webDevelopmentRef.current,
        triggerHook: 0,
      })
            
        .setPin(webDevelopmentRef.current)
        .addTo(controller)
        .on('enter', () => {
          setRenderWebDevelopmentPage(true)
          setImAtWebDevelopment(true)
      
        })
    }
    else {
      new ScrollMagic.Scene({
        duration: 122550,
        triggerElement: webDevelopmentRef.current,
        triggerHook: 0,
      })
            
        .setPin(webDevelopmentRef.current)
        .addTo(controller)
        .on('enter', () => {
          setRenderWebDevelopmentPage(true)
          setImAtWebDevelopment(true)
      
        })
    }
  }, []);

  
  useEffect(() => {
    const handleScrollChange = (event) => {
      const currentScrollPosition = window.scrollY;

      if (currentScrollPosition !== previousScrollPosition) {
        setPreviousScrollPosition(currentScrollPosition);
      }

    };

    window.addEventListener('scroll', handleScrollChange, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScrollChange);
    };

  }, [previousScrollPosition]);


  useEffect(() => {

    if((webDevelopmentProgress===100)){
      setWebDevelopmentSuspenseFinished(webDevelopmentProgress)
    }
    
  }, [webDevelopmentProgress,webDevelopmentSuspenseFinished]);


  useEffect(() => {
    const preventDefaultScroll = (event) => {
      event.preventDefault();
    };
  
    if ((imAtWebDevelopment && webDevelopmentSuspenseFinished === 100)) {
      
    if (isMobileView) {
        setTimeout(() => {
          document.documentElement.style.overflowY = "visible";
        }, 2900);
        document.body.style.overflowY = "";
      } 
      else {
        setTimeout(() => {
          document.body.style.overflowY = "visible";
        }, 1000);
        document.documentElement.style.overflowY = "";
      }
  
      window.removeEventListener("DOMMouseScroll", preventDefaultScroll, false);
      window.removeEventListener("wheel", preventDefaultScroll);
      window.removeEventListener("touchmove", preventDefaultScroll);
    } 

    else {
      if (isMobileView) {
        document.documentElement.style.overflowY = "hidden";
        document.body.style.overflowY = "";
      } else {
        document.documentElement.style.overflowY = "";
        document.body.style.overflowY = "hidden";
      }
      window.addEventListener("DOMMouseScroll", preventDefaultScroll, false);
      window.addEventListener("wheel", preventDefaultScroll, { passive: false });
      window.addEventListener("touchmove", preventDefaultScroll, { passive: false });
    }
  
    return () => {
      window.removeEventListener("wheel", preventDefaultScroll);
      window.removeEventListener("touchmove", preventDefaultScroll);
    };
  }, [
    imAtWebDevelopment,
    webDevelopmentSuspenseFinished,
    isMobileView
  ]);
  




  useEffect(() => {
    const preventDefaultScroll = (event) => {
      event.preventDefault();
    };
    if( (document.body.style.overflowY==="hidden" ||  document.documentElement.style.overflowY === "hidden") && imAtWebDevelopment && webDevelopmentSuspenseFinished === 0){
      setTimeout(() => {
      setWebDevelopmentSuspenseFinished(100);
      }, isMobileView ? 900 : 200);

      if(isMobileView){
        document.documentElement.style.overflowY = "hidden";
        document.body.style.overflowY = ""
      }
      else{
        document.documentElement.style.overflowY=""
        document.body.style.overflowY = "hidden"
      }

      window.removeEventListener('wheel', preventDefaultScroll);
      window.removeEventListener('touchmove', preventDefaultScroll);
    }
  },[imAtWebDevelopment,webDevelopmentSuspenseFinished,isMobileView])


const onWindowResize = () => {
  clearTimeout(window.resizeLag);

  window.resizeLag = setTimeout(() => {
    delete window.resizeLag;
    const currentOrientation = isLandscape() ? 'landscape' : 'portrait';

    if (currentOrientation !== orientation) {
      setPrevOrientation(orientation);
      setOrientation(currentOrientation);
    }
  }, 100);
};

useEffect(() => {
  if (orientation !== prevOrientation) {
    window.location.reload();
  }
}, [orientation, prevOrientation]);

useEffect(() => {
  onWindowResize();
  window.addEventListener('resize', onWindowResize);

  return () => {
    window.removeEventListener('resize', onWindowResize);
  };
}, []);


  return (
      <>
      <div id="webDevelopment">
      <div ref={webDevelopmentRef} className="panel-webDevelopmentScene">
      {renderWebDevelopmentPage && <Suspense fallback={<WebDevelopmentTransition onProgressChange={setWebDevelopmentProgress} />}> <WebDevelopmentScene previousScrollPosition={previousScrollPosition}/> </Suspense> }
      </div>          
      </div>
      </>
  );
}

