

import React, { lazy } from 'react'
import TextSection from '../TextSection';


const SalesCloud = lazy(() => import("./SalesCloud"));

export function CloudTemplate({x,y,z,rotationX,rotationY,rotationZ,scaleX,scaleY,scaleZ ,cloudColor,cloudOverlineColor,cloudOverlineHoverColor,outlines,text1X,text1Y,text1Z,text1RotationX,text1RotationY,text1RotationZ,title1, color1,fontSize1,maxWidth1, text2X,text2Y,text2Z,text2RotationX,text2RotationY,text2RotationZ,title2, color2,fontSize2,maxWidth2 }) {

  return (
    <>
    <SalesCloud x={x} y={y} z={z} rotationX={rotationX} rotationY={rotationY} rotationZ={rotationZ} scaleX={scaleX} scaleY={scaleY} scaleZ={scaleZ} cloudColor={cloudColor} cloudOverlineColor={cloudOverlineColor} cloudOverlineHoverColor={cloudOverlineHoverColor} outlines={outlines} />
    <TextSection title={title1} fontSize={fontSize1} maxWidth={maxWidth1} fillOpacity={5} letterSpacing={-0.01} color={color1} position={[text1X,text1Y,text1Z]} rotation={[text1RotationX,text1RotationY,text1RotationZ]}  />
    <TextSection title={title2} fontSize={fontSize2} maxWidth={maxWidth2} fillOpacity={5} letterSpacing={-0.01} color={color2} position={[text2X,text2Y,text2Z]} rotation={[text2RotationX,text2RotationY,text2RotationZ]}  />
    </>
  )
}


