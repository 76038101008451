import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './Home';
import reportWebVitals from './reportWebVitals';
//import Loader from './pageComponents/Loader';
//import extension from '@theatre/r3f/dist/extension'
//import studio from '@theatre/studio';
//import { PlayProvider } from './components/mainScene/Play';

/*function onRender(id, phase, actualDuration, baseDuration, startTime, commitTime) {
  console.log(id,phase,actualDuration,baseDuration,startTime,commitTime)
}*/

//studio.extend(extension);
//studio.initialize()

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    
    /* <Suspense fallback={ <Loader/>}> */

    /* <Profiler id="App" onRender={onRender}> */
    <Home/>
    /* </Profiler> */

   /* </Suspense> */


);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
