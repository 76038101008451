import React, { useEffect, useState,useRef, Suspense } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../Home.css";
import ScrollMagic from 'scrollmagic';
import JavaTransitionDesktop from '../transitions/JavaTransitionDesktop';
import JavaScene from '../javaPage/JavaScene';



export default function JavaComponent( ) {

  const javaRef = useRef(null);


  const [isMobileView, setIsMobileView] = useState(false);
  const [previousScrollPosition, setPreviousScrollPosition] = useState(0);

  
  const [renderJavaPage, setRenderJavaPage] = useState(true);
  const [javaSuspenseFinished, setJavaSuspenseFinished] = useState(100);
  const [imAtJava,setImAtJava]=useState(false);
  const [javaProgress, setJavaProgress] = useState(0);


  useEffect(() => {
    function checkScreenSize() {
      setIsMobileView(window.innerWidth <= 500); 
    }

    checkScreenSize(); 

    window.addEventListener('resize', checkScreenSize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  const isLandscape = () => window.matchMedia('(orientation:landscape)').matches;
  const [orientation, setOrientation] = useState(isLandscape() ? 'landscape' : 'portrait');
  const [prevOrientation, setPrevOrientation] = useState(orientation);



  useEffect(() => {


      const controller = new ScrollMagic.Controller();
      
          new ScrollMagic.Scene({
            duration: 11100,
            triggerElement: javaRef.current,
            triggerHook: 0,
          })
         
            .setPin(javaRef.current)
            .addTo(controller)
            .on('enter', () => {
              setImAtJava(true)
              setRenderJavaPage(true)   
    
            })
            


  }, []);

  
  useEffect(() => {
    const handleScrollChange = (event) => {
      const currentScrollPosition = window.scrollY;

      if (currentScrollPosition !== previousScrollPosition) {
        setPreviousScrollPosition(currentScrollPosition);
      }

    };

    window.addEventListener('scroll', handleScrollChange, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScrollChange);
    };

  }, [previousScrollPosition]);


  useEffect(() => {

    if(javaProgress===100){
      setJavaSuspenseFinished(javaProgress)
    }
    
  }, [javaProgress,javaSuspenseFinished]);


  useEffect(() => {
    const preventDefaultScroll = (event) => {
      event.preventDefault();
    };
  
    if ((imAtJava && javaSuspenseFinished === 100)) {
      
    if (isMobileView) {
        setTimeout(() => {
          document.documentElement.style.overflowY = "visible";
        }, 2900);
        document.body.style.overflowY = "";
      } 
      else {
        setTimeout(() => {
          document.body.style.overflowY = "visible";
        }, 1000);
        document.documentElement.style.overflowY = "";
      }
  
      window.removeEventListener("DOMMouseScroll", preventDefaultScroll, false);
      window.removeEventListener("wheel", preventDefaultScroll);
      window.removeEventListener("touchmove", preventDefaultScroll);
    } 

    else {
      if (isMobileView) {
        document.documentElement.style.overflowY = "hidden";
        document.body.style.overflowY = "";
      } else {
        document.documentElement.style.overflowY = "";
        document.body.style.overflowY = "hidden";
      }
      window.addEventListener("DOMMouseScroll", preventDefaultScroll, false);
      window.addEventListener("wheel", preventDefaultScroll, { passive: false });
      window.addEventListener("touchmove", preventDefaultScroll, { passive: false });
    }
  
    return () => {
      window.removeEventListener("wheel", preventDefaultScroll);
      window.removeEventListener("touchmove", preventDefaultScroll);
    };
  }, [

    javaSuspenseFinished,
    imAtJava,
    isMobileView

  ]);
  




  useEffect(() => {
    const preventDefaultScroll = (event) => {
      event.preventDefault();
    };
    if((document.body.style.overflowY==="hidden" || document.documentElement.style.overflowY === "hidden") && imAtJava && javaSuspenseFinished === 0){

      setTimeout(() => {
      setJavaSuspenseFinished(100);
      }, isMobileView ? 900 : 300);

      if(isMobileView){
        document.documentElement.style.overflowY = "hidden";
        document.body.style.overflowY = ""
      }
      else{
        document.documentElement.style.overflowY=""
        document.body.style.overflowY = "hidden"
      }

      window.removeEventListener('wheel', preventDefaultScroll);
      window.removeEventListener('touchmove', preventDefaultScroll);
    }

    
  },[imAtJava,javaSuspenseFinished,isMobileView])








const onWindowResize = () => {
  clearTimeout(window.resizeLag);

  window.resizeLag = setTimeout(() => {
    delete window.resizeLag;
    const currentOrientation = isLandscape() ? 'landscape' : 'portrait';

    if (currentOrientation !== orientation) {
      setPrevOrientation(orientation);
      setOrientation(currentOrientation);
    }
  }, 100);
};

useEffect(() => {
  if (orientation !== prevOrientation) {
    window.location.reload();
  }
}, [orientation, prevOrientation]);

useEffect(() => {
  onWindowResize();
  window.addEventListener('resize', onWindowResize);

  return () => {
    window.removeEventListener('resize', onWindowResize);
  };
}, []);


  return (
      <>


      <div id="java">
      <div ref={javaRef} className="panel-javaScene">
      {renderJavaPage &&  <Suspense fallback={<JavaTransitionDesktop onProgressChange={setJavaProgress}/>}> <JavaScene previousScrollPosition={previousScrollPosition}/> </Suspense> }
      </div>
      </div>

    
      </>
  );
}

