import React from 'react';
import "./WebDevelopmentInfoPage.css"

export default function WebDevelopmentInfoPage() {




  return (

<body>

    <div class="hero_area_web">

    <div class="hero_bg_box">
    <div class="bg_img_box">
    <img src="assets/images/hero-bg.png" alt="" />
    </div>
    </div>

    <header className="header_section_web">
    <div className="container-fluid">
      <nav className="custom_nav-container navbar-expand">   

          <ul className="navbar-nav">
            <li className="nav-item active">
              <a className="nav-link" href="#home"> Home </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#distinction"> Distinction </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#story"> Story </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#projects"> Projects </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#methodology"> Methodology </a>
            </li>
          </ul>

      </nav>
    </div>
    </header>

    <div class="home_web" id="home">

    <div class="container">
          <div class="row">
            <div class="col-md-6 ">
              <div class="detail-box">
                <h1>
                ​Welcome to <br/>
                 Web Development
                </h1>
                <p>
                At Calm Coders we are dedicated to harnessing cutting-edge technologies to deliver innovative solutions that empower businesses and enhance user experiences 
                </p>
                <p>
                With expertise in Java Spring Boot, React, Angular, PHP and Laravel we are committed to pushing the boundaries of web application development
                </p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="img-box">
                <img src="assets/images/slider-img.png" alt="" />
              </div>
            </div>
            <div className='col-md-6'>        
            <div class="technology_container">
            <div class="row">

            <div class="col-3">
                <div class="img-box1">
                  <img src="assets/images/spring.png" class="img1" alt=""/>
                </div>
            </div>

            <div class="col-3">
                <div class="img-box1">
                  <img src="assets/images/react.png" class="img2" alt=""/>
                </div>
            </div>

            <div class="col-3">
                <div class="img-box1">
                  <img src="assets/images/angular.png" class="img3" alt="" />
                </div>
            </div>

            <div class="col-3">
                <div class="img-box1">
                  <img src="assets/images/laravel.png" class="img4" alt=""/>
                </div>
            </div>
 
          </div>
        </div>
      </div>

    </div>

    </div>

    </div>

    </div>


    <div class="distinction_web" id='distinction'>
        <div class="container">
          <div class="heading_container_web heading_center">
            <h2 className='mt-4'>
            What Sets Us <span>Apart</span>
            </h2>
            <p>
            At our company, we are committed to excellence and innovation in every project we undertake
            </p>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="box">
                <div class="img-box">
                  <img src="assets/images/s1.png" alt="" />
                </div>
                <div class="detail-box">
                  <h5>
                  Programming expertise
                  </h5>
                  <p>
                  Our seasoned team leverages modern technologies to deliver robust, scalable, and feature-rich web applications tailored to your needs
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="box ">
                <div class="img-box">
                  <img src="assets/images/s2.png" alt="" />
                </div>
                <div class="detail-box">
                  <h5>
                  ​Innovation at the Core
                  </h5>
                  <p>
                  We thrive on innovation, continually exploring new technologies to drive digital transformation and exceed expectations
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="box ">
                <div class="img-box">
                  <img src="assets/images/s3.png" alt="" />
                </div>
                <div class="detail-box">
                  <h5>
                  ​Client-Centric Approach
                  </h5>
                  <p>
                  ​We prioritize understanding our client's objectives and goals, ensuring that every solution we deliver is aligned with their vision and contributes to their success
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>


    <div class="story_web layout_padding" id='story'>
      <div class="container">
        <div class="heading_container_web heading_center">
          <h2>
            Our <span>Story</span>
          </h2>
        </div>
        <div class="row">
          <div class="col-md-6 ">
            <div class="img-box">
              <img src="assets/images/about-img.png" alt="" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="detail-box">
              <h3>
                We Are Calm Coders
              </h3>
              <p>
              Our journey began with a vision to revolutionize the digital landscape by harnessing the power of cutting-edge technologies
              </p>
              <p>
              ​Since our inception, we've remained dedicated to this mission, continuously evolving and adapting to meet the ever-changing needs of our clients
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="projects_web layout_padding" id='projects'>
      <div class="container">

        <div class="heading_container_web heading_center">
          <h2>
          Projects
          </h2>
        </div>

     <div class="projects_container">
          <div className='row'>

          <div className='col-lg-6 col-12'>
          <div class="box">
            <div class="img-box">
              <img src="assets/images/caricambi.png" alt="" class="img-box-project1"/>
            </div>
            <div class="detail-box">
              <h5>
                Caricambi
              </h5>
              <p> 
              The ultimate solution for managing your automotive sales and customer relationships. 
              Our intuitive interface empowers dealerships to streamline operations, track leads and enhance customer satisfaction.
              With real-time insights, automated follow-ups and seamless integration with your existing systems, our CRM helps you stay ahead in the competitive market. 
              Experience the future of automotive sales with our comprehensive and user-friendly platform
              </p>
            </div>
          </div>
          </div>

          <div className='col-lg-6 col-12'>
          <div class="box">
            <div class="img-box">
              <img src="assets/images/sniper.png" alt="" class="img-box-project2"/>
            </div>
            <div class="detail-box">
              <h5>
               Sniper
              </h5>
              <p>
              Sniper where every lead becomes an opportunity and every interaction is optimized for success.
              Our advanced system empowers sales teams to efficiently manage leads, track sales pipelines and nurture relationships with clients.
              With customizable dashboards, insightful analytics, and automated workflows, our page simplifies sales processes and drives revenue growth. 
              Say goodbye to scattered data and missed opportunities - embrace the power of our page and unlock your team's full potential
              </p>
            </div>
          </div>
          </div>

          <div className='col-lg-6 col-12'>
          <div class="box">
            <div class="img-box">
              <img src="assets/images/calm-coders.png" alt="" class="img-box-project3"/>
            </div>
            <div class="detail-box">
              <h5>
                Calm Coders
              </h5>
              <p>    
               3D scrolling experience! Step into a world where every scroll reveals a new dimension of creativity and innovation. 
               Navigate through stunning landscapes, intricate designs, and captivating animations as you journey deeper into our digital realm. 
               From breathtaking visuals to interactive elements, every detail is meticulously crafted to engage and inspire.
               Explore at your own pace, uncover hidden surprises, and immerse yourself in a scroll like never before. 
               Welcome to the future of web design - where every scroll tells a story in three dimensions
              </p>
            </div>
          </div>
          </div>

          <div className='col-lg-6 col-12'>
          <div class="box">
            <div class="img-box">
              <img src="assets/images/probranding.png" alt="" class="img-box-project4"/>
            </div>
            <div class="detail-box">
              <h5>
              ProBranding
              </h5>
              <p>    
                      ProBranding is a comprehensive project focused on CRM and user management.
                      It aims to streamline and enhance interactions with customers by integrating features that manage customer data, track communications, and analyze customer behavior.
                      Additionally, it includes robust user management functionalities to handle user roles, permissions, and authentication, ensuring secure and efficient access to various system components.
                      The project is designed to improve business operations, foster better customer relationships, and optimize user experiences.
              </p>
            </div>
          </div>
          </div>


          </div>
        </div>
      </div>
    </div>


    <div class="methodology_web layout_padding" id='methodology'>
      <div class="container-fluid">
        <div class="heading_container_web heading_center">
          <h2 class="">
            <span> How </span> We Do It
          </h2>
        </div>

        <div class="team_container">
          <div class="row">
            <div class="col-lg-4 col-12">
              <div class="box">
                <div class="img-box">
                  <img src="assets/images/strategic-planning.jpg" class="img1" alt="" />
                </div>
                <div class="detail-box">
                  <h5>
                  Strategic Planning
                  </h5>
                  <p>
                  ​We start by gaining a deep understanding of your business objectives and target audience, allowing us to devise a strategic plan tailored to your specific needs
                  </p>
                </div>
      
              </div>
            </div>
            <div class="col-lg-4 col-12">
              <div class="box">
                <div class="img-box">
                  <img src="assets/images/agile-software.png" class="img1" alt="" />
                </div>
                <div class="detail-box">
                  <h5>
                  Agile Development​
                  </h5>
                  <p>
                  ​Our agile development methodology ensures flexibility and transparency throughout the project lifecycle, enabling us to adapt to changing requirements and deliver results efficiently
                  </p>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-12">
              <div class="box">
                <div class="img-box">
                  <img src="assets/images/quality-assurance.jpg" class="img1" alt="" />
                </div>
                <div class="detail-box">
                  <h5>
                  ​Quality Assurance
                  </h5>
                  <p>
                  ​We are here to stringent quality standards at every stage of development, conducting rigorous testing to guarantee the reliability, security, and performance of our solutions
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

      

    <div class="info_section_web layout_padding2">
      <div class="container">
        <div class="row">
          <div class="col-md-5 col-lg-4 info_col">
          <div class="info_contact">
              <h4>
                Address
              </h4>
              <div class="contact_link_box">
                <a href="#a">
                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                  <span>
                   Square 21
                  </span>
                </a>
 
              </div>

              <h4>
                Contacts
              </h4>

              <div class="contact_link_box">
               
                <a href="#a">
                  <i class="fa fa-phone" aria-hidden="true"></i>
                  <span>
                   +39 351 886 8016
                  </span>           
                </a>
                <a href="#a">
                  <i class="fa fa-phone" aria-hidden="true"></i>
                  <span>
                   +355 69 35 91 564
                  </span>           
                </a>
                <a href="/contact-us">
                  <i class="fa fa-envelope" aria-hidden="true"></i>
                  <span>
                    info@calm-coders.com
                  </span>
                </a>
          
              </div>
            </div>

          </div>
          <div class="col-md-5 col-lg-4 info_col">
            <div class="info_detail">
              <h4>
                Info
              </h4>
              <p style={{width:"250px"}}>
              Calm Coders team provides quality web design & development services.
                </p>
         
            </div>
          </div>
          <div class="col-md-2 col-lg-4 mx-auto info_col">
            <div class="info_link_box">
              <h4>
                Links
              </h4>
              <div class="info_links">
                <a className="nav-link" href="#home">
                  Home
                </a>
                <a className="nav-link" href="#distinction">
                  Distinction 
                </a>
                <a className="nav-link" href="#story">
                   Story
                </a>
                <a className="nav-link" href="#projects">
                   Projects 
                </a>
                <a className="nav-link" href="#methodology"> 
                   Methodology
                </a>
              </div>
            </div>
          </div>

        </div>
      </div>
      </div>
      
      


    <div class="footer_section_web">
      <div class="container">
        <p>
          &copy; All Rights Reserved By Calm Coders
        </p>
      </div>
    </div>



</body>

  );
}

