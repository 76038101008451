import React from "react";
import './TeamTransition.css';

export default function TeamTransition({name, surname}) {
  
  return (

<div className="titleTeam">
    <h1 className="text1Team"> {name} </h1>
    <h1 className="text2Team"> {surname} </h1>
   </div>

  );

};
