import React, { Suspense, lazy, useEffect, useRef, useState } from 'react';
import "./TeamPage.css";
import { Canvas } from '@react-three/fiber';
import { Environment, useCursor } from '@react-three/drei';
import ScrollMagic from 'scrollmagic';
import TeamTransition from './TeamTransition';

const Aurel3D = lazy(() => import("../components/teamScene/Aureli"));
const Sara3D = lazy(() => import("../components/teamScene/Sara"));
const Sajmir3D = lazy(() => import("../components/teamScene/Sajmiri"));
const Rexhina3D = lazy(() => import("../components/teamScene/Rexhina"));
const Stiven3D = lazy(() => import("../components/teamScene/Stiven"));

const TextSection = lazy(() => import("../components/TextSection"));

export default function SalesforceTeamPage() {

  const [hovered, setHovered] = useState(null);
  useCursor(hovered);
  const [isMobileView, setIsMobileView] = useState(false);
  const [isDesktopView, setIsDesktopView] = useState(false);

  useEffect(() => {
    function checkScreenSize() {
      setIsMobileView(window.innerWidth <= 500); 
      setIsDesktopView(window.innerWidth > 500 && window.innerWidth <= 1680 ); 
    }

    checkScreenSize(); 

    window.addEventListener('resize', checkScreenSize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);


  const aurelRef = useRef(null);
  const saraRef = useRef(null);
  const sajmirRef = useRef(null);
  const rexhinaRef = useRef(null);
  const stivenRef = useRef(null);

  const [renderAurelModel, setRenderAurelModel] = useState(false);
  const [renderSaraModel, setRenderSaraModel] = useState(false);
  const [renderSajmirModel, setRenderSajmirModel] = useState(false);
  const [renderRexhinaModel, setRenderRexhinaModel] = useState(false);
  const [renderStivenModel, setRenderStivenModel] = useState(false);


  const [aurelSuspenseFinished, setAurelSuspenseFinished] = useState(0);
  const [saraSuspenseFinished, setSaraSuspenseFinished] = useState(0);
  const [sajmirSuspenseFinished, setSajmirSuspenseFinished] = useState(0);
  const [rexhinaSuspenseFinished, setRexhinaSuspenseFinished] = useState(0);
  const [stivenSuspenseFinished, setStivenSuspenseFinished] = useState(0);



  useEffect(() => {

          const controller = new ScrollMagic.Controller();
          
          new ScrollMagic.Scene({
          duration: 1000,
          triggerElement: aurelRef.current,
          triggerHook: 0,
          })
          .setPin(aurelRef.current)
          .addTo(controller)
          .on('enter', () => {    
          setRenderAurelModel(true)
          setRenderSaraModel(false)
          })




          new ScrollMagic.Scene({
          duration: 1000,
          triggerElement: saraRef.current,
          triggerHook: 0,
          })
          .setPin(saraRef.current)
          .addTo(controller)
          .on('enter', () => {    
          setRenderAurelModel(false)
          setRenderSaraModel(true)
          setRenderSajmirModel(false)
          })


          new ScrollMagic.Scene({
          duration: 1000,
          triggerElement: sajmirRef.current,
          triggerHook: 0,
          })
          .setPin(sajmirRef.current)
          .addTo(controller)
          .on('enter', () => {    
          setRenderSaraModel(false)
          setRenderSajmirModel(true)
          setRenderRexhinaModel(false)
          })


          new ScrollMagic.Scene({
          duration: 1000,
          triggerElement: rexhinaRef.current,
          triggerHook: 0,
          })
          .setPin(rexhinaRef.current)
          .addTo(controller)
          .on('enter', () => {    
          setRenderSajmirModel(false)
          setRenderRexhinaModel(true)
          setRenderStivenModel(false)
          })

          new ScrollMagic.Scene({
            duration: 1000,
            triggerElement: stivenRef.current,
            triggerHook: 0,
            })
            .setPin(stivenRef.current)
            .addTo(controller)
            .on('enter', () => {    
            setRenderRexhinaModel(false)
            setRenderStivenModel(true)
            })
  

    
  }, []);
 


   
  useEffect(()=> {


    if (renderAurelModel && aurelSuspenseFinished === 100){
      
      if(isMobileView){
        setTimeout(() => {
          document.documentElement.style.overflowY = "visible";
          }, 500 );
  
        document.body.style.overflowY = ""
      }

      else{
        setTimeout(() => {
          document.body.style.overflowY = "visible"
          }, 300 );
        document.documentElement.style.overflowY=""
      }
  
    }
    
    else{
  
      if(isMobileView){
        document.documentElement.style.overflowY = "hidden";
        document.body.style.overflowY = ""
      }
      else{
        document.documentElement.style.overflowY=""
        document.body.style.overflowY = "hidden"
      }

    }
  
  
  },[renderAurelModel,aurelSuspenseFinished,isMobileView])

  useEffect(() => {

    if((document.body.style.overflowY==="hidden" || document.documentElement.style.overflowY === "hidden") && renderAurelModel && aurelSuspenseFinished === 0){
      setTimeout(() => {
        setAurelSuspenseFinished(100)
      }, isMobileView ? 300 : 200);
    }
  },[renderAurelModel,aurelSuspenseFinished,isMobileView])



  useEffect(()=> {


    if (renderSaraModel && saraSuspenseFinished === 100){
      
      if(isMobileView){
        setTimeout(() => {
          document.documentElement.style.overflowY = "visible";
          }, 500 );
  
        document.body.style.overflowY = ""
      }

      else{
        setTimeout(() => {
          document.body.style.overflowY = "visible"
          }, 300 );
        document.documentElement.style.overflowY=""
      }
  
    }
    
    else{
  
      if(isMobileView){
        document.documentElement.style.overflowY = "hidden";
        document.body.style.overflowY = ""
      }
      else{
        document.documentElement.style.overflowY=""
        document.body.style.overflowY = "hidden"
      }
    }
  
  
  },[renderSaraModel,saraSuspenseFinished,isMobileView])

  useEffect(() => {

    if((document.body.style.overflowY==="hidden" || document.documentElement.style.overflowY === "hidden") && renderSaraModel && saraSuspenseFinished === 0){
      setTimeout(() => {
        setSaraSuspenseFinished(100)
      }, isMobileView ? 300 : 200);
    }
  },[renderSaraModel,saraSuspenseFinished,isMobileView])



  useEffect(()=> {


    if (renderSajmirModel && sajmirSuspenseFinished === 100){
      
      if(isMobileView){
        setTimeout(() => {
          document.documentElement.style.overflowY = "visible";
          }, 500 );
  
        document.body.style.overflowY = ""
      }

      else{
        setTimeout(() => {
          document.body.style.overflowY = "visible"
          }, 300 );
        document.documentElement.style.overflowY=""
      }

      
    }
    
    else{
  
      if(isMobileView){
        document.documentElement.style.overflowY = "hidden";
        document.body.style.overflowY = ""
      }
      else{
        document.documentElement.style.overflowY=""
        document.body.style.overflowY = "hidden"
      }

    }
  
  
  },[renderSajmirModel,sajmirSuspenseFinished,isMobileView])

  useEffect(() => {

    if((document.body.style.overflowY==="hidden" || document.documentElement.style.overflowY === "hidden") && renderSajmirModel && sajmirSuspenseFinished === 0){
      setTimeout(() => {
        setSajmirSuspenseFinished(100)
      }, isMobileView ? 300 : 200);
    }
  },[renderSajmirModel,sajmirSuspenseFinished,isMobileView])



  useEffect(()=> {


    if (renderRexhinaModel && rexhinaSuspenseFinished === 100){
      
      if(isMobileView){
        setTimeout(() => {
          document.documentElement.style.overflowY = "visible";
          }, 500 );
  
        document.body.style.overflowY = ""
      }

      else{
        setTimeout(() => {
          document.body.style.overflowY = "visible"
          }, 300 );
        document.documentElement.style.overflowY=""
      }
    }
    
    else{
  
      if(isMobileView){
        document.documentElement.style.overflowY = "hidden";
        document.body.style.overflowY = ""
      }
      else{
        document.documentElement.style.overflowY=""
        document.body.style.overflowY = "hidden"
      }
    }
  
  
  },[renderRexhinaModel,rexhinaSuspenseFinished,isMobileView])

  useEffect(() => {

    if((document.body.style.overflowY==="hidden" || document.documentElement.style.overflowY === "hidden") && renderRexhinaModel && rexhinaSuspenseFinished === 0){
      setTimeout(() => {
        setRexhinaSuspenseFinished(100)
      }, isMobileView ? 300 : 200);
    }
  },[renderRexhinaModel,rexhinaSuspenseFinished,isMobileView])

  
  useEffect(()=> {


    if (renderStivenModel && stivenSuspenseFinished === 100){
      
      if(isMobileView){
        setTimeout(() => {
          document.documentElement.style.overflowY = "visible";
          }, 500 );
  
        document.body.style.overflowY = ""
      }

      else{
        setTimeout(() => {
          document.body.style.overflowY = "visible"
          }, 300 );
        document.documentElement.style.overflowY=""
      }
    }
    
    else{
  
      if(isMobileView){
        document.documentElement.style.overflowY = "hidden";
        document.body.style.overflowY = ""
      }
      else{
        document.documentElement.style.overflowY=""
        document.body.style.overflowY = "hidden"
      }
    }
  
  
  },[renderStivenModel,stivenSuspenseFinished,isMobileView])

  useEffect(() => {

    if((document.body.style.overflowY==="hidden" || document.documentElement.style.overflowY === "hidden") && renderStivenModel && stivenSuspenseFinished === 0){
      setTimeout(() => {
        setStivenSuspenseFinished(100)
      }, isMobileView ? 300 : 200);
    }
  }, [renderStivenModel, stivenSuspenseFinished, isMobileView])
  


  return (
    
    <div>

    <div ref={aurelRef} style={{ width: '100%', height: '100vh', backgroundColor: "#999999"}}>
    {renderAurelModel &&
    <Suspense fallback={<TeamTransition  name={"Aurel"} surname= {"Mrruku"}/>}> 
    <Canvas>
    <ambientLight intensity={0.5}/>
    <Environment preset="sunset"/>
    <Aurel3D scale={3} position={isMobileView ? [-0.2,-4.05,-2.4] : isDesktopView ? [3.8,-2.5,-3] : [4.5,-2.5,-3]} rotation={isMobileView ? [0.22,0.6,-0.03] : [0.3,0.15,0.1]} hoverd={hovered ==="Aurel"}/>      
    <TextSection title={"CEO & SALESFORCE ARCHITECT"} 
    position={isMobileView ? [-1.05,3.3,0.1] : isDesktopView ? [-3.5,2,0] : [-5,2,0]} 
    fontSize={isMobileView ? 0.15 : 0.2} 
    maxWidth={isMobileView ? 2.4 : 4} 
    color={"black"} 
    fillOpacity={3} />
    <TextSection title={isMobileView 
    ? 
    "Meet Aurel Mrurku                                    The CEO and Salesforce Developer powerhouse at Cloud Solutions Inc. With over a decade of industry experience, Aurel's leadership drives our company's success.     As a skilled Salesforce Developer, he combines technical prowess with strategic vision to deliver innovative solutions that propel businesses forward. With Aurel leading the way Cloud Solutions Inc. sets the standard for excellence in the Salesforce ecosystem" 
    : 
    "Meet Aurel Mrurku                                        The CEO and Salesforce Developer powerhouse at Cloud Solutions Inc       With over a decade of industry experience, Aurel's leadership drives our company's success.   As a skilled Salesforce Developer, he combines technical prowess with strategic vision to deliver innovative solutions that propel businesses forward. With Aurel leading the way, Cloud Solutions Inc. sets the standard for excellence in the Salesforce ecosystem."} 
    position={isMobileView ? [-1.05,3.05,0.1] : isDesktopView ? [-3.5,1.5,0] : [-5,1.5,0]} 
    rotation={[0,-0.01,0]} 
    fontSize={isMobileView ? 0.12 : 0.2} 
    maxWidth={isMobileView ? 2.45: 4} 
    color={"black"} 
    fillOpacity={3} 
    onPointerEnter={() => setHovered("Aurel")}  
    onPointerLeave={() => setHovered(null)}/>
    <TextSection title={"Scroll to see more"} 
    position={isMobileView ? [-1.7, 0.9, 0] : isDesktopView ? [-4.8, 0.5, 0] :  [-7.5, 0.9, 0]}
    fontSize={isMobileView ? 0.13 : 0.2} 
    maxWidth={isMobileView ? 0.1 : 1} 
    color={"black"} 
    fillOpacity={3} /> 
    </Canvas>
    </Suspense>
    }
    </div>

    <div ref={saraRef}  style={{ width: '100%', height: '100vh', backgroundColor: "#563e35"}}>
    {renderSaraModel &&
    <Suspense fallback={<TeamTransition  name={"Sara"} surname={"Aga"}/>}> 
    <Canvas>
    <ambientLight intensity={0.5}/>
    <Environment preset="sunset"/>
    <Sara3D scale={3} position={isMobileView ? [0.05,-4.2,-2.5] : isDesktopView ? [-3.2,-2.5,-3] : [-4.5,-2.5,-3]} rotation={isMobileView ? [0.16,0,0.012] : isDesktopView ? [0.3,0.3,-0.1] : [0.3,0.4,-0.13]} hoverd={hovered ==="Sara"}/>      
    <TextSection title={"Salesforce Developer"} 
    position={isMobileView ? [-0.8,3,0.1] : [0,1.7,0]} 
    fontSize={isMobileView ? 0.148 : 0.2} 
    maxWidth={isMobileView ? 3.5 : 4} 
    color={"#221915"} 
    fillOpacity={3} />
    <TextSection title={isMobileView ? "Hey there, I'm Sara                                             A Salesforce developer who thrives                 on creating innovative solutions                     With solid knowledge in Apex and   a knack for Lightning Web Components, I navigate projects with agility, leveraing the power of Salesforce. In the world of development. I find joy in problem-solving and crafting solutions that drive business success" : "Hey there, I'm Sara                                          A Salesforce developer who thrives on creating innovative solutions. With solid knowledge in Apex and a knack for Lightning Web Components, I navigate projects with agility, leveraing the power of Salesforce. In the world of development, I find joy in problem-solving and crafting solutions that drive business success"} 
    position={isMobileView ? [-1.05,2.7,0.1] : [0,1.2,0]} 
    fontSize={isMobileView ? 0.125 : 0.2} 
    maxWidth={isMobileView ? 2.6 : 4}
    color={"#221915"} 
    fillOpacity={3} 
    onPointerEnter={() => setHovered("Sara")}  
    onPointerLeave={() => setHovered(null)}/>
    <TextSection title={"Scroll to see more"} 
    position={isMobileView ? [1.1,0,0.1] : isDesktopView ? [4,0.9,0] : [6.6,0.9,0]} 
    fontSize={isMobileView ? 0.13 : 0.2} 
    maxWidth={isMobileView ? 0.1 : 1} 
    color={"#221915"} 
    fillOpacity={3} /> 
    </Canvas>
    </Suspense>
    }
    </div>

    <div ref={sajmirRef} style={{ width: '100%', height: '100vh', backgroundColor: "#89888d"}}>
    {renderSajmirModel &&
    <Suspense fallback={<TeamTransition  name={"Sajmir"} surname={"Hallaçi"}/>}> 
    <Canvas>
    <ambientLight intensity={0.5}/>
    <Environment preset="sunset"/>
    <Sajmir3D scale={3.2} position={isMobileView ? (hovered ==="Sajmir" ? [16.7,-3.3,-0.8] : [13.1,-4.9,-0.8] )  : isDesktopView ? (hovered ==="Sajmir" ? [18.76,-3.1,4] : [16.5,-3,2.2]) :  (hovered ==="Sajmir" ? [18,-3,2.2] : [18,-3,2.2])} rotation={isMobileView ? (hovered ==="Sajmir" ? [-0.05,2.9,-0.03] : [0.2,2.8,-0.05]) : (hovered ==="Sajmir" ? [0.3,2.5,-0.09] : [0.3,2.5,-0.15])} hoverd={hovered ==="Sajmir"}/>      
    <TextSection title={"Salesforce Admin / Business Analyst"} 
    position={isMobileView ? [-1.15,2.95,0.1] : isDesktopView ? [-3.5,1.8,0] : [-4.5,1.8,0]} 
    fontSize={isMobileView ? 0.13 : 0.2} 
    maxWidth={isMobileView ? 3.5 : isDesktopView ? 4.2 : 4} 
    color={"#265a85"} 
    fillOpacity={3} />
    <TextSection title={isMobileView ? "Hey, I'm Sajmir                                                  As a Salesforce Admin, Business Analyst and Scrum Master      I’m here to streamline your business operations and drive innovation. From solving challenges with custom Salesforce solutions to leading agile teams, I ensure smooth processes and clear communication in both English and French. Let’s turn your vision into reality" : "Hey, I'm Sajmir                                                As a Salesforce Admin, Business Analyst and Scrum Master       I’m here to streamline your business operations and drive innovation. From solving challenges with custom Salesforce solutions to leading agile teams, I ensure smooth processes and clear communication in both English and French.            Let’s turn your vision        into reality"} 
    position={isMobileView ? [-1.1,2.75,0.1] : isDesktopView ? [-3.5,1.3,0] : [-4.5,1.3,0]} 
    fontSize={isMobileView ? 0.125 : 0.2} 
    maxWidth={isMobileView ? 2.4 : 4}
    color={"#265a85"} 
    fillOpacity={3} 
    onPointerEnter={() => setHovered("Sajmir")}  
    onPointerLeave={() => setHovered(null)}/>
    <TextSection title={"Scroll to see more"} 
    position={isMobileView ? [-1.55,0.4,0.1] : isDesktopView ? [-4.7,0.5,0] : [-7.4,0.9,0]} 
    fontSize={isMobileView ? 0.13 : 0.2} 
    maxWidth={isMobileView ? 0.1 : 1} 
    color={"#265a85"} 
    fillOpacity={3} /> 
    </Canvas>
    </Suspense>
    }
    </div>

    <div ref={rexhinaRef} style={{ width: '100%', height: '100vh', backgroundColor: "#5c5050" }}>
    {renderRexhinaModel &&
    <Suspense fallback={<TeamTransition  name={"Rexhina"} surname={"Hysi"}/>}> 
    <Canvas>
    <ambientLight intensity={0.5}/>
    <Environment preset="sunset"/>
    <Rexhina3D scale={3} position={isMobileView ? [0.15,-4.2,-2] : isDesktopView ? [-3.7,-2.5,-3] : [-5.5,-2.5,-3]} rotation={isMobileView ? [0.16,0,0.012] : isDesktopView ? [0.35,0.55,-0.13] : [0.35,0.68,-0.13]} hoverd={hovered ==="Rexhina"}/>      
    <TextSection title={"Salesforce Developer"} 
    position={isMobileView ? [-0.8,2.95,0.1] : [0,1.85,0]} 
    fontSize={isMobileView ? 0.148 : 0.2} 
    maxWidth={isMobileView ? 3.5 : 4} 
    color={"#dcdcdd"} 
    fillOpacity={3} />
    <TextSection title={isMobileView ? "Hello, I'm Rexhina                                                     My interest in technology began with a curiosity for problem-solving and it led me to discover and dive into Salesforce. With each click and code, I've embaraced the artistry of administration and the intricacies of Apex development.                    As a seasoned administrator,        I orchestrate the seamless flow     of operations, ensuring every process hums with efficiency" : "Hello, I'm Rexhina                                           My interest in technology began with a curiosity for problem-solving and it led me to discover and dive into Salesforce          With each click and code, I've embaraced the artistry of administration and the intricacies  of Apex development.                As a seasoned administrator, I orchestrate the seamless flow of operations, ensuring every process hums with efficiency"} 
    position={isMobileView ? [-1.1,2.65,0.1] : [0,1.4,0]} 
    fontSize={isMobileView ? 0.12 : 0.2} 
    maxWidth={isMobileView ? 2.6 : 4.1}
    color={"#dcdcdd"} 
    fillOpacity={3} 
    onPointerEnter={() => setHovered("Rexhina")}  
    onPointerLeave={() => setHovered(null)} />
    <TextSection title={"Scroll to see more"} 
    position={isMobileView ? [1.1,0,0.1] : isDesktopView ? [4,0.9,0] : [6.6,0.9,0]} 
    fontSize={isMobileView ? 0.13 : 0.2} 
    maxWidth={isMobileView ? 0.1 : 1} 
    color={"#dcdcdd"} 
    fillOpacity={3} /> 
    </Canvas>
    </Suspense>
    }
    </div>

    <div ref={stivenRef} style={{ width: '100%', height: '100vh', backgroundColor: "#79a7e1"}}>
    {renderStivenModel &&
    <Suspense fallback={<TeamTransition  name={"Stiven"} surname={"Qehaja"}/>}> 
    <Canvas>
    <ambientLight intensity={0.5}/>
    <Environment preset="sunset"/>
    <Stiven3D scale={3} position={isMobileView ? [0.15,-4.2,-2] : isDesktopView ? [-3.7,-2.5,-3] : [-5.5,-2.5,-3]} rotation={isMobileView ? [0.16,0,0.012] : isDesktopView ? [0.35,0.55,-0.13] : [0.35,0.68,-0.13]} hoverd={hovered ==="Stiven"}/>      
    <TextSection title={"Salesforce Developer"} 
    position={isMobileView ? [-0.8,3,0.1] : [0,1.7,0]} 
    fontSize={isMobileView ? 0.148 : 0.2} 
    maxWidth={isMobileView ? 3.5 : 4} 
    color={"#201917"} 
    fillOpacity={3} />
    <TextSection title={isMobileView
    ? "Hey, I'm Stiven                   A Salesforce developer specializing in Apex programming and Lightning Web Components.      I pride myself on being a quick learner and my ability to quickly adapt to new environments and challenges. Efficiency is at the core of my work. Facing everyday challenges with excitement and competitiveness, always striving to deliver the best solutions."
    : "Hey, I'm Stiven                  A Salesforce developer specializing in Apex programming and Lightning Web Components.    I pride myself on being a quick learner and my ability to quickly adapt to new environments and challenges. Efficiency is at the core of my work. Facing everyday challenges with excitement and competitiveness, always striving to deliver the best solutions."} 
    position={isMobileView ? [-1.05,2.7,0.1] : [0,1.2,0]} 
    fontSize={isMobileView ? 0.125 : 0.2} 
    maxWidth={isMobileView ? 2.6 : 4}
    color={"#201917"} 
    fillOpacity={3} 
    onPointerEnter={() => setHovered("Stiven")}  
    onPointerLeave={() => setHovered(null)}/>
    </Canvas>
    </Suspense>
    }
    </div>

    </div>

  );
}
