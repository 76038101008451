import { Background } from "./Background";
import { Float, PerspectiveCamera } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { gsap } from "gsap";
import { lazy, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import * as THREE from "three";
import { Euler, Group, Vector3 } from "three";
import { usePlay } from "./Play";
import { fadeOnBeforeCompile } from "./fadeMaterial";
import { Speed } from "./Speed";
import  TextSection  from "./TextSection";
//import { Perf } from "r3f-perf";

const CloudSculpt = lazy(() => import('./CloudSculpt'));
const PhoenixObject = lazy(() => import('./Phoenix'));


const LINE_NB_POINTS = 1200;
const CURVE_AHEAD_PHOENIX = 0.02;
const PHOENIX_MAX_ANGLE = 20;
const FRICTION_DISTANCE = 12;


export const HeaderLogo = () => {

  const { play,setPlay, setHasScroll, end, setEnd } = usePlay();
  const [previousScrollPosition, setPreviousScrollPosition] = useState(0);
  const [isMobileView, setIsMobileView] = useState(false);
  const CURVE_DISTANCE = isMobileView ? 80 : 120;
  const CURVE_AHEAD_CAMERA = isMobileView ? 0.0002 : 0.013;
  const phoenix = useRef();
  const tl = useRef();
  const phoenixInTl = useRef();
  const phoenixOutTl = useRef();
  const backgroundColors = useRef({
    colorA: "#3535cc",
    colorB: "#69d4ff",
  });
  const sceneOpacity = useRef(0);
  const lineMaterialRef = useRef();
  const cameraGroup = useRef();
  const cameraRail = useRef();
  const camera = useRef(); 
  const lastScroll = useRef(0);

  const curvePoints = useMemo(
    () => [
      new THREE.Vector3(0, 0, CURVE_DISTANCE/4),
      new THREE.Vector3(0, 0, -CURVE_DISTANCE),
      new THREE.Vector3(100, 0, -2 * CURVE_DISTANCE),
      new THREE.Vector3(-100, 0, -3 * CURVE_DISTANCE),
      new THREE.Vector3(100, 0, -4 * CURVE_DISTANCE),
      new THREE.Vector3(0, 0, -5 * CURVE_DISTANCE),
      new THREE.Vector3(0, 0, -6 * CURVE_DISTANCE),
      new THREE.Vector3(0, 0, -7 * CURVE_DISTANCE),
    ],
    [CURVE_DISTANCE]
  );

  const curve = useMemo(() => {
    return new THREE.CatmullRomCurve3(curvePoints, false, "catmullrom", 0.5);
  }, [curvePoints]);

  const textSections = useMemo(() => {
    const aspectRatio = window.innerWidth / window.innerHeight;
    return [
      {

        cameraRailDist: aspectRatio > 1 ? -1 : -1 ,
        position: new Vector3(
          aspectRatio > 1 ? curvePoints[1].x - 3.2 : curvePoints[1].x  - 1.17 ,
          aspectRatio > 1 ?  curvePoints[1].y - 0.35 :  curvePoints[1].y + 1.2,
          aspectRatio > 1 ? curvePoints[1].z : curvePoints[1].z - 2.5             
        ),
        rotation: new Euler(0,-0.47,0),
        title: `Welcome to Calm Coders !`,
        colorTitle: "orange",   
        colorOutline: "#8b4319", 
        fontSizeTitle: aspectRatio > 1 ? 0.25 : 0.37,
        maxWidthTitle: aspectRatio > 1 ? 1.8 : 5.5,
        
      },

      {
        cameraRailDist: aspectRatio > 1 ? 1 : 0.8,
        position: new Vector3(
          aspectRatio > 1 ? curvePoints[2].x + 1.4 : curvePoints[2].x - 2.6 ,
          aspectRatio > 1 ? curvePoints[2].y - 0.95 : curvePoints[2].y + 0.3 ,
          aspectRatio > 1 ? curvePoints[2].z : curvePoints[2].z - 2.45
        ),
        title: aspectRatio > 1 ? "You have power    over your mind       not outside events Realize this and you will find strength" : "You have power over your mind not outside events Realize this and you will find strength",
        colorTitle: "#e14572",       
        colorOutline: "#581327", 
        fontSizeTitle: aspectRatio > 1 ? 0.25 : 0.17,      
        maxWidthTitle: aspectRatio > 1 ? 2.41 : 1.7,
        lineHeightTitle: 1.1,
        rotation:  aspectRatio > 1 ? new Euler(0,0.5,0) : new Euler(0,0.8,0)
      },
      

      {
        cameraRailDist: aspectRatio > 1 ? -3 : -1.3,
        position: new Vector3(
          aspectRatio > 1 ?  curvePoints[3].x - 5.4 :  curvePoints[3].x - 0.83,
          aspectRatio > 1 ?  curvePoints[3].y - 1.1 :  curvePoints[3].y + 0.3,
          aspectRatio > 1 ? curvePoints[3].z -1 : curvePoints[3].z + 0.2
        ),
        title: aspectRatio > 1 ? "Programming isn't about what you know it's about what you   can figure out" :  "Programming isn't about what you know,  it's about what you can figure out",
        colorTitle: "#43b98a",    
        colorOutline: "#1a4a37",    
        fontSizeTitle: aspectRatio > 1 ? 0.34 : 0.1,
        maxWidthTitle: aspectRatio > 1 ? 3.43 : 1.2,
        lineHeightTitle: 1.2,
        rotation:  aspectRatio > 1 ? new Euler(0,0.3,0) : new Euler(0,-0.15,0)
      },

      {
        cameraRailDist: aspectRatio > 1 ? 1.5 : 0.9,
        position: new Vector3(
          aspectRatio > 1 ? curvePoints[4].x + 3.2 : curvePoints[4].x - 0.8 ,
          aspectRatio > 1 ? curvePoints[4].y - 1.1 : curvePoints[4].y + 0.2 ,
          aspectRatio > 1 ? curvePoints[4].z - 5 : curvePoints[4].z + 0.5 
        ), 
        title: aspectRatio > 1 ? "Persistence can        change failure into extraordinary achievement" :   "Persistence can    change failure into extraordinary achievement",
        colorTitle: "#fb8438",
        colorOutline: "#321401",
        fontSizeTitle: aspectRatio > 1 ? 0.31 : 0.18,
        maxWidthTitle: aspectRatio > 1 ? 3.5 : 2,
        lineHeightTitle: 1.2,
        rotation:  aspectRatio > 1 ? new Euler(0,-0.2,0) : new Euler(0,-0.6,0)
      },

      

      {
        cameraRailDist: aspectRatio > 1 ? 1.5 : 0.5,
        position: new Vector3(
          aspectRatio > 1 ? curvePoints[5].x + 2.7 : curvePoints[5].x + 0.7,
          aspectRatio > 1 ? curvePoints[5].y - 0.6 : curvePoints[5].y + 0.25,
          aspectRatio > 1 ? curvePoints[5].z + 3 : curvePoints[5].z + 2.2
        ),
        title: aspectRatio > 1 ? "Software and temples    are much the same          first we build them         then we pray" : "Software and temples   are much the same         first we build them        then we pray",
        colorTitle: "#9a0f35",       
        colorOutline: "#e8bd66",
        fontSizeTitle: aspectRatio > 1 ? 0.148 : 0.2,
        maxWidthTitle: aspectRatio > 1 ? 1.49 : 2.1,
        lineHeightTitle: 1.2,
        rotation: aspectRatio > 1 ? new Euler(0,0.4,0) : new Euler(0,0.7,0)
      },

     

      {
        cameraRailDist: aspectRatio > 1 ? 1.5 : 0.55,
        position: new Vector3(
          aspectRatio > 1 ? curvePoints[6].x + 1 : curvePoints[6].x - 4.26 ,
          aspectRatio > 1 ? curvePoints[6].y - 1.3 : curvePoints[6].y  ,
          aspectRatio > 1 ? curvePoints[6].z + 20 : curvePoints[6].z + 26 
        ),
        title: aspectRatio > 1 ? "In the face of ambiguity refuse the temptation      to guess" : "In the face of ambiguity refuse the temptation to guess",
        colorTitle: "white",
        colorOutline: "black",
        fontSizeTitle: aspectRatio > 1 ? 0.4 : 0.2,          
        maxWidthTitle:  aspectRatio > 1 ? 5 : 2.2,
        lineHeightTitle: 1.2,
        rotation: aspectRatio > 1 ? new Euler(0,0,0) : new Euler(0,-0.1,0)
      },

    ];
  }, [curvePoints]);

  const clouds = useMemo(() => [
      // STARTING
      {
        scale: new Vector3(0.5, 0.5, 0.5),
        position: new Vector3(-6, -4.5, -7),
      },
      {
        scale: new Vector3(0.5, 0.5, 0.5),
        position: new Vector3(3.5, -4, -10),
      },
      {
        scale: new Vector3(1, 1, 1),
        position: new Vector3(-27, 12, -68),
        rotation: new Euler(-Math.PI / 5, Math.PI / 6, 0),
      },
      {
        scale: new Vector3(1, 1, 1),
        position: new Vector3(-18, 1, -68),
        rotation: new Euler(-Math.PI / 5, Math.PI / 6, 0),
      },
      {
        scale: new Vector3(1, 1, 1),
        position: new Vector3(19, 10, -60),
        rotation: new Euler(-Math.PI / 5, Math.PI / 6, 0),
      },
      {
        scale: new Vector3(1, 1, 1),
        position: new Vector3(12, -2, -65),
        rotation: new Euler(-Math.PI / 5, Math.PI / 6, 0),
        
      },
        // FIRST POINT
      {
        scale: new Vector3(1, 1, 1),
        position: new Vector3(
          curvePoints[1].x + 15,
          curvePoints[1].y + 2,
          curvePoints[1].z + 74
        ),
      },
      {
        scale: new Vector3(2, 2, 2),
        position: new Vector3(
          curvePoints[1].x - 70,
          curvePoints[1].y - 20,
          curvePoints[1].z + 52
        ),
        rotation: new Euler(0, Math.PI / 7, 0),
      },
      {
        scale: new Vector3(1.5, 1.5, 1.5),
        position: new Vector3(
          curvePoints[1].x - 35,
          curvePoints[1].y + 16,
          curvePoints[1].z + 28
        ),
        rotation: new Euler(0, Math.PI / 7, 0),
      },
      {
        scale: new Vector3(1.7, 1.7, 1.7),
        position: new Vector3(
          curvePoints[1].x - 85,
          curvePoints[1].y + 30,
          curvePoints[1].z + 40
        ),
        rotation: new Euler(0, Math.PI / 7, 0),
      },
      {
        rotation: new Euler(0, Math.PI / 8, Math.PI *2),
        scale: new Vector3(1.5, 1.5, 1.5),
        position: new Vector3(
          curvePoints[1].x + 30,
          curvePoints[1].y + 50,
          curvePoints[1].z - 52
        ),
      },
      {
        rotation: new Euler(Math.PI / 2, Math.PI , Math.PI / 2),
        scale: new Vector3(1.5, 1.5, 1.5),
        position: new Vector3(
          curvePoints[1].x + 110,
          curvePoints[1].y + 50,
          curvePoints[1].z - 60
        ),
      },
      {
        scale: new Vector3(1.3, 1.3, 1.3),
        position: new Vector3(
          curvePoints[1].x + 25,
          curvePoints[1].y - 50,
          curvePoints[1].z - 20
        ),
      },

      {
        scale: new Vector3(1.2, 1.2, 1.2),
        position: new Vector3(
          curvePoints[3].x + 40,
          curvePoints[3].y ,
          curvePoints[3].z + 100
        ),
      },
      {
        scale: new Vector3(2.5, 2.5, 2.5),
        position: new Vector3(
          curvePoints[3].x - 5,
          curvePoints[3].y + 50,
          curvePoints[3].z + 30
        ),
        rotation: new Euler(Math.PI / 4, 0, Math.PI / 5),
      },


      // SECOND POINT
      {
        scale: new Vector3(2, 2, 2),
        position: new Vector3(
          curvePoints[2].x + 50,
          curvePoints[2].y ,
          curvePoints[2].z + 50
        ),
      },
      {
        scale: new Vector3(2, 2, 2),
        position: new Vector3(
          curvePoints[2].x + 20,
          curvePoints[2].y - 55,
          curvePoints[2].z + 60
        ),
      },
      {
        scale: new Vector3(1.2, 1.2, 1.2),
        position: new Vector3(
          curvePoints[2].x + 12,
          curvePoints[2].y - 4,
          curvePoints[2].z - 26
        ),
      },
      {
        scale: new Vector3(1, 1, 1),
        position: new Vector3(
          curvePoints[2].x + 12,
          curvePoints[2].y + 1,
          curvePoints[2].z - 86
        ),
        rotation: new Euler(Math.PI / 4, 0, Math.PI / 3),
      },
      // THIRD POINT

      {
        scale: new Vector3(2, 2, 2),
        position: new Vector3(
          curvePoints[3].x - 150,
          curvePoints[3].y + 60,
          curvePoints[3].z - 8
        ),
        rotation: new Euler(Math.PI, 2, Math.PI ),
      },
      {
        scale: new Vector3(2.5, 2.5, 2.5),
        position: new Vector3(
          curvePoints[3].x - 200,
          curvePoints[3].y - 60,
          curvePoints[3].z - 8
        ),
        rotation: new Euler(Math.PI, 2, Math.PI ),
      },
      {
        scale: new Vector3(2.5, 2.5, 2.5),
        position: new Vector3(
          curvePoints[3].x - 260,
          curvePoints[3].y + 10,
          curvePoints[3].z + 20
        ),
        rotation: new Euler(0, Math.PI / 3, 0),
      },

      {
        scale: new Vector3(1.5, 1.5, 1.5),
        position: new Vector3(
          curvePoints[3].x - 10,
          curvePoints[3].y + 30,
          curvePoints[3].z - 8
        ),
        rotation: new Euler(Math.PI, 2, Math.PI ),
      },
      {
        scale: new Vector3(1.5, 1.5, 1.5),
        position: new Vector3(
          curvePoints[3].x + 5,
          curvePoints[3].y - 35,
          curvePoints[3].z - 8
        ),
        rotation: new Euler(Math.PI, 2, Math.PI ),
      },
      {
        scale: new Vector3(2, 2, 2),
        position: new Vector3(
          curvePoints[3].x + 2,
          curvePoints[3].y + 5,
          curvePoints[3].z - 98
        ),
        rotation: new Euler(0, Math.PI / 3, 0),
      },
     // FOURTH POINT
      {
        scale: new Vector3(1, 1, 1),
        position: new Vector3(
          curvePoints[4].x + 3,
          curvePoints[4].y - 20,
          curvePoints[4].z + 2
        ),
      },
      {
        scale: new Vector3(1, 1, 1),
        position: new Vector3(
          curvePoints[4].x + 24,
          curvePoints[4].y + 30,
          curvePoints[4].z - 42
        ),
        rotation: new Euler(Math.PI / 4, 0, Math.PI ),
      },
      {
        scale: new Vector3(1.2, 1.2, 1.2),
        position: new Vector3(
          curvePoints[4].x - 8,
          curvePoints[4].y + 10,
          curvePoints[4].z - 62
        ),
        rotation: new Euler(Math.PI / 3, 0, Math.PI / 3),
      },
      {
        scale: new Vector3(1.5, 1.5, 1.5),
        position: new Vector3(
          curvePoints[4].x + 200,
          curvePoints[4].y - 8 ,
          curvePoints[4].z - 62
        ),
        rotation: new Euler(Math.PI / 3, 0, Math.PI / 3),
      },
         {
        scale: new Vector3(2, 2, 2),
        position: new Vector3(
          curvePoints[4].x + 250,
          curvePoints[4].y - 50,
          curvePoints[4].z - 40
        ),
        rotation: new Euler(Math.PI / 4, 0, Math.PI ),
      },
      {
        scale: new Vector3(1.5, 1.5, 1.5),
        position: new Vector3(
          curvePoints[4].x + 120,
          curvePoints[4].y + 30,
          curvePoints[4].z - 42
        ),
        rotation: new Euler(Math.PI / 4, 0, Math.PI ),
      },
        // FINAL
      {
        scale: new Vector3(1.2, 1.2, 1.2),
        position: new Vector3(
          curvePoints[7].x + 50,
          curvePoints[7].y - 5,
          curvePoints[7].z + 60
        ),
        rotation: new Euler(-Math.PI / 4, -Math.PI / 6, 0),
      },
      {
        scale: new Vector3(1.2, 1.2, 1.2),
        position: new Vector3(
          curvePoints[7].x + 20,
          curvePoints[7].y + 35,
          curvePoints[7].z + 60
        ),
        rotation: new Euler(-Math.PI / 4, -Math.PI / 6, 0),
      },
      {
        scale: new Vector3(1.2, 1.2, 1.2),
        position: new Vector3(
          curvePoints[7].x + 80,
          curvePoints[7].y - 30,
          curvePoints[7].z + 60
        ),
        rotation: new Euler(-Math.PI / 4, -Math.PI / 6, 0),
      },
      {
        scale: new Vector3(1.2, 1.2, 1.2),
        position: new Vector3(
          curvePoints[7].x + 5,
          curvePoints[7].y - 40,
          curvePoints[7].z + 60
        ),
        rotation: new Euler(-Math.PI / 4, -Math.PI / 6, 0),
      },
      {
        scale: new Vector3(1, 1, 1),
        position: new Vector3(
          curvePoints[7].x - 40,
          curvePoints[7].y + 5,
          curvePoints[7].z + 120
        ),
        rotation: new Euler(Math.PI / 4, Math.PI / 6, 0),
      },
      {
        scale: new Vector3(1, 1, 1),
        position: new Vector3(
          curvePoints[7].x - 60,
          curvePoints[7].y + 25,
          curvePoints[7].z + 120
        ),
        rotation: new Euler(Math.PI / 4, Math.PI / 6, 0),
      },
      {
        scale: new Vector3(1, 1, 1),
        position: new Vector3(
          curvePoints[7].x - 60,
          curvePoints[7].y - 20,
          curvePoints[7].z + 120
        ),
        rotation: new Euler(Math.PI / 4, Math.PI / 6, 0),
      },
    ],
    [curvePoints]
  );

  const shape = useMemo(() => {
    const shape = new THREE.Shape();
    shape.moveTo(0, -0.08);
    shape.lineTo(0, 0.08);

    return shape;
  }, []);


  useFrame((_state, delta) => {

    if (window.innerWidth > window.innerHeight) {
      // LANDSCAPE
      camera.current.fov = 30;
      camera.current.position.z = 5;
    } 
    else {
      // PHONE
      camera.current.fov = 80;
      camera.current.position.z = 2;
    }

    if (lastScroll.current <= 0 && previousScrollPosition/10000 > 0) {
      setHasScroll(true);
    }

    if (play && !end && sceneOpacity.current < 1) {
      sceneOpacity.current = THREE.MathUtils.lerp(
        sceneOpacity.current,
        1,
        delta * 0.1
      );
    }

    if (end && sceneOpacity.current > 0) {
      sceneOpacity.current = THREE.MathUtils.lerp(
        sceneOpacity.current,
        0,
        delta
      );
    }

    lineMaterialRef.current.opacity = sceneOpacity.current;

    if (end) {
      return;
    }


    const scrollOffset = Math.max(0, previousScrollPosition/10000);
    let cameraPositionBeforeMove = cameraGroup.current.position.clone()
    let resetCameraRail = true;
    // LOOK TO CLOSE TEXT SECTIONS
    textSections.forEach((textSection) => {
      const distance = textSection.position.distanceTo( cameraGroup.current.position );
      const adjustedFrictionDistance = isMobileView ? FRICTION_DISTANCE * 0.7 : FRICTION_DISTANCE * 1.5;

      if (resetCameraRail) {
        const targetCameraRailPosition = new Vector3(0.1, 0, 1.5);
        cameraRail.current.position.lerp(targetCameraRailPosition, delta);
      }

      if (distance < adjustedFrictionDistance) {
        const targetCameraRailPosition = new Vector3((1 - distance / FRICTION_DISTANCE) * textSection.cameraRailDist, 0, 0);
        cameraRail.current.position.lerp(targetCameraRailPosition, delta);
        cameraGroup.current.position.copy(cameraPositionBeforeMove);
        resetCameraRail = false;
      }

          // CALCULATE LERPED SCROLL OFFSET
          let lerpedScrollOffset = THREE.MathUtils.lerp(
          lastScroll.current,
          scrollOffset,
          delta 
          );
          // PROTECT BELOW 0 AND ABOVE 1
          lerpedScrollOffset = Math.min(lerpedScrollOffset, 1);
          lerpedScrollOffset = Math.max(lerpedScrollOffset, 0);

          lastScroll.current = lerpedScrollOffset;
          tl.current.seek(lerpedScrollOffset * tl.current.duration());

          const curPoint = curve.getPoint(lerpedScrollOffset);

          // Follow the curve points
          cameraGroup.current.position.lerp(curPoint, delta * 24);

          // Make the group look ahead on the curve

          const lookAtPoint = curve.getPoint(
          Math.min(lerpedScrollOffset + CURVE_AHEAD_CAMERA, 1)
          );

          const currentLookAt = cameraGroup.current.getWorldDirection(
          new THREE.Vector3()
          );
          const targetLookAt = new THREE.Vector3()
          .subVectors(curPoint, lookAtPoint)
          .normalize();

          const lookAt = currentLookAt.lerp(targetLookAt, delta * 24);
          cameraGroup.current.lookAt(
          cameraGroup.current.position.clone().add(lookAt)
          );

          // Phoenix rotation

          const tangent = curve.getTangent(lerpedScrollOffset + CURVE_AHEAD_PHOENIX);

          const nonLerpLookAt = new Group();
          nonLerpLookAt.position.copy(curPoint);
          nonLerpLookAt.lookAt(nonLerpLookAt.position.clone().add(targetLookAt));

          tangent.applyAxisAngle(
          new THREE.Vector3(0, 1, 0),
          -nonLerpLookAt.rotation.y
          );

          let angle = Math.atan2(-tangent.z, tangent.x);
          angle = -Math.PI / 2 + angle;

          let angleDegrees = (angle * 180) / Math.PI;
          angleDegrees *= 2.4; // stronger angle

          // LIMIT PLANE ANGLE
          if (angleDegrees < 0) {
          angleDegrees = Math.max(angleDegrees, -PHOENIX_MAX_ANGLE);
          }
          if (angleDegrees > 0) {
          angleDegrees = Math.min(angleDegrees, PHOENIX_MAX_ANGLE);
          }

          // SET BACK ANGLE
          angle = (angleDegrees * Math.PI) / 180;

          const targetPhoenixQuaternion = new THREE.Quaternion().setFromEuler(
          new THREE.Euler(
            phoenix.current.rotation.x,
            phoenix.current.rotation.y,
            angle
          )
          );
          phoenix.current.quaternion.slerp(targetPhoenixQuaternion, delta * 2);


    });


    if ( cameraGroup.current.position.z < curvePoints[curvePoints.length - 1].z + 100 && play
    ) {
      setEnd(true);
      setPlay(false)
      phoenixOutTl.current.play();
    }
  });

  useEffect(() => {
    function checkScreenSize() {
      setIsMobileView(window.innerWidth <= 500); 
    }
  
    checkScreenSize(); 
  
    window.addEventListener('resize', checkScreenSize);
  
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  const handleScrollChange = useCallback(() => {
    const currentScrollPosition = window.scrollY;

    if (currentScrollPosition !== previousScrollPosition) {
      setPreviousScrollPosition(currentScrollPosition);
    }
  }, [previousScrollPosition, setPreviousScrollPosition]);

  useEffect(() => {
    if (play) {
      window.addEventListener('scroll', handleScrollChange);
      window.addEventListener('wheel', handleScrollChange);
      window.addEventListener('touchmove', handleScrollChange);
    }

    return () => {
      window.removeEventListener('scroll', handleScrollChange);
      window.removeEventListener('wheel', handleScrollChange);
      window.removeEventListener('touchmove', handleScrollChange);
    };
  }, [play, handleScrollChange]);

  useLayoutEffect(() => {

    tl.current = gsap.timeline();

    tl.current.to(backgroundColors.current, {
      duration: 1,
      colorA: "#6f35cc",
      colorB: "#ffad30",
    });
    tl.current.to(backgroundColors.current, {
      duration: 1,
      colorA: "#424242",
      colorB: "#ffcc00",
    });
    tl.current.to(backgroundColors.current, {
      duration: 0.6,
      colorA: "#480655",
      colorB: "#480655",
    });

    tl.current.pause();

    phoenixInTl.current = gsap.timeline();
    phoenixInTl.current.pause();
    phoenixInTl.current.from(phoenix.current.position, {
      duration: 3,
      z: 5,
      y: -2,
    });

    phoenixOutTl.current = gsap.timeline();
    phoenixOutTl.current.pause();

    phoenixOutTl.current.to(
      phoenix.current.position,
      {
        duration: 10,
        z: -250,
        y: 10,
      },
      0
    );
    phoenixOutTl.current.to(
      cameraRail.current.position,
      {
        duration: 8,
        y: 12,
      },
      0
    );
    phoenixOutTl.current.to(phoenix.current.position, {
      duration: 1,
      z: -1000,
    });
  }, []);

  useEffect(() => {
    if (play) {
      phoenixInTl.current.play();
    }
  }, [play]);
      


      
      return useMemo(
        () => (
        <>
        
          <directionalLight position={[0, 3, 1]} intensity={0.1} />

          <group ref={cameraGroup}>        
          <Speed />
          <Background backgroundColors={backgroundColors} />

          <group ref={cameraRail}>
              <PerspectiveCamera ref={camera} position={[0, 0, 5]} fov={30}  makeDefault />
          </group>

          <group ref={phoenix}>
              <Float floatIntensity={1} speed={1.5} rotationIntensity={0.5}>              
              <PhoenixObject rotation-y={Math.PI / 2} position-y={ isMobileView ? -1.3 : -0.8} />
              </Float>
          </group>

          </group>
          
          {/* TEXT */}
          {textSections.map((textSection, index) => (
            <TextSection {...textSection} key={index} />
          ))}
  
          {/* LINE */}
          <group position-y={-3}>
          <mesh visible={false}> 
          <extrudeGeometry args={[ shape, { steps: LINE_NB_POINTS, bevelEnabled: false, extrudePath: curve } ]} />
          <meshStandardMaterial ref={lineMaterialRef} color={null} transparent envMapIntensity={2} onBeforeCompile={fadeOnBeforeCompile} />
          </mesh>
          </group>
  
          {/* CLOUDS */}
          {clouds.map((cloud, index) => (
          <CloudSculpt sceneOpacity={0} {...cloud} key={index} />
          ))}
        </>
        ),
        [clouds,curve,shape,textSections,isMobileView]
      );
}