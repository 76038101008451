import React from "react";
import {Text } from "@react-three/drei";

const TextSection2 = React.memo( ({ title, color,fontSize,maxWidth,fillOpacity, letterSpacing,lineHeight, ...props }) => {

  return (
    <group {...props}>
      {!!title && (
        <>
      
        <Text
          color={color}
          anchorX="left"
          anchorY="top"
          fontSize={fontSize}
          maxWidth={maxWidth}
          fillOpacity={fillOpacity}
          curveSegments={24}
          brevelSegments={1}
          bevelEnabled
          bevelSize={0.08}
          bevelThickness={0.03}
          letterSpacing={letterSpacing}
          font={"assets/fonts/Augustusbeveled-YOvj.otf"}
          lineHeight={lineHeight}
        >
          {title}
        </Text>
        </>
      )}

    </group>
  );
})
export default TextSection2;