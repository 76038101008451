import { Text } from "@react-three/drei";
import { fadeOnBeforeCompileFlat } from "./fadeMaterial";
import React from "react";


 const TextSection = React.memo(({ title, colorTitle,colorOutline, subtitle, colorSubtitle, fontSizeTitle , fontSizeSubtitle, maxWidthTitle,maxWidthSubtitle, lineHeightTitle,  ...props }) => {
  return (
    <group {...props}>
      {!!title && (
        <Text
          color={colorTitle}
          anchorX={"left"}
          anchorY="bottom"
          fontSize={fontSizeTitle}
          maxWidth={maxWidthTitle}
          lineHeight={lineHeightTitle}
          font={"/assets/fonts/AnggiedisplayRegular-jEpWj.otf"}
          outlineWidth={0.01}
          outlineColor={colorOutline}
        >
          {title}
          <meshStandardMaterial
            color={"blue"}
            onBeforeCompile={fadeOnBeforeCompileFlat}
          />
        </Text>
      )}

    </group>
  );
})
export default TextSection;