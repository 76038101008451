import { useProgress } from "@react-three/drei";
import { usePlay } from "./Play";
import { useEffect, useState } from "react";


export const Overlay = () => {

  const { progress } = useProgress();
  const { play, end, setPlay, hasScroll } = usePlay();
  const [isSuspenseResolved, setIsSuspenseResolved] = useState(false);

  useEffect(() => {
    const preventDefaultScroll = (event) => {
      event.preventDefault();
    }; 
  if (!isSuspenseResolved) {
    window.addEventListener('wheel', preventDefaultScroll, { passive: false });
    window.addEventListener('touchmove', preventDefaultScroll, { passive: false });
  }
    return () => {
      window.removeEventListener('wheel', preventDefaultScroll);
      window.removeEventListener('touchmove', preventDefaultScroll);
    };
  }, [isSuspenseResolved]);


  return (
    <div
      className={`overlay ${play ? "overlay--disable" : ""}
    ${hasScroll ? "overlay--scrolled" : ""}`}
    >
      <div
        className={`loader ${progress === 100 ? "loader--disappear" : ""}`}
      />
      {progress === 100 && !end && (
        <div className={`intro ${play ? "intro--disappear" : ""}`}>
          <h1 className="logo"> Use your brain like a book </h1>
          <div className="spinner__image"/>
          <div className="calmText"> Calm Coders</div>
          <p className="intro__scroll">Open it to learn more</p>
          <p className="intro__scroll1">Scroll down</p>
          <div className="mouseAnimation"></div>


          <button
            className="explore"
            onClick={() => {
              setPlay(true);
              setIsSuspenseResolved(true)
            }}
          >
          Explore
          </button>
        </div>
      )}

      <div className={`outro ${end ? "outro--appear" : ""}`}>
        <p className="outro__text">Scroll to see more</p>

        <div className="mouse_scroll">

        <div className="mouse">
		    <div className="wheel"></div>
		    </div>

		    <div>
		  	<span className="m_scroll_arrows one"></span>
			  <span className="m_scroll_arrows two"></span>
			  <span className="m_scroll_arrows three"></span>
		    </div>


        <div className="mouse-hide">
		    <div className="wheel-hide"></div>
		    </div>
      

        </div>

      </div>

  

    </div>
  );
};