import React, { Suspense, lazy, useEffect, useRef, useState } from 'react';
import "./TeamPage.css";
import { Canvas } from '@react-three/fiber';
import { Environment, useCursor } from '@react-three/drei';
import ScrollMagic from 'scrollmagic';
import TeamTransition from './TeamTransition';


const Gabri3D = lazy(() => import("../components/teamScene/Gabri"));
const Elvin3D = lazy(() => import("../components/teamScene/Elvini"));
const Finesa3D = lazy(() => import("../components/teamScene/Finesa"));
const Henrik3D = lazy(() => import("../components/teamScene/Henriku"));
const Migena3D = lazy(() => import("../components/teamScene/Migena"));
const Olta3D = lazy(() => import("../components/teamScene/Olta"));
const Anxhela3D = lazy(() => import("../components/teamScene/Anxhela"));

const TextSection = lazy(() => import("../components/TextSection"));

export default function JavaTeamPage() {

  const [hovered, setHovered] = useState(null);
  useCursor(hovered);
  const [isMobileView, setIsMobileView] = useState(false);
  const [isDesktopView, setIsDesktopView] = useState(false);

  useEffect(() => {
    function checkScreenSize() {
      setIsMobileView(window.innerWidth <= 500); 
      setIsDesktopView(window.innerWidth > 500 && window.innerWidth <= 1680 ); 
    }

    checkScreenSize(); 

    window.addEventListener('resize', checkScreenSize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);


  const gabrielRef = useRef(null);
  const elvinRef = useRef(null);
  const finesaRef = useRef(null);
  const henrikRef = useRef(null);
  const migenaRef = useRef(null);
  const oltaRef = useRef(null);
  const anxhelaRef = useRef(null);



  const [renderGabrielModel, setRenderGabrielModel] = useState(false);
  const [renderElvinModel, setRenderElvinModel] = useState(false);
  const [renderFinesaModel, setRenderFinesaModel] = useState(false);
  const [renderHenrikModel, setRenderHenrikModel] = useState(false);
  const [renderMigenaModel, setRenderMigenaModel] = useState(false);
  const [renderOltaModel, setRenderOltaModel] = useState(false);
  const [renderAnxhelaModel, setRenderAnxhelaModel] = useState(false);



  const [gabrielSuspenseFinished, setGabrielSuspenseFinished] = useState(0);
  const [elvinSuspenseFinished, setElvinSuspenseFinished] = useState(0);
  const [finesaSuspenseFinished, setFinesaSuspenseFinished] = useState(0);
  const [henrikSuspenseFinished, setHenrikSuspenseFinished] = useState(0);
  const [migenaSuspenseFinished, setMigenaSuspenseFinished] = useState(0);
  const [oltaSuspenseFinished, setOltaSuspenseFinished] = useState(0);
  const [anxhelaSuspenseFinished, setAnxhelaSuspenseFinished] = useState(0);


  useEffect(() => {

          const controller = new ScrollMagic.Controller();

          new ScrollMagic.Scene({
          duration: 1000,
          triggerElement: gabrielRef.current,
          triggerHook: 0,
          })
          .setPin(gabrielRef.current)
          .addTo(controller)
          .on('enter', () => {    
          setRenderGabrielModel(true)
          setRenderElvinModel(false)
          })
          


          new ScrollMagic.Scene({
          duration: 1000,
          triggerElement: elvinRef.current,
          triggerHook: 0,
          })
          .setPin(elvinRef.current)
          .addTo(controller)
          .on('enter', () => {    
          setRenderGabrielModel(false)
          setRenderElvinModel(true)
          setRenderFinesaModel(false)
          })


          new ScrollMagic.Scene({
          duration: 1000,
          triggerElement: finesaRef.current,
          triggerHook: 0,
          })
          .setPin(finesaRef.current)
          .addTo(controller)
          .on('enter', () => {    
          setRenderElvinModel(false)
          setRenderFinesaModel(true)
          setRenderHenrikModel(false)
          })


          new ScrollMagic.Scene({
          duration: 1000,
          triggerElement: henrikRef.current,
          triggerHook: 0,
          })
          .setPin(henrikRef.current)
          .addTo(controller)
          .on('enter', () => {    
          setRenderFinesaModel(false)
          setRenderHenrikModel(true)
          setRenderMigenaModel(false)
          })


          new ScrollMagic.Scene({
          duration: 1000,
          triggerElement: migenaRef.current,
          triggerHook: 0,
          })
          .setPin(migenaRef.current)
          .addTo(controller)
          .on('enter', () => {    
          setRenderHenrikModel(false)
          setRenderMigenaModel(true)
          setRenderOltaModel(false)
          })
    
    
          new ScrollMagic.Scene({
          duration: 1000,
          triggerElement: oltaRef.current,
          triggerHook: 0,
          })
          .setPin(oltaRef.current)
          .addTo(controller)
          .on('enter', () => {    
          setRenderMigenaModel(false)
          setRenderOltaModel(true)
          setRenderAnxhelaModel(false)
          })

          new ScrollMagic.Scene({
            duration: 1000,
            triggerElement: anxhelaRef.current,
            triggerHook: 0,
            })
            .setPin(anxhelaRef.current)
            .addTo(controller)
            .on('enter', () => {    
            setRenderOltaModel(false)
            setRenderAnxhelaModel(true)
            })
  


         
  }, []);
 

  useEffect(()=> {

    if (renderGabrielModel && gabrielSuspenseFinished === 100){
      
      if(isMobileView){
        setTimeout(() => {
          document.documentElement.style.overflowY = "visible";
          }, 500 );
  
        document.body.style.overflowY = ""
      }

      else{
        setTimeout(() => {
          document.body.style.overflowY = "visible"
          }, 300 );
        document.documentElement.style.overflowY=""
      }

      
    }
    
    else{
  
      if(isMobileView){
        document.documentElement.style.overflowY = "hidden";
        document.body.style.overflowY = ""
      }
      else{
        document.documentElement.style.overflowY=""
        document.body.style.overflowY = "hidden"
      }

    }

  
  },[renderGabrielModel,gabrielSuspenseFinished,isMobileView])

  useEffect(() => {

    if((document.body.style.overflowY==="hidden" || document.documentElement.style.overflowY === "hidden") && renderGabrielModel && gabrielSuspenseFinished === 0){
      setTimeout(() => {
        setGabrielSuspenseFinished(100)
      }, isMobileView ? 300 : 200);
    }
  },[renderGabrielModel,gabrielSuspenseFinished,isMobileView])
  

  useEffect(()=> {


    if (renderElvinModel && elvinSuspenseFinished === 100){
      
      if(isMobileView){
        setTimeout(() => {
          document.documentElement.style.overflowY = "visible";
          }, 500 );
  
        document.body.style.overflowY = ""
      }

      else{
        setTimeout(() => {
          document.body.style.overflowY = "visible"
          }, 300 );
        document.documentElement.style.overflowY=""
      }

      
    }
    
    else{
  
      if(isMobileView){
        document.documentElement.style.overflowY = "hidden";
        document.body.style.overflowY = ""
      }
      else{
        document.documentElement.style.overflowY=""
        document.body.style.overflowY = "hidden"
      }

    }
  
  
  },[renderElvinModel,elvinSuspenseFinished,isMobileView])

  useEffect(() => {

    if((document.body.style.overflowY==="hidden" || document.documentElement.style.overflowY === "hidden") && renderElvinModel && elvinSuspenseFinished === 0){
      setTimeout(() => {
        setElvinSuspenseFinished(100)
      }, isMobileView ? 300 : 200);
    }
  },[renderElvinModel,elvinSuspenseFinished,isMobileView])


  useEffect(()=> {

    if (renderFinesaModel && finesaSuspenseFinished === 100){
      
      if(isMobileView){
        setTimeout(() => {
          document.documentElement.style.overflowY = "visible";
          }, 500 );
  
        document.body.style.overflowY = ""
      }

      else{
        setTimeout(() => {
          document.body.style.overflowY = "visible"
          }, 300 );
        document.documentElement.style.overflowY=""
      }
      
    }
    
    else{
  
      if(isMobileView){
        document.documentElement.style.overflowY = "hidden";
        document.body.style.overflowY = ""
      }
      else{
        document.documentElement.style.overflowY=""
        document.body.style.overflowY = "hidden"
      }

    }
  
  
  },[renderFinesaModel,finesaSuspenseFinished,isMobileView])

  useEffect(() => {

    if((document.body.style.overflowY==="hidden" || document.documentElement.style.overflowY === "hidden") && renderFinesaModel && finesaSuspenseFinished === 0){
      setTimeout(() => {
        setFinesaSuspenseFinished(100)
      }, isMobileView ? 300 : 200);
    }
  },[renderFinesaModel,finesaSuspenseFinished,isMobileView])


  useEffect(()=> {

    if (renderHenrikModel && henrikSuspenseFinished === 100){
      
      if(isMobileView){
        setTimeout(() => {
          document.documentElement.style.overflowY = "visible";
          }, 500 );
  
        document.body.style.overflowY = ""
      }

      else{
        setTimeout(() => {
          document.body.style.overflowY = "visible"
          }, 300 );
        document.documentElement.style.overflowY=""
      }
  
      
    }
    
    else{
  
      if(isMobileView){
        document.documentElement.style.overflowY = "hidden";
        document.body.style.overflowY = ""
      }
      else{
        document.documentElement.style.overflowY=""
        document.body.style.overflowY = "hidden"
      }

    }
  
  
  },[renderHenrikModel,henrikSuspenseFinished,isMobileView])

  useEffect(() => {

    if((document.body.style.overflowY==="hidden" || document.documentElement.style.overflowY === "hidden") && renderHenrikModel && henrikSuspenseFinished === 0){
      setTimeout(() => {
        setHenrikSuspenseFinished(100)
      }, isMobileView ? 300 : 200);
    }
  },[renderHenrikModel,henrikSuspenseFinished,isMobileView])


  useEffect(()=> {


    if (renderMigenaModel && migenaSuspenseFinished === 100){
      
      if(isMobileView){
        setTimeout(() => {
          document.documentElement.style.overflowY = "visible";
          }, 500 );
  
        document.body.style.overflowY = ""
      }

      else{
        setTimeout(() => {
          document.body.style.overflowY = "visible"
          }, 300 );
        document.documentElement.style.overflowY=""
      }
      
    }
    
    else{
  
      if(isMobileView){
        document.documentElement.style.overflowY = "hidden";
        document.body.style.overflowY = ""
      }
      else{
        document.documentElement.style.overflowY=""
        document.body.style.overflowY = "hidden"
      }

    }
  
  
  },[renderMigenaModel,migenaSuspenseFinished,isMobileView])

  useEffect(() => {

    if((document.body.style.overflowY==="hidden" || document.documentElement.style.overflowY === "hidden") && renderMigenaModel && migenaSuspenseFinished === 0){
      setTimeout(() => {
        setMigenaSuspenseFinished(100)
      }, isMobileView ? 300 : 200);
    }
  }, [renderMigenaModel, migenaSuspenseFinished, isMobileView])
  

  useEffect(()=> {


    if (renderOltaModel && oltaSuspenseFinished === 100){
      
      if(isMobileView){
        setTimeout(() => {
          document.documentElement.style.overflowY = "visible";
          }, 500 );
  
        document.body.style.overflowY = ""
      }

      else{
        setTimeout(() => {
          document.body.style.overflowY = "visible"
          }, 300 );
        document.documentElement.style.overflowY=""
      }
      
    }
    
    else{
  
      if(isMobileView){
        document.documentElement.style.overflowY = "hidden";
        document.body.style.overflowY = ""
      }
      else{
        document.documentElement.style.overflowY=""
        document.body.style.overflowY = "hidden"
      }

    }
  
  
  },[renderOltaModel,oltaSuspenseFinished,isMobileView])

  useEffect(() => {

    if((document.body.style.overflowY==="hidden" || document.documentElement.style.overflowY === "hidden") && renderOltaModel && oltaSuspenseFinished === 0){
      setTimeout(() => {
        setOltaSuspenseFinished(100)
      }, isMobileView ? 300 : 200);
    }
  },[renderOltaModel,oltaSuspenseFinished,isMobileView])


  
  useEffect(()=> {


    if (renderAnxhelaModel && anxhelaSuspenseFinished === 100){
      
      if(isMobileView){
        setTimeout(() => {
          document.documentElement.style.overflowY = "visible";
          }, 500 );
  
        document.body.style.overflowY = ""
      }

      else{
        setTimeout(() => {
          document.body.style.overflowY = "visible"
          }, 300 );
        document.documentElement.style.overflowY=""
      }
      
    }
    
    else{
  
      if(isMobileView){
        document.documentElement.style.overflowY = "hidden";
        document.body.style.overflowY = ""
      }
      else{
        document.documentElement.style.overflowY=""
        document.body.style.overflowY = "hidden"
      }

    }
  
  
  },[renderAnxhelaModel,anxhelaSuspenseFinished,isMobileView])

  useEffect(() => {

    if((document.body.style.overflowY==="hidden" || document.documentElement.style.overflowY === "hidden") && renderAnxhelaModel && anxhelaSuspenseFinished === 0){
      setTimeout(() => {
        setAnxhelaSuspenseFinished(100)
      }, isMobileView ? 300 : 200);
    }
  },[renderAnxhelaModel,anxhelaSuspenseFinished,isMobileView])


  return (
    
    <div>

    <div ref={gabrielRef} style={{ width: '100%', height: '100vh', backgroundColor: "#1e2a44"}}>
    {renderGabrielModel &&
    <Suspense fallback={<TeamTransition  name={"Gabriel"} surname={"Manolache"}/>}> 
    <Canvas>
    <ambientLight intensity={0.5}/>
    <Environment preset="sunset"/>
    <Gabri3D scale={isMobileView ? 3.2 : 3} position={isMobileView ? [-0.1,-4.4,-2.5] : isDesktopView ? [-3.5,-2,-3] : [-4.5,-2,-3]} rotation={isMobileView ? [0.22,0.06,-0.03] : [0.3,0.3,-0.09]} hoverd={hovered ==="Gabriel"}/>      
    <TextSection title={"CTO & SOFTWARE DEVELOPER"} 
    position={isMobileView ? [-1.05,3.2,0.1] : [0,2.3,0]} 
    fontSize={isMobileView ? 0.15 : 0.2} 
    maxWidth={isMobileView ? 2.4 : 4} 
    color={"white"} 
    fillOpacity={3} />
    <TextSection title={isMobileView 
    ? 
    "Introducing Gabriel Manolache , the CTO at Calm Coders and our  in-house Java guru. With over ten years in the tech area , he's been coding up a storm , one Java bean at a time (and we-re not just talking about his impressive coffee collection)                    His mantra? 'Good code is like good coffee rich, robust and keeps you going all'" 
    :
    "Introducing Gabriel Manolache                The CTO at Calm Coders and our  in-house Java guru. With over ten years in the tech area , he's been coding up a storm , one Java bean at a time (and we-re not just talking about his impressive coffee collection)                     His mantra? 'Good code is like good coffee  rich, robust and keeps you going all'"} 
    position={isMobileView ? [-1.05,2.9,0.1] : [0,1.5,0]} 
    rotation={[0,-0.01,0]} 
    fontSize={isMobileView ? 0.12 : 0.2} 
    maxWidth={isMobileView ? 2.4 : 4} 
    color={"white"} 
    fillOpacity={3} 
    onPointerEnter={() => setHovered("Gabriel")}  
    onPointerLeave={() => setHovered(null)}/>
    <TextSection title={"Scroll to see more"} 
    position={isMobileView ? [-1.7, 0.9, 0] : isDesktopView ? [-4.8, 0.5, 0] :  [-7.5, 0.9, 0]}
    fontSize={isMobileView ? 0.13 : 0.2} 
    maxWidth={isMobileView ? 0.1 : 1} 
    color={"white"} 
    fillOpacity={3} /> 
    </Canvas>
    </Suspense>
    }
    </div>


    <div ref={elvinRef}  style={{ width: '100%', height: '100vh', backgroundColor: "#1e2a44"}}>
    {renderElvinModel &&
    <Suspense fallback={<TeamTransition  name={"Elvin"} surname={"Kutrolli"}/>}> 
    <Canvas style={{ width: '100%', height: isMobileView ? '106vh' : '102vh', backgroundColor: "#1e202c"}}>
    <ambientLight intensity={0.5}/>
    <Environment preset="sunset"/>
    <Elvin3D scale={3} position={isMobileView ? [-0.05,-4.4,-2.6] : isDesktopView ? [-3.3,-2.5,-3] : [-4.5,-2.5,-3]} rotation={isMobileView ? [0.2,0.55,-0.03] : [0.3,0.9,-0.09]} hoverd={hovered ==="Elvin"}/>   
    <TextSection title={"Java Developer"} 
    position={isMobileView ? [-0.6,3,0.1] : [0,1.8,0]} 
    fontSize={isMobileView ? 0.15 : 0.2} 
    maxWidth={isMobileView ? 2.4 : 4} 
    color={"#cccccc"} 
    fillOpacity={3} />
    <TextSection title={isMobileView 
    ? 
    "Hello I'm Elvin                                             Your friendly developer specialized in Angular framework, Spring framework and related technologies, always ready for  new adventures, new projects, exploring new ways to deliver  fast and efficient products in a reliable time, trying every time new ways to optimize and bring innovative solutions" 
    :
    "Hello I'm Elvin                                             Your friendly developer specialized in Angular framework Spring framework and related technologies, always ready for  new adventures, new projects, exploring new ways to deliver  fast and efficient products      in a reliable time, trying every time new ways to optimize and bring innovative solutions"} 
    position={isMobileView ? [-1.05,2.7,0.1] : [0,1.3,0]} 
    rotation={[0,0,0]} 
    fontSize={isMobileView ? 0.12 : 0.2} 
    maxWidth={isMobileView ? 2.4 : 4} 
    color={"#cccccc"} 
    fillOpacity={3} 
    onPointerEnter={() => setHovered("Elvin")}  
    onPointerLeave={() => setHovered(null)}/>
    <TextSection title={"Scroll to see more"} 
    position={isMobileView ? [1.1,0.4,0.1] : isDesktopView ? [4,0.9,0] : [6.7,0.9,0]} 
    fontSize={isMobileView ? 0.13 : 0.2} 
    maxWidth={isMobileView ? 0.1 : 1} 
    color={"#cccccc"} 
    fillOpacity={3} /> 
    </Canvas>
    </Suspense>
    }
    </div>


    <div ref={finesaRef} style={{ width: '100%', height: '100vh', backgroundColor: "#594b70"}}>
    {renderFinesaModel &&
    <Suspense fallback={<TeamTransition  name={"Finesa"} surname={"Sullenjeri"}/>}> 
    <Canvas>
    <ambientLight intensity={0.5}/>
    <Environment preset="sunset"/>
    <Finesa3D scale={3} position={isMobileView ? [-0.85,-4.3,-2.5] : isDesktopView ? [3,-2.5,-3] : [4.5,-2.5,-3]} rotation={isMobileView ? [0.2,0.5,-0.035] : [0.3,-0.1,0.1]} hoverd={hovered ==="Finesa"}/>   
    <TextSection title={"Java Developer"} 
    position={isMobileView ? [-0.6,3,0.1] : isDesktopView ? [-3.5,1.7,0] : [-5,1.7,0]} 
    fontSize={isMobileView ? 0.15 : 0.2} 
    maxWidth={isMobileView ? 2.4 : 4} 
    color={"black"} 
    fillOpacity={3} />
    <TextSection title={isMobileView 
    ? 
    "Hi there, I am Finesa                                         A Java backend developer with a creative flair in graphic design Blending coding expertise with design finesse.                          I create seamless and visually appealing solutions. Committed to pushing boundaries and creating seamless user-centric experiences through code" 
    : 
    "Hi there, I am Finesa                                         A Java backend developer with a creative flair in graphic design Blending coding expertise with design finesse.                          I create seamless and visually appealing solutions. Committed to pushing boundaries and creating seamless user-centric experiences through code"} 
    position={isMobileView ? [-1.05,2.6,0.1] : isDesktopView ? [-3.5,1.2,0] : [-5,1.2,0]} 
    fontSize={isMobileView ? 0.125 : 0.2} 
    maxWidth={isMobileView ? 2.4 : 4} 
    color={"black"} 
    fillOpacity={3} 
    onPointerEnter={() => setHovered("Finesa")}  
    onPointerLeave={() => setHovered(null)}/>   
   <TextSection title={"Scroll to see more"} 
    position={isMobileView ? [-1.7, 0.9, 0] : isDesktopView ? [-4.8, 0.5, 0] :  [-7.5, 0.9, 0]}
    fontSize={isMobileView ? 0.13 : 0.2} 
    maxWidth={isMobileView ? 0.1 : 1} 
    color={"black"} 
    fillOpacity={3} /> 
    </Canvas>
    </Suspense>
    }
    </div>

    <div ref={henrikRef} style={{ width: '100%', height: '100vh', backgroundColor: "#a79184"}}>
    {renderHenrikModel &&
    <Suspense fallback={<TeamTransition  name={"Henrik"} surname={"Zhupani"}/>}> 
    <Canvas>
    <ambientLight intensity={0.5}/>
    <Environment preset="sunset"/>
    <Henrik3D scale={3} position={isMobileView ? [0,-4.1,-2.5] : isDesktopView ? [-3,-2.5,-3] : [-4.5,-2.5,-3]} rotation={isMobileView ? [0.25,0.62,-0.02] : isDesktopView ? [0.4,0.8,-0.13] : [0.3,1,-0.13]} hoverd={hovered ==="Henrik"}/>      
    <TextSection title={"Java Developer"} 
    position={isMobileView ? [-0.6,3.1,0.1] : isDesktopView ? [0.4,1.6,0] : [0,1.6,0]} 
    fontSize={isMobileView ? 0.15 : 0.2} 
    maxWidth={isMobileView ? 2.4 : 4} 
    color={"#241c1b"} 
    fillOpacity={3} />
    <TextSection title={isMobileView ? "I'm Henrik                                                               Go-to guy for Java programming wizardry and crafting slick  WordPress websites. I've got a good handle on CSS & HTML turning code into eye-catching interfaces. Oh, and I've got a bit of a sales savvy side too. Let's just say,                      I'm a tech all-rounder!" : "I'm Henrik                                                               Go-to guy for Java programming wizardry and crafting slick  WordPress websites               I've got a good handle on CSS & HTML turning code into eye-catching interfaces. Oh, and I've got a bit of a sales savvy side too. Let's just say, I'm a tech all-rounder!"} 
    position={isMobileView ? [-1.05,2.65,0.1] : isDesktopView ? [0.4,1.2,0] : [0,1.2,0]} 
    fontSize={isMobileView ? 0.125 : 0.2} 
    maxWidth={isMobileView ? 2.4 : 4}
    color={"#241c1b"} 
    fillOpacity={3} 
    onPointerEnter={() => setHovered("Henrik")}  
    onPointerLeave={() => setHovered(null)}/>
    <TextSection title={"Scroll to see more"} 
    position={isMobileView ? [1.1,0.4,0.1] : isDesktopView ? [4,0.9,0] : [6.7,0.9,0]} 
    fontSize={isMobileView ? 0.13 : 0.2} 
    maxWidth={isMobileView ? 0.1 : 1} 
    color={"#241c1b"} 
    fillOpacity={3} /> 
    </Canvas>
    </Suspense>
    }
    </div>

    <div ref={migenaRef} style={{ width: '100%', height: '100vh', backgroundColor: "#191c41"}}>
    {renderMigenaModel &&
    <Suspense fallback={<TeamTransition  name={"Migena"} surname={"Mustafa"}/>}> 
    <Canvas >
    <ambientLight intensity={0.5}/>
    <Environment preset="sunset"/>
    <Migena3D scale={3} position={isMobileView ? [-0.6,-4.9,-2.5] : isDesktopView ? [3.5,-2.5,-3] : [5,-2.5,-3]} rotation={isMobileView ? [0.16,0.55,0.03] : [0.17,0,0.05]} hoverd={hovered ==="Migena"}/>      
    <TextSection title={"Web App Developer & Java Developer"} 
    position={isMobileView ? [-1.23,2.98,0.1] : isDesktopView ? [-3.7,2.1,0] : [-5,2.3,0]} 
    fontSize={isMobileView ? 0.144 : 0.2} 
    rotation={isMobileView ? [0,0,0] : [0,0,0.01]}
    maxWidth={isMobileView ? 3.5 : isDesktopView ? 4.2 : 4} 
    color={"#4a95ce"} 
    fillOpacity={3} />
    <TextSection title={isMobileView ? "Hi, I am Migena                                                                   A developer with a dual mastery           that transcends boundaries.           With a flair in frontend technologies,          I weave seamless frontend magic                                Java, in my hands, becomes the backbone of scalable web applications bringing functionality to life         Yet, my canvas extends beyond code                         With Blender as my brush, I sculpt   enchanting 3D models and scenes, infusing digital landscapes with artistic elegance. I'm not just a coder, I am a symphony of technology and artistry, reshaping the digital realm into a captivating masterpiece" : "Hi, I am Migena                                                                   A developer with a dual mastery that transcends boundaries. With a flair in frontend technologies, I weave seamless frontend magic Java, in my hands, becomes the backbone of scalable web applications, bringing functionality to life. Yet, my canvas extends beyond code. With Blender as my brush, I sculpt   enchanting 3D models and scenes, infusing digital landscapes with artistic elegance  I'm not just a coder, I am a symphony of technology and artistry, reshaping the  digital realm into a captivating masterpiece"} 
    position={isMobileView ? [-1.18,2.75,0.1] : isDesktopView ? [-3.65,1.5,0] : [-5,1.5,0]} 
    fontSize={isMobileView ? 0.125 : 0.2} 
    rotation={isMobileView ? [0,0,0] : [0,0,0.01]}
    maxWidth={isMobileView ? 2.9 : 5}
    color={"#4a95ce"} 
    fillOpacity={3}
    onPointerEnter={() => setHovered("Migena")}  
    onPointerLeave={() => setHovered(null)} />
    <TextSection title={"Scroll to see more"} 
    position={isMobileView ? [-1.9, 0.9, 0] : isDesktopView ? [-4.8, 0.5, 0] :  [-7.5, 0.9, 0]}
    fontSize={isMobileView ? 0.13 : 0.2} 
    maxWidth={isMobileView ? 0.1 : 1} 
    color={"#4a95ce"} 
    fillOpacity={3} /> 
    </Canvas>
    </Suspense>
    }
    </div>

    <div ref={oltaRef} style={{ width: '100%', height: '100vh', backgroundColor: "#999999"}}>
    {renderOltaModel &&
    <Suspense fallback={<TeamTransition  name={"Olta"} surname= {"Vashko"}/>}> 
    <Canvas>
    <ambientLight intensity={0.5}/>
    <Environment preset="sunset"/>
    <Olta3D scale={3} position={isMobileView ? [0.1,-4.05,-2.4] : isDesktopView ? [3.8,-2.5,-3] : [4.5,-2.5,-3]} rotation={isMobileView ?  (hovered ==="Olta" ? [0.22,0.1,-0.03] :  [0.22,0.6,0.02] ) : (hovered ==="Olta" ? [0.3,-0.35,0.1] :  [0.2,0.15,0.1] ) } hoverd={hovered ==="Olta"}/>      
    <TextSection title={"Java Developer"} 
    position={isMobileView ? [-1.05,3.3,0.1] : isDesktopView ? [-3.5,2,0] : [-5,2,0]} 
    fontSize={isMobileView ? 0.15 : 0.2} 
    maxWidth={isMobileView ? 2.4 : 4} 
    color={"black"} 
    fillOpacity={3} />
    <TextSection title={isMobileView 
    ? 
    "Hi, I’m Olta                      As a junior Java Developer,        I craft effective web applications using Spring Boot. My skills include building APIs, working with databases, and solving complex problems. I’m passionate about learning and enjoy tackling projects that make a real impact. Always ready for a challenge,      I strive to deliver practical   and reliable solutions in    software development" 
    : 
    "Hi, I’m Olta                             As a junior Java Developer, I craft effective web applications using Spring Boot. My skills include building APIs, working with databases and solving complex problems.      I’m passionate about learning and enjoy tackling projects that make a real impact. Always ready for a challenge, I strive to deliver practical and reliable solutions in software development."} 
    position={isMobileView ? [-1.05,3.05,0.1] : isDesktopView ? [-3.5,1.5,0] : [-5,1.5,0]} 
    rotation={[0,-0.01,0]} 
    fontSize={isMobileView ? 0.12 : 0.2} 
    maxWidth={isMobileView ? 2.45: 4.5} 
    color={"black"} 
    fillOpacity={3} 
    onPointerEnter={() => setHovered("Olta")}  
    onPointerLeave={() => setHovered(null)}/>
    <TextSection title={"Scroll to see more"} 
    position={isMobileView ? [1.1,0.4,0.1] : isDesktopView ? [4,0.9,0] : [6.7,0.9,0]} 
    fontSize={isMobileView ? 0.13 : 0.2} 
    maxWidth={isMobileView ? 0.1 : 1} 
    color={"black"} 
    fillOpacity={3} /> 
    </Canvas>
    </Suspense>
    }
    </div>

    <div ref={anxhelaRef} style={{ width: '100%', height: '100vh', backgroundColor: "#58575b"}}>
    {renderAnxhelaModel &&
    <Suspense fallback={<TeamTransition  name={"Anxhela"} surname={"Murthi"}/>}> 
    <Canvas >
    <ambientLight intensity={0.5}/>
    <Environment preset="sunset"/>
    <Anxhela3D scale={3} position={isMobileView ? [0.2,-4.9,-2.5] : isDesktopView ? [3.5,-2.5,-3] : [5,-2.5,-3]} rotation={isMobileView ? [0.16,0,0.03] : [0.2,-0.5,0.08]} hoverd={hovered ==="Anxhela"}/>      
    <TextSection title={"Java Developer"} 
    position={isMobileView ? [-1.1,3.3,0.1] : isDesktopView ? [-3.7,2.1,0] : [-5,2.3,0]} 
    fontSize={isMobileView ? 0.144 : 0.2} 
    rotation={isMobileView ? [0,0,0] : [0,0,0.01]}
    maxWidth={isMobileView ? 3.5 : isDesktopView ? 4.2 : 4} 
    color={"white"} 
    fillOpacity={3} />
    <TextSection title={isMobileView ? "Hi, I am Anxhela                     As a Junior Java Developer I’m passionate about turning ideas into powerful and efficient applications.   I specialize in crafting clean, maintainable code and thrive on designing intuitive APIs and managing complex databases. My approach to problem-solving is both innovative and precise, driven by a commitment to continuous learning and improvement.  I blend technical expertise with a dedication to creating impactful backend systems, ensuring that each solution I develop is both effective and meaningful. Always eager to embrace new challenges, I strive to make a positive difference through my work and contribute to technological advancements." : 
    "Hi, I am Anxhela                         As a Junior Java Developer, I’m passionate about turning ideas into powerful and efficient applications.     I specialize in crafting clean, maintainable code and thrive on designing intuitive APIs and managing complex databases. My approach to problem-solving is both innovative and precise, driven by a commitment to continuous learning and improvement. I blend technical expertise with a dedication to creating impactful backend systems, ensuring that each solution I develop is both effective and meaningful. Always eager to embrace new challenges, I strive to make a positive difference through my work and contribute to technological advancements."} 
    position={isMobileView ? [-1.1,3.1,0.1] : isDesktopView ? [-3.65,1.5,0] : [-5,1.5,0]} 
    fontSize={isMobileView ? 0.125 : 0.2} 
    rotation={isMobileView ? [0,0,0] : [0,0,0.01]}
    maxWidth={isMobileView ? 2.9 : 5}
    color={"white"} 
    fillOpacity={3}
    onPointerEnter={() => setHovered("Anxhela")}  
    onPointerLeave={() => setHovered(null)} />
    </Canvas>
    </Suspense>
    }
    </div>


  </div>

  );
}
